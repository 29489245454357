import React, { useState, useEffect } from 'react'
import api from '../../Authenticaltion/api';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';// Import search components
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import './EmployeeList.css'
import { useLoading } from '../../LoadingContext';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { toast } from 'react-toastify';

const EmployeeList = () => {
  const [employees, setEmployees] = useState([]);
  const { updateLoading } = useLoading()
  const [isAddDialogVisible, setAddDialogVisible] = useState(false);
  const initialBasicInfo = {
    emp_id: "",
    Name: "",
    joiningDate: ""
  };

  const [basicInfo, setBasicInfo] = useState(initialBasicInfo);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBasicInfo((prevState) => ({ ...prevState, [name]: value }));
  };

  const { SearchBar } = Search;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    updateLoading(true)
    try {
      const response = await api.post('/EmployeeControl');
      setEmployees(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    updateLoading(false)
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!basicInfo.emp_id || !basicInfo.Name) {
      toast.warning('Employee ID and Name and joining Date are required.');
      return;
    }
    try {
      const response = await api.post('/Addemployee', basicInfo);
      toast.success(response.data.message);
      setBasicInfo(initialBasicInfo);
      fetchData();      
      setAddDialogVisible(false); // Close the dialog after submission
    } catch (error) {
      console.error('Error adding employee:', error);
      toast.error('An error occurred while adding the employee.');
    }
  };

  const columns = [
    {
      dataField: 'Emp_id',
      text: 'Emp_id',
      headerAlign: 'center',
      align: 'center',
      columnClasses: 'col-2',
      sort: true,
      style: { width: '20px', overflow: 'hidden', textOverflow: 'ellipsis' },
      sortCaret: (order, column) => {
        if (!order) return (<span>&nbsp;&nbsp;Desc/Asc</span>);
        else if (order === 'asc') return (<span>&nbsp;&nbsp;Desc/<font color="red">Asc</font></span>);
        else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="red">Desc</font>/Asc</span>);
        return null;
      },
      formatter: (cell, row) => (
        <div title={cell}>{cell}</div>
      ),
    },
    {
      dataField: 'Name',
      text: 'Name',
      headerAlign: 'center',
      align: 'center',
      columnClasses: 'col-3'
    },
    {
      dataField: 'Email_Id',
      text: 'Email',
      headerAlign: 'center',
      align: 'center',
      columnClasses: 'col-3'
    },
    {
      dataField: 'status',
      text: 'Status',
      headerAlign: 'center',
      align: 'center',
      columnClasses: 'col-2'
    },
    {
      dataField: 'actions',
      text: 'Actions',
      headerAlign: 'center',
      align: 'center',
      columnClasses: 'col-2',
      formatter: (cell, row) => (
        <div>
          <Link to={`/View/${row.Emp_id}`} className="btn btn-sm btn-success mx-2">
            <FontAwesomeIcon icon={faEye} className="fa-xs" />
          </Link>
          {row.status === "Active" ? (
            <button type="button" className="btn btn-sm btn-danger" onClick={() => handleDeleteEmployee(row.id, row.Name, row.Emp_id,)}>
              <FontAwesomeIcon icon={faTrash} className="fa-xs" />
            </button>
          ) : <button type="button" className="btn btn-sm btn-danger" disabled>
            <FontAwesomeIcon icon={faTrash} className="fa-xs" />
          </button>}
        </div >

      ),
    },
  ];

  const handleDeleteEmployee = (id, name, Emp_id) => {
    Swal.fire({
      text: `Confirm removal of ${name} (Emp_ID: ${Emp_id})?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonColor: '#6366F1',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Remove',
      dangerMode: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await api.delete(`/deleteUser/${id}`);
          if (response.status === 200) {
            Swal.fire('Success!', `${name} has been deleted.`, 'success');
          } else {
            Swal.fire('Error!', 'An error occurred while deleting the employee.', 'error');
          }
          fetchData();
        } catch (error) {
          console.error('Error deleting employee:', error);
          Swal.fire('Error!', 'An error occurred while deleting the employee.', 'error');
        }
      }
    });
  };
  const footer = (
    <div>
      <Button label="Cancel" icon="pi pi-times"  onClick={() => {
    setBasicInfo(initialBasicInfo); // Reset form fields when dialog is closed
    setAddDialogVisible(false);    // Close the dialog
  }}  className="p-button-text" />
      <Button label="Save" onClick={handleSubmit} icon="pi pi-check"  />
    </div>
  );

  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="btn-group" role="group">
      {options.map((option) => {
        const isSelect = currSizePerPage === `${option.page}`;
        const buttonClass = isSelect ? 'btn' : 'btn btn-secondary';
        const buttonStyle = isSelect ? { backgroundColor: '#6366F1', color: '#fff' } : {};

        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={buttonClass}
            style={buttonStyle}
          >
            {option.text}
          </button>
        );
      })}
    </div>
  );
 

  return (
    <div className='row'>
      <ToolkitProvider
        keyField="id"
        data={employees}
        columns={columns}
        search
      >
        {props => (
          <div class=" shadow p-3 bg-white rounded">
            <div class="h5 pb-2 mt-2 ml-2 mb-2 border-bottom text-primary">
              Employee Details
            </div>
            <div className="col text-end" style={{ paddingBottom: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
              <div className="d-flex justify-content-between align-items-center">
                <SearchBar {...props.searchProps} />
                {/* <Link to='/Add_employee' className="btn btn-sm" style={{ backgroundColor: '#6366F1', color: 'white', marginLeft: '15px', marginBottom: '11px', padding: '6px' }}>Add Employee</Link> */}
                <button 
        className="btn btn-sm" 
        style={{ backgroundColor: '#6366F1', color: 'white', marginLeft: '15px', marginBottom: '11px', padding: '6px' }}
        onClick={() => setAddDialogVisible(true)}
      >
        Add Employee
      </button>
                <Link to='/Manage_departmenets' className="btn btn-sm" style={{ backgroundColor: '#6366F1', color: 'white', marginLeft: '15px', marginBottom: '11px', padding: '6px' }}>Manage Departments</Link>
               
              </div>
            </div>
            
            <div className="table-responsive overflow-hidden">
              <BootstrapTable
                className="pagination"
                {...props.baseProps}
                condensed
                hover
                responsive
                pagination={paginationFactory({
                  sizePerPageRenderer,
                  paginationSize: 4,
                  pageStartIndex: 1,
                  firstPageText: 'First',
                  prePageText: 'Prev',
                  nextPageText: 'Next',
                  lastPageText: 'Last',
                  hideSizePerPage: true,
                  showTotal: true,
                  sizePerPageList: [{
                    text: '10', value: 10
                  }, {
                    text: '20', value: 20
                  }, {
                    text: '50', value: 50
                  }, {
                    text: 'All', value: employees.length
                  }],
                  paginationTotalRenderer: (from, to, size) => (
                    <span className="react-bootstrap-table-pagination-total">
                      Showing {from} to {to} of {size} Results
                    </span>
                  ),
                  classes: 'b-pagination-nav'
                })}
              />
            </div>
          </div>
        )}
      </ToolkitProvider>
      <Dialog 
        header="Employee Details" 
        visible={isAddDialogVisible} 
        style={{ width: '500px' }} 
        footer={footer} 
        onHide={() => {
          setBasicInfo(initialBasicInfo); // Reset form fields when dialog is closed
          setAddDialogVisible(false);
        }}
      >
        <form>
          <div className="form-group mb-3">
            <label htmlFor="emp_id">Employee ID</label>
            <input
              type="text"
              className="form-control"
              id="emp_id"
              name="emp_id"
              value={basicInfo.emp_id}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="Name">Name</label>
            <input
              type="text"
              className="form-control"
              id="Name"
              name="Name"
              value={basicInfo.Name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="joiningDate">Joining Date</label>
            <input
              type="date"
              className="form-control"
              id="joiningDate"
              name="joiningDate"
              value={basicInfo.joiningDate}
              onChange={handleInputChange}
            />
          </div>
        </form>
      </Dialog>
      </div>
  );
};

export default EmployeeList;
