import React, { useState, useEffect } from 'react';
import './ManageDepartment.css';
import api from '../../Authenticaltion/api';
import { toast } from 'react-toastify';
import LoaderComponent from '../../LoaderComponenet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useLoading } from '../../LoadingContext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import Swal from 'sweetalert2';


const ManageDepartments = () => {
  const { updateLoading } = useLoading()
  const [departments, setDepartments] = useState([]);
  const [newDepartmentName, setNewDepartmentName] = useState('');
  const [designationName, setDesignationName] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editingDepartment, setEditingDepartment] = useState(null);
  const [editedDepartmentName, setEditedDepartmentName] = useState('');
  const [editingDesignation, setEditingDesignation] = useState(null);
  const [editedDesignationName, setEditedDesignationName] = useState('');
  const [designationNames, setDesignationNames] = useState({});


  ///now state for shwoing popup for delete designation and departmenet
  const [deleteDepartmentDialogVisible, setDeleteDepartmentDialogVisible] = useState(false);
  const [departmentToDelete, setDepartmentToDelete] = useState(null);
  const [deleteDesignationDialogVisible, setDeleteDesignationDialogVisible] = useState(false);
  const [designationToDelete, setDesignationToDelete] = useState(null);
  useEffect(() => {
    fetchDepartments();
  }, []);

  const fetchDepartments = async () => {
    updateLoading(true)
    try {
      const response = await api.get('/GetDepartmentDetails');
      setDepartments(response.data.departmentDetails);

    } catch (err) {
      toast.error(err);
    }
    finally {
      updateLoading(false)
    }
  };

  const addDepartment = async () => {

    if (newDepartmentName.trim() === '') {

      Swal.fire({
        icon: 'warning',
        text: 'Department name cannot be empty.',
        timer: 2000,
        showConfirmButton: false
      })
      return; // Prevent API call if the input is empty or contains only spaces
    }
    if (departments.some((d) => d.department_name.trim() === newDepartmentName.trim())) {
      toast.info('Department already exists.');
      return;
    }
    updateLoading(true)
    try {
      const response = await api.post('/SetDepartmentList', { department: newDepartmentName });
      if (response.status === 200) {
        // Department added successfully, you can handle this case as needed
        toast.success('Department added successfully');
        setNewDepartmentName('');
        fetchDepartments();
      } else {
        // Handle other status codes or errors
        toast.error('Failed to add department:', response.data);
      }
    } catch (error) {
      toast.error('Error adding department: ' + error);
    }
    finally {
      updateLoading(false)
    }
  };

  const deleteDepartment = async (departmentId) => {
    updateLoading(true)
    try {
      // Replace this with your actual API call to delete a department
      const response = await api.delete(`/DeleteDepartment/${departmentId}`);
      console.log("delete departmenet response", response)
      if (response.status === 200) {
        fetchDepartments()
      }
      else if (response.status === 400) {
        const errorMessage = await response.text();
        toast.error('Error: ' + errorMessage);
      }
      else {
        toast.error('Errorss deleting department:', response.statusText);
      }
    } catch (error) {
      console.log("catch block errrot", error)
      toast.error('Error deleting department: ' + error.response.data);
    }
    finally {
      updateLoading(false)
    }
  };

  const showDeleteDepartmentDialog = (department) => {
    setDepartmentToDelete(department);
    setDeleteDepartmentDialogVisible(true);
  };
  const showDeleteDesignationDialog = (departmentId, designationId, designationName) => {
    setDesignationToDelete({ departmentId: departmentId, designationId: designationId, designationName: designationName });
    setDeleteDesignationDialogVisible(true);
  };

  const addDesignation = async (departmenetId) => {
    const designationName = designationNames[departmenetId].trim();
    console.log(typeof (designationName))
    if (designationName) {
      const isDesignationAlreadyExists = departments.some((d) =>
        d.designations.some((designation) =>
          designation.designation_name.trim() === designationName
        )
      );
      if (isDesignationAlreadyExists) {
        toast.error('Designation already exists in the department.');
      } else {
        try {
          const response = await api.post('/AddDesignation', { departmenetId, designationName });
          if (response.status === 200) {
            Swal.fire({
              icon: 'success',
              text: 'Designation added successfully.',
              timer: 2000,
              showConfirmButton: false
            })
            designationNames[departmenetId] = ''
            fetchDepartments();
          } else {
            toast.error('Failed to add designation:', response.data);
          }
        }
        catch (error) {
          toast.error('Error adding designation: ' + error);
        }
      }
    }
    else {
      Swal.fire({
        icon: 'warning',
        text: 'Designation name cannot be empty.',
        timer: 2000,
        showConfirmButton: false
      })
    }
  };
  const deleteDesignation = async (departmentId, designationId) => {
    try {
      const response = await api.delete(`/deleteDesignation/${departmentId}/${designationId}`);
      if (response.status === 200) {
        toast.success(response.data)
        fetchDepartments()
      } else {
        toast.error(response.data)
      }
    } catch (error) {
      toast.error('Error deleting designation: ' + error);
    }
  };

  const startEditDepartment = (departmentId, department_name) => {
    setEditingDepartment(departmentId);
    setEditedDepartmentName(department_name)
  };

  const cancelEditDepartment = () => {
    setEditingDepartment(null);
  };

  const saveEditedDepartment = async (departmentId, updatedName) => {
    // try {
    //   const updatedDepartments = departments.map((d) => {
    //     if (d.department_id === departmentId) {
    //       d.department_name = updatedName;
    //     }
    //     return d;
    //   });
    //   setDepartments(updatedDepartments);
    //   setEditingDepartment(null);
    // } catch (error) {
    //   console.error('Error updating department: ' + error);
    // }
    const matchingDepartment = departments.find((department) => department.department_id === departmentId);

    if (matchingDepartment && matchingDepartment.department_name === updatedName) {
      // No changes were made to the department name
      cancelEditDepartment();
      return;
    }
    console.log("departmenet", departments)
    console.log("department id is", departmentId);
    console.log("depafrtment ame is", updatedName);
    try {
      const response = await api.patch('/UpdateDepartmentlist', { departmentId, updatedName })
      if (response.status === 200) {
        fetchDepartments()
      }
      else {
        toast.error(response.data)
      }
      setEditingDepartment(null);
    }
    catch (err) {
      toast.error('Error updating department:' + err)
    }

  };

  const startEditDesignation = (designationId, designation_name) => {

    setEditingDesignation(designationId);
    setEditedDesignationName(designation_name)
  };

  const cancelEditDesignation = () => {
    setEditingDesignation(null);
    setEditedDesignationName('');
  };

  const saveEditedDesignation = async (departmentId, designationId, updatedName) => {
    try {
      const response = await api.put(`/updateDesignation/${designationId}/${updatedName}`)
      if (response.status === 200) {
        setEditingDesignation(null);
        setEditedDesignationName('');
        fetchDepartments();
      }
      else {
        toast.error('Failed to update designation')
      }
    }
    catch (error) {
      // Handle any network or other errors
      toast.error('An error occurred', error);
    }

  };

  return (

    <div class=" shadow p-3 bg-white rounded">
      <div class="h5 pb-2 mt-2 mb-2 border-bottom text-primary">
        Manage Departments
      </div>
      <div className="department-form">
        <input
          type="text"
          value={newDepartmentName}
          onChange={(e) => setNewDepartmentName(e.target.value)}
          placeholder="Enter Department Name"
          className='departmenet_form_input'
        />
        <button className="add-button" onClick={addDepartment} style={{ boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)', height: '35PX' }}>
          <FontAwesomeIcon icon={faPlus} /> Add Department
        </button>
      </div>
      <div className="department-list">
        {departments && departments.length > 0 ? (
          departments.map((department) => (
            <div key={department.department_id} className="department-card">
              <div className="department-header">
                {editingDepartment === department.department_id ? (
                  <div className="edit-controls">
                    <input
                      type="text"
                      value={editedDepartmentName}
                      onChange={(e) => setEditedDepartmentName(e.target.value)}
                    />

                  </div>
                ) : (
                  <span>{department.department_name}</span>
                )}
                <div className="icon-container">
                  {editingDepartment === department.department_id ? (
                    <>
                      <FontAwesomeIcon icon={faSave} onClick={() => saveEditedDepartment(department.department_id, editedDepartmentName)} className="save-icon" />
                      <FontAwesomeIcon icon={faTimes} onClick={() => cancelEditDepartment()} className="cancel-icon" />
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon
                        icon={faEdit}
                        onClick={() => startEditDepartment(department.department_id, department.department_name)}
                        className="edit-icon"
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={() => showDeleteDepartmentDialog(department)}
                        className="delete-icon"
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="designation-container">
                <h6 style={{ color: 'black', paddingBottom: '5px' }}><b>Designations:</b></h6>
                <ul>
                  {department.designations && department.designations.length > 0 ? (
                    department.designations.map((designation) => (
                      <>
                        <li key={designation.id} className="designation-card">
                          {editingDesignation === designation.designation_id ? (
                            <div className="edit-controls">
                              <input
                                type="text"
                                value={editedDesignationName}
                                onChange={(e) => setEditedDesignationName(e.target.value)}
                              />
                              <FontAwesomeIcon icon={faSave} onClick={() => saveEditedDesignation(department.department_id, designation.designation_id, editedDesignationName)} className="save-icon" />
                              <FontAwesomeIcon icon={faTimes} onClick={() => cancelEditDesignation()} className="cancel-icon" />
                            </div>
                          ) : (
                            <div className="designation-info">
                              <span style={{ width: '20vw', paddingBottom: '7px' }} >
                                {designation.designation_name}
                              </span>
                              <div className="icon-container">
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  onClick={() => startEditDesignation(designation.designation_id, designation.designation_name)}
                                  className="edit-icon"
                                />
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  // onClick={() => deleteDesignation(department.department_id, designation.designation_id)}
                                  onClick={() => showDeleteDesignationDialog(department.department_id, designation.designation_id, designation.designation_name)}
                                  className="delete-icon"
                                />
                              </div>
                            </div>
                          )}

                        </li>
                        <hr className="divider" />
                      </>
                    ))
                  ) : (
                    <li>No designations found.</li>
                  )}
                </ul>
                <div className="designation-form">
                  <input
                    type="text"
                    value={designationNames[department.department_id] || ''}
                    onChange={(e) => setDesignationNames({ ...designationNames, [department.department_id]: e.target.value })}
                    placeholder="Enter Designation Name"
                  />
                  <button className="add-button" onClick={() => addDesignation(department.department_id)} style={{ boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)', height: '35px' }} >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No departments found.</p>
        )}
      </div>
      {/* department delete confirm modal showing */}
      <ConfirmDialog
        visible={deleteDepartmentDialogVisible}
        onHide={() => setDeleteDepartmentDialogVisible(false)}
        message={`Are you sure you want to delete the department "${departmentToDelete ? departmentToDelete.department_name : ''}"?`}
        header="Confirm Delete"
        icon="pi pi-exclamation-triangle"
        accept={() => deleteDepartment(departmentToDelete?.department_id)}
        reject={() => setDeleteDepartmentDialogVisible(false)}
      />
      {/* Designation delete modal showing */}
      <ConfirmDialog
        visible={deleteDesignationDialogVisible}
        onHide={() => setDeleteDesignationDialogVisible(false)}
        message={`Are you sure you want to delete the designation "${designationToDelete ? designationToDelete.designationName : ''}"?`}
        header="Confirm Delete"
        icon="pi pi-exclamation-triangle"
        accept={() => deleteDesignation(designationToDelete?.departmentId, designationToDelete?.designationId)}
        reject={() => setDeleteDesignationDialogVisible(false)}
      />
    </div>
  );
};

export default ManageDepartments;
