import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./app/Component/Login";
import Register from "./app/Component/Register";
import ForgotPassword from "./app/Component/ForgotPassword";
import LandingPage from "./LandingPage";
import Dashboard from "./app/Component/Dashboard";
import Holidays from "./app/Component/Holidays";
import Leaves from "./app/Component/Leaves";
import FileUploadPage from "./app/Component/FileUploadPage ";
import EmployeeList from "./app/Component/EmployeeList";
import DisplayTableData from "./app/Component/PageComponent/DisplayTableData";
import ProtectedRoute from "./app/ProtectedRoute";
import UserProfile from "./app/Component/UserProfile";
import TimeTracker from "./app/Component/TimeTracker";
import UserDashboard from "./app/Component/UserDashboard";

import ProfilePage from "./app/Component/ProfilePage";
import EditEmployee from "./app/Component/PageComponent/EditEmployee";
import ApplyLeave from "./app/Component/ApplyLeave";
import UserTimeTracker from "./app/Component/UserTimeTracker";
import AssignedLeaves from "./app/Component/AssignedLeaves";
import PageNotFound from "./app/Component/PageComponent/PageNotFound";
import UserTableData from "./app/Component/PageComponent/UserTableData";
import ManageDepartments from "./app/Component/ManageDepartments";
import CompnayRule from "./app/Component/CompnayRule";
import ManageHolidays from "./app/Component/ManageHolidays";
import UserWorkFromHome from "./app/Component/UserWorkFromHome";
import ApplyWFH from "./app/Component/ApplyWFH";
import AssignedWorkFromHome from "./app/Component/AssignedWorkFromHome";
import Reimbursement from "./app/Component/Reimbursement";
import ApplyReimbursement from "./app/Component/ApplyReimbursement";
import AdminReimbursement from "./app/Component/AdminReimbursement";
import HandleAssignedAdminLeave from "./app/Component/PageComponent/HandleAssignedAdminLeave"
import GenerateSalarySlip from "./app/Component/GenerateSalarySlip";
import AdminSalarySlip from "./app/Component/AdminSalarySlip";
import UserSalarySlips from "./app/Component/UserSalarySlips";
import ViewMySalarySlips from "./app/Component/ViewMySalarySlips"
import DeatilsSalarySlipsById from "./app/Component/DeatilsSalarySlipsById";
import AddEmployee from "./app/Component/PageComponent/AddEmployee";

const AppRoutes = () => {
  return (
    <Routes>
      {/* <Route path="/Template" element={<Templatepage />} /> */}
      <Route path="/" exact element={<LandingPage />} />
      <Route path="*" element={<PageNotFound />} />
      <Route path="/forgot-password" exact element={<ForgotPassword />} />
      <Route path="/Login" exact element={<Login />} />
      <Route path="/Register" exact element={<Register />} />
      <Route path="/forgot-password" exact element={<ForgotPassword />} />
      <Route element={<ProtectedRoute />}>
        {/* //All auth use show */}
        <Route path="/Holidays" exact element={<Holidays />} />
        <Route path="/Leaves" exact element={<Leaves />} />
        <Route path="/user-TimeTracker" exact element={<UserTimeTracker />} />
        {/* {/* USer and manager role Route */}
        <Route path="/Profilepage" exact element={<ProfilePage />} />
        <Route path="/UserDashboard" exact element={<UserDashboard />} />
        <Route
          path="/UserDashboard/View/:key"
          exact
          element={<UserTableData />}
        />
        <Route path="/Reimbursement" exact element={<Reimbursement />} />
        <Route path="/ApplyReimbursement" exact element={<ApplyReimbursement />} />
        <Route path="/apply-leave" exact element={<ApplyLeave />} />
        <Route path="/UserWorkFromHome" exact element={<UserWorkFromHome />} />
        <Route path="/apply-WFH" exact element={<ApplyWFH />} />
        <Route path="/User-Leaves" exact element={<AssignedLeaves />} />
        <Route path="/Assigned-WFH" exact element={<AssignedWorkFromHome />} />
        <Route path="/SalarySlips" exact element={<UserSalarySlips />} />
        <Route path="salary-slip/:Payslips_id" exact element={<ViewMySalarySlips />} />
        {/*Admin And Superuser Route*/}
        <Route
          element={<ProtectedRoute allowedRoles={["ADMIN", "SUPERADMIN", "MASTERADMIN"]} />}
        >
          <Route
            path="/Manage_departmenets"
            exact
            element={<ManageDepartments />}
          ></Route>
          <Route path="/Add_employee" exact element={<AddEmployee />} />
          <Route
            path={"/company-rules"}
            exact
            element={<CompnayRule />}
          ></Route>
          <Route path="/View/:id" exact element={<EditEmployee />} />
          <Route path="/Dashboard" exact element={<Dashboard />} />
          <Route path="/TimeTracker" exact element={<TimeTracker />} />
          <Route path="/UserProfile" exact element={<UserProfile />} />
          <Route path="/UploadFile" exact element={<FileUploadPage />} />
          <Route path="/EmployeeList" exact element={<EmployeeList />} />
          <Route path="/ViewAllLeave" exact element={<HandleAssignedAdminLeave />} />
          <Route path="/AppliedReimbursement" exact element={<AdminReimbursement />} />
          <Route path="/Dashboard/DisplayData" exact element={<DisplayTableData />} />
          <Route path="/Manage-Holidays" exact element={<ManageHolidays />}></Route>
          <Route path="/Admin-Salaryslip" exact element={<AdminSalarySlip />}></Route>
          <Route path="/Generate-Salaryslip" exact element={<GenerateSalarySlip />}></Route>
          <Route path="/Admin-Salaryslip/:Payslips_id" exact element={<DeatilsSalarySlipsById />}></Route>
        </Route>
      </Route>
    </Routes>
  );
};
export default AppRoutes;
