
import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import './FileUploadPage.css';
import api from '../../Authenticaltion/api';
import AbsentGraphChart from './PageComponent/AbsentGraphChart';
import Latecomer15Days from './PageComponent/Latecomer15Days'
import AllPendingLeave from './PageComponent/AllPendingLeave';
import NewEmployeeList from '../Component/NewEmployeeList';
import { useLoading } from '../../LoadingContext';

const Dashboard = () => {
    const { updateLoading } = useLoading()
    const [employeeData, setEmployeeData] = useState({
        totalEmployees: 0,
        presentEmployees: 0,
        absentEmployees: 0,
        latecomerEmployees: 0,
    });
    const overviewItems = [
        {
            title: 'Total Attendance',
            icon: 'fas fa-clipboard-check',
            value: employeeData.totalEmployees,
            detailsKey: 'Present',
            column: "c1",
            color: "#455dfc, #3945d5, #2a2daf, #19168a, #000068"
        },

        {
            title: 'Monthly Present',
            icon: 'zmdi zmdi-check-circle',
            value: employeeData.presentEmployees,
            detailsKey: 'ThisMonthPresent',
            column: "c2",
            color: "#03a622, #02951d, #018519, #017514, #006610"
        },
        {
            title: 'Monthly Tardiness',
            icon: 'zmdi zmdi-alarm-snooze',
            value: employeeData.absentEmployees,
            detailsKey: 'ThisMonthLateArrivals',
            column: "c3",
            color: "#d6df22, #cdcf1b, #c3bf15, #b9b00f, #afa109"
        },
        {
            title: 'Monthly Absent',
            icon: 'zmdi zmdi-minus-circle',
            value: employeeData.latecomerEmployees,
            detailsKey: 'Absent',
            column: "c4",
            color: " #ff0000, #ed0000, #db0000, #ca0000, #b90000"
        },
    ];
    useEffect(() => {
        // Set loading to true when fetching data
        updateLoading(true);

        // Fetch data from the API
        api.get('/AnalyticsData')
            // Replace with your API URL
            .then((data) => {
                const totalEmployees = data.data[0];
                const presentEmployees = data.data[1];
                const absentEmployees = data.data[0] - data.data[1];
                const latecomerEmployees = data.data[2];
                setEmployeeData({
                    totalEmployees,
                    presentEmployees,
                    absentEmployees,
                    latecomerEmployees,
                });

                // Set loading to false after data is received
                updateLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                // Set loading to false in case of an error
                updateLoading(false);
            });
    }, []);

    return (
        <>
            <div class=" shadow p-3 bg-white rounded">
                <div class="h5 pb-2 mt-2 mb-2 border-bottom text-primary">
                    OverView
                </div>
                <div className="row mt-2 ">
                    {overviewItems.map((item) => (
                        <div className="col-lg-3 col-xs-6" key={item.title}>
                            <div className="card text-white p-2 rounded"
                                style={{
                                    backgroundImage: `linear-gradient(to right, ${item.color})`,
                                    border: "none"
                                }}
                            >
                                <div className="row icon align-items-center">
                                    <div className="row">
                                        <div className="col">
                                            <h4 className='row text-white text-nowrap ml-2'>{item.title}</h4>
                                            <h4 className='row text-white text-nowrap ml-2 mt-3'>{item.value}</h4>
                                        </div>
                                        <div className='col text-right'>
                                            <i className={item.icon} style={{ opacity: "0.8" }}></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
                <div className="row">
                    <Latecomer15Days />
                    <AbsentGraphChart />
                </div>
                <div className="row">
                    <AllPendingLeave />
                    <NewEmployeeList />
                </div>
            </div>
        </>
    );
};

export default Dashboard;



