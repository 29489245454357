import React, { useState, useEffect } from 'react';
import { useLoading } from "../../LoadingContext";
import { toast } from 'react-toastify';
import api from '../../Authenticaltion/api';
import './CompanyRule.css'
import { Dropdown } from 'bootstrap';
import { Dialog } from 'primereact/dialog';
import Swal from 'sweetalert2';
const CompnayRule = () => {
  const [attendanceData, setAttendanceData] = useState({});
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [visible, setvisible] = useState(false);
  const [showAddSubtypeForm, setShowAddSubtypeForm] = useState(false);
  useEffect(() => {
    // Fetch leave types for the select dropdown
    const fetchLeaveTypes = async () => {
      try {
        const response = await api.get('/CompanyLeaveType');
        setLeaveTypes(response.data.leaveTypes);
      } catch (error) {
        console.error('Error fetching leave types', error);
      }
    };

    fetchLeaveTypes();
  }, []);
  const [leaveSubtype, setLeaveSubtype] = useState({
    LeaveTypeID: '',
    SubTypeName: '',
    minDays: '',
    MaximumDays: '',
    RequiredDaysToApply: '',
    ApplicableForAll: false
  });
  const [leaveData, setLeaveData] = useState([]);
  const [isAttendanceRuleAvailable, setIsAttendanceRuleAvailable] = useState(false);
  const { updateLoading } = useLoading()
  const [showLeaveForm, setShowLeaveForm] = useState(false);
  const [ShowDialog, setShowDialog] = useState();

  const [leaveFormData, setLeaveFormData] = useState({
    leaveOption: null,
    leaveName: null,
    leaveBalance: null,
  });
  const [leaveSubtypeOptions, setLeaveSubtypeOptions] = useState({
    LeaveTypeID: null,
    SubTypeName: null,
    minDays: null,
    MaximumDays: null,
    RequiredDaysToApply: null,
    applicableForAll: false
  });
  const DayName = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
  const [showAttendanceForm, setShowAttendanceForm] = useState(false);

  const handleAttendanceChange = (event) => {
    const { name, value } = event.target;

    // Additional validation logic
    if (name === 'intime' && attendanceData.outtime !== '' && value >= attendanceData.outtime) {
      toast.warning('In Time should be earlier than Out Time.');
      return;
    }

    if (name === 'outtime' && attendanceData.intime !== '' && value <= attendanceData.intime) {
      toast.warning('Out Time should be later than In Time.');
      return;
    }

    if (name === 'lateTolerance' && attendanceData.intime !== '' && value <= attendanceData.intime) {
      toast.warning('Late Tolerance should be later than In Time.');
      return;
    }

    setAttendanceData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleLeaveSubmit = async (event) => {
    event.preventDefault();
    if (!leaveFormData.leaveName) {
      toast.error('Leave type name is required.');
      return;
    }  
    console.log(leaveFormData);
    const trimmedLeaveName = leaveFormData.leaveName.trim();
    if(!trimmedLeaveName){
      toast.error('Leave type name is required.');
      return;
    }
    if (leaveFormData.leaveOption === 'true') {
      const leaveBalance = parseInt(leaveFormData.leaveBalance, 10);
      if (isNaN(leaveBalance) || leaveBalance < 1 || leaveBalance > 365) {
        toast.error('Leave balance must be a number between 1 and 365.');
        return;
      }
    }
    const existingLeave = leaveData.find(leave =>
      leave.TypeName.toLowerCase() === leaveFormData.leaveName.toLowerCase() && leave.IsActive
    );

    if (existingLeave) {
      toast.error('Leave type name already exists.');
      return;
    }

    const DataSend = {
      TypeName: leaveFormData.leaveName,
      LeaveBalance: leaveFormData.leaveBalance || 0,
      IsCreditType: leaveFormData.leaveOption == 'true' ? true : false
    };

    try {
      const response = await api.post(`/CompanyLeaveType`, DataSend);
      if (response.status === 200) {
        toast.success('Leave type created successfully.');

      } else {
        toast.error(`Error: ${response.data.error}`);
      }
    } catch (error) {
      console.error('Error saving leave data:', error);
      // toast.error(`Error saving leave data: ${error.response?.data?.message || error.message}`);
    }
    finally {
      setLeaveFormData({
        leaveOption: null,
        leaveName: null,
        leaveBalance: null,
      })
      setShowLeaveForm(false)
      fetchCompanyLeaveDistributions()
    }
  };
  const viewLeaveType = (leave) => {
    setShowDialog(leave)
    setvisible(true)
  }
  const DeleteLeave = async (leave) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete it!"
      });

      if (result.isConfirmed) {
        const deleteResponse = await api.delete(`/DeleteCompanyLeaveType/${leave}`);
        if (deleteResponse.status === 200) {
          await Swal.fire({
            icon: "success",
            title: "Successfully Deleted",
            showConfirmButton: false,
            timer: 1500
          });
          fetchCompanyLeaveDistributions();
        } else {
          toast.error("Deletion Failed");
        }
      }
    } catch (error) {
      toast.error("Deletion Failed");
      console.error("Error deleting leave:", error);
    }
  };

  const fetchCompanyLeaveDistributions = async (event) => {
    // Check if event exists and has preventDefault method
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    updateLoading(true);
    try {
      const leaveresult = await api.get('/CompanyLeaves');
      if (leaveresult.status === 200) {
        if (leaveresult.data.message === "No leave type found") {
          // toast.error(leaveresult.data.message);
        } else {
          setLeaveData(leaveresult.data);
          console.log(leaveresult.data);
        }
      } else {
        toast.error(leaveresult.data.err);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      updateLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanyLeaveDistributions()
    fetchCompanyAttendanceRule()
  }, []);

  const fetchCompanyAttendanceRule = async () => {
    updateLoading(true)
    try {
      const attendanceResult = await api.get('/company_attendance_rule')
      if (attendanceResult.status === 200) {
        setAttendanceData(attendanceResult.data[0]);
        console.log(attendanceResult.data[0])
        if (attendanceResult.data[0].attendance_rules_id != null) {
          console.log("Set is mname")
          setIsAttendanceRuleAvailable(true);
        }

      } else {
        setIsAttendanceRuleAvailable(false);
      }
    }
    catch (error) {
      toast.error(error)
    }
    finally {
      updateLoading(false)
    }
  }

  const handleSubmit = async (event) => {
    // Check if all required fields are filled
    for (const key in attendanceData) {
      if (attendanceData[key] === '') {
        toast.error('Please fill in all fields.');
        return;
      }
    }

    try {
      // Send the attendanceData to the backend API
      const response = await api.post('/saveAttendanceRule', attendanceData);

      if (response.status === 200) {
        toast.success('Attendance rule saved successfully.');

        // Perform any additional actions after successful save
      } else {
        toast.error(response.error);
      }
    } catch (error) {
      toast.error(`Error saving attendance rule: ${error}`);
    }
    finally {
      showAttendanceForm(false)
      fetchCompanyAttendanceRule()
    }
  };
  const handleLeaveSubtypeSubmit = async (e) => {
    console.log("leaveSubtypeOptions", leaveSubtypeOptions);

    e.preventDefault();
    if (!ShowDialog?.LeaveTypeID || !leaveSubtypeOptions.SubTypeName || leaveSubtypeOptions.minDays == null || leaveSubtypeOptions.MaximumDays == null || leaveSubtypeOptions.RequiredDaysToApply == null || leaveSubtypeOptions.RequiredDaysToApply === '') {
      toast.error('Enter all required Fields');
      return;
  }
  if(leaveSubtypeOptions.minDays > leaveSubtypeOptions.MaximumDays){
    toast.error('Minimum days cannot be greater than Maximum days');
    return;

  }
  

    const DataTosend = {
      LeaveTypeID: ShowDialog?.LeaveTypeID,
      SubTypeName: leaveSubtypeOptions.SubTypeName,
      MinDays: leaveSubtypeOptions.minDays,
      MaximumDays: leaveSubtypeOptions.MaximumDays,
      RequiredDaysToApply: leaveSubtypeOptions.RequiredDaysToApply,
      ApplicableForAll: true
    }
    try {
      const response = await api.post('/leaveSubtypeWithOptions', DataTosend);
      setLeaveSubtype({
        LeaveTypeID: '',
        SubTypeName: '',
        minDays: '',
        MaximumDays: '',
        RequiredDaysToApply: '',
        applicableForAll: false

      });
    } catch (error) {
      console.error(error);
      toast.error('Error adding leave subtype options');
    }
    finally {
      setLeaveSubtype({
        LeaveTypeID: '',
        SubTypeName: '',
        minDays: '',
        MaximumDays: '',
        RequiredDaysToApply: '',
        applicableForAll: false

      });
      setShowAddSubtypeForm(false)
      setShowLeaveForm(false)
      setvisible(false)
      fetchCompanyLeaveDistributions()
    }
  };
  const daysOfWeekOptions = [
    { label: 'Sunday', value: 1 },
    { label: 'Monday', value: 2 },
    { label: 'Tuesday', value: 3 },
    { label: 'Wednesday', value: 4 },
    { label: 'Thursday', value: 5 },
    { label: 'Friday', value: 6 },
    { label: 'Saturday', value: 7 },
  ];

  return (
    <div className="row m-3">
      <div className="col-xl-6 col-sm-12 col-md-12">
        <div className="row">
          <div className="col-12 d-flex justify-content-between align-items-center">
            <div>
              <h4 className="text-primary">Leave Information</h4>
              <small style={{ color: 'red' }}> * Leave balance is automatically updated annually.</small>
            </div>
            <div>
              <button
                onClick={() => setShowLeaveForm(!showLeaveForm)}
                className="btn"
                style={{ backgroundColor: '#6366F1', color: 'white' }}
              >
                Add Leave Rules
              </button>
            </div>
          </div>
        </div>
        {/* Your leave information form can go here */}
        {showLeaveForm && (
          <div className='row'>
            <div className="col-xl-6 col-sm-12 col-md-12">
              {showLeaveForm && (
                <div className="row mt-3">
                  <div className="leave-form col-12">
                    <form>
                      <select
                        className="form-control mb-3"
                        onChange={(e) => {
                          setLeaveFormData({
                            ...leaveFormData,
                            leaveOption: e.target.value,
                          });
                        }}
                      >
                        <option value="" disabled selected>
                          Select leave option
                        </option>
                        <option value="true">Creditable leave</option>
                        <option value="false">Non-creditable leave</option>
                      </select>
                      <label>
                        Leave Title<span style={{ color: 'red' }}>*</span>
                      </label>
                      <input
                        className="form-control mb-3"
                        type="text"
                        name="leaveName"
                        placeholder="Leave Title"
                        onChange={(e) => {
                          setLeaveFormData({
                            ...leaveFormData,
                            leaveName: e.target.value,
                          });
                        }}
                        required
                      />
                      {leaveFormData.leaveOption === 'true' && (
                        <>
                          <label>
                            Leave Balance<span style={{ color: 'red' }}>*</span>
                          </label>
                          <small className="form-text text-muted">
                            maximum leave balance of 365.
                          </small>
                          <input
                            className="form-control mb-3"
                            type="number"
                            name="leaveBalance"
                            placeholder="Leave Balance"
                            onInput={(e) => {
                              let value = e.target.value;
                              if (value !== "") {
                                // Convert to number and clamp between 1 and 365
                                value = Math.max(1, Math.min(365, Number(value)));
                              }
                              setLeaveFormData({
                                ...leaveFormData,
                                leaveBalance: value,
                              });
                            }}
                            value={leaveFormData.leaveBalance}
                            min="1"
                            max="365"
                            pattern="[1-9][0-9]{0,2}|365"
                            required
                          />

                        </>
                      )}
                      <button
                        className="btn"
                        style={{ backgroundColor: '#6366F1', color: 'white', width: '100%' }}
                        onClick={(event) => handleLeaveSubmit(event)}
                      >
                        Add Leave Type
                      </button>
                    </form>
                  </div>
                </div>
              )}

            </div>
          </div>
        )}
        <div >
          <div className="row mt-3">
            {leaveData && leaveData.length > 0 ? (
              leaveData.map((leave) => (
                leave.IsActive == '1' && (
                  <div key={leave.LeaveTypeID} className="col-xl-4 md-6">
                    <div className="card h-40 bg-white leave-item position-relative col-xl-4-md-3" style={{ cursor: 'pointer' }}>
                      <div className="position-absolute top-0 end-0 p-2">
                        <i className="fas fa-trash-alt" style={{ color: 'red', cursor: 'pointer' }} onClick={() => DeleteLeave(leave.LeaveTypeID)}></i>
                      </div>
                      <div className="card-body mt-3">
                        <div className="mb-2">
                          <strong className="d-inline" style={{ color: 'green' }}>{leave.TypeName}</strong>
                          <br />
                          <h6 className="d-inline">Leave Balance:</h6> {leave.LeaveBalance}
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-items-center mb-2">
                        <button className='btn btn-primary btn-sm' onClick={() => viewLeaveType(leave)}>View</button>
                      </div>
                    </div>
                  </div>
                )
              ))
            ) : (
              <div className="col">
                <p>There is no leave type available.</p>
              </div>
            )}
          </div>
        </div>
        <Dialog visible={visible} showHeader={false} style={{ width: '35vw', cursor: 'pointer' }} >
          <div className="row position-relative d-flex w-100" >
            <div className='w-100'>
              <h5 className="card-title d-flex justify-content-between align-items-center ml-3 " >
                <u style={{ color: "#1531EB" }}>Leave type Details</u>
                <i className="fa fa-times text-danger" onClick={() => { setvisible(false); setShowAddSubtypeForm(false) }}></i>
              </h5>
              <div className="col-md-12 mt-2">
                <i className="fa fa-home" /><span className="contentLable ml-2">Leave type : <span className="AppliedDate">{ShowDialog?.TypeName}</span> </span>
              </div>
              <div className="col-md-12 mt-1">
                <i className="fa fa-calendar" />
                <span className="contentLable ml-2">Leave Balance : <span className="DateDuration">{ShowDialog?.LeaveBalance}</span></span>
                <div className='d-flex justify-content-end'>
                  <button className='btn btn-primary' style={{ backgroundColor: '#6366F1', color: 'white' }} onClick={() => setShowAddSubtypeForm(!showAddSubtypeForm)}>Add Sub type</button>
                </div>
              </div>
            </div>
            <div className='w-100 d-flex justify-content-center'>
              {showAddSubtypeForm && (
                <div className=' card ml-3 mt-2 p-2'>
                  <form>
                    <h6 className='text-center mt-2'><u>Add Leave Subtype</u></h6>
                    <div className="row mb-3">
                      <div className="col-xl-12 col-sm-12 col-md-12">
                        <label>Leave Subtype Name <span style={{ color: 'red' }}>*</span></label>
                        <input
                          className="form-control"
                          type="text"
                          name="SubTypeName"
                          placeholder="Leave Subtype Name"
                          value={leaveFormData.SubTypeName}
                          onChange={(e) => {
                            setLeaveSubtypeOptions({
                              ...leaveSubtypeOptions,
                              SubTypeName: e.target.value,
                            });
                          }}
                          required
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
  <div className="col-xl-12 col-sm-12 col-md-12">
    <label>Require date to apply <span style={{ color: 'red' }}>*</span></label>
    
    <input
      className="form-control"
      type="number"
      name="RequiredDays"
      placeholder="Required Days"
      value={leaveSubtypeOptions.RequiredDaysToApply}
      onInput={(e) => {
        let value = e.target.value;
        if (value !== "") {
          // Convert to number and clamp between 1 and 365
          value = Math.max(0, Math.min(365, Number(value)));
        }
        setLeaveSubtypeOptions({
          ...leaveSubtypeOptions,
          RequiredDaysToApply: value, // Use clamped value here
        });
      }}
      min="1"
      required
    />
    <small className="text-muted">Maximum 365 days</small>
  </div>
</div>


<div className="row mb-3">
  <div className="col-xl-6 col-sm-12 col-md-12">
    <label>Minimum Days <span style={{ color: 'red' }}>*</span></label>
    <input
      className="form-control"
      type="number"
      name="minDays"
      placeholder="Min Days"
      value={leaveSubtypeOptions.minDays}
      onChange={(e) => {
        let value = e.target.value;
        if (value !== "") {
          value = Math.max(0, Math.min(365, Number(value)));
        }
        setLeaveSubtypeOptions({
          ...leaveSubtypeOptions,
          minDays: value,
        });
      }}
      min="0"
      required
    />
    <small className="text-muted">Maximum 365 days</small>
  </div>
  <div className="col-xl-6 col-sm-12 col-md-12">
    <label>Maximum Days <span style={{ color: 'red' }}>*</span></label>
    <input
      className="form-control"
      type="number"
      name="maximumDays"
      placeholder="Max Days"
      value={leaveSubtypeOptions.MaximumDays}
      onChange={(e) => {
        let value = e.target.value;
        if (value !== "") {
          value = Math.max(0, Math.min(365, Number(value)));
        }
        setLeaveSubtypeOptions({
          ...leaveSubtypeOptions,
          MaximumDays: value,
        });
      }}
      min="0"
      required
    />
    <small className="text-muted">Maximum 365 days</small>
  </div>
</div>




                    <button className="btn btn-primary mt-3 mx-auto d-block" style={{ backgroundColor: '#6366F1', color: 'white' }} onClick={handleLeaveSubtypeSubmit}>
                      Submit
                    </button>
                  </form>
                </div>

              )}
            </div>
            <div className='W-100 '>
              {ShowDialog?.subtypeInformation && ShowDialog.subtypeInformation.length > 0 ? (
                ShowDialog.subtypeInformation.map((subleave) => (
                  <div key={subleave.SubTypeID} className='card ml-3 mt-2 p-0'>
                    <div className='card-container p-2'>
                      <span>Leave subtype name : </span><strong>{subleave.SubTypeName}</strong><br />
                      <span>Must Apply Before : </span><strong>{subleave.RequiredDaysToApply ?? 'N/A'}</strong> days<br />
                      <span>Maximum Days : </span><strong>{subleave.MaximumDays ?? 'N/A'}</strong><br />
                      <span>Minimum Days : </span><strong>{subleave.MinDays ?? 'N/A'}</strong><br />
                      <span>Applicable For All: </span><strong>{subleave.ApplicableForAll ? 'Yes' : 'No'}</strong><br />
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-12">
                  <p>No subtypes available.</p>
                </div>
              )}
            </div>
          </div>
        </Dialog>
      </div>
      <div className="col-xl-6 col-sm-12 col-md-12">
        <div className="row">
          <div className="col-12 d-flex justify-content-between align-items-center">
            <div>
              <h4 className='text-primary'>Attendance Information</h4>
            </div>
            <div>
              <button
                onClick={() => setShowAttendanceForm(!showAttendanceForm)}
                disabled={isAttendanceRuleAvailable}
                className='btn'
                style={{ backgroundColor: '#6366F1', color: 'white' }}
              >
                {isAttendanceRuleAvailable ? 'Rule Already Set' : 'Add Attendance Rule'}
              </button>
            </div>
          </div>
          <div className="col-12">
            <small style={{ color: 'red' }}>* Attendance can only be entered once and cannot be changed.</small>
          </div>
          <div className="col-12">
            {showAttendanceForm && (
              <form className="shadow p-3 bg-white rounded mt-3" onSubmit={handleSubmit}>
                <label >
                  In Time<span style={{ color: 'red' }}>*</span>
                  <input
                    type="time"
                    name="intime"
                    className="time-input form-control"
                    value={attendanceData.intime}
                    onChange={handleAttendanceChange}
                    required
                  />
                </label>
                <label>
                  Out Time<span style={{ color: 'red' }}>*</span>
                  <input
                    type="time"
                    name="outtime"
                    className="time-input form-control"
                    value={attendanceData.outtime}
                    onChange={handleAttendanceChange}
                    required
                  />
                </label>
                <label>
                  Late Tolerance<span style={{ color: 'red' }}>*</span>
                  <input
                    type="time"
                    name="lateTolerance"
                    className="time-input form-control"
                    value={attendanceData.lateTolerance}
                    onChange={handleAttendanceChange}
                    required
                  />
                </label>
                <label>
                  Total Days Late Excluded<span style={{ color: 'red' }}>*</span>
                  <input
                    type="number"
                    name="totalDaysLateExcluded"
                    className="form-control"
                    value={attendanceData.totalDaysLateExcluded}
                    onChange={handleAttendanceChange}
                    required
                  />
                </label>
                <label>
                  Work Week Start<span style={{ color: 'red' }}>*</span>
                  <select
                    name="workWeekStart"
                    className="form-control"
                    value={attendanceData.workWeekStart}
                    onChange={handleAttendanceChange}
                    required
                  >
                    <option value="">Select Day</option>
                    {daysOfWeekOptions.map((day) => (
                      <option key={day.value} value={day.value}>{day.label}</option>
                    ))}
                  </select>
                </label>
                <label>
                  Work Week End<span style={{ color: 'red' }}>*</span>
                  <select
                    name="workWeekEnd"
                    className="form-control"
                    value={attendanceData.workWeekEnd}
                    onChange={handleAttendanceChange}
                    required
                  >
                    <option value="">Select Day</option>
                    {daysOfWeekOptions.map((day) => (
                      <option key={day.value} value={day.value}>{day.label}</option>
                    ))}
                  </select>
                </label>
                <button className="btn btn-block" style={{ backgroundColor: '#6366F1', color: 'white' }}>Save</button>
              </form>
            )}
            {isAttendanceRuleAvailable && (
              <div className="shadow p-3 bg-white rounded mt-3">
                <small style={{ color: 'red' }}>* Contact administrations for any changes.</small>
                <p><strong>In Time:</strong> {attendanceData.in_time}</p>
                <p><strong>Out Time:</strong> {attendanceData.out_time}</p>
                <p><strong>Late Exclusion:</strong> {attendanceData.InTimeThreshold}</p>
                <p><strong>Total Days Late Excluded Each Month:</strong> {attendanceData.late_tolerance}</p>
                <p><strong>Work Start Day:</strong> {DayName[attendanceData.work_week_start - 1]}</p>
                <p><strong>Work End Day:</strong> {DayName[attendanceData.work_week_end - 1]}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div >
  )
}

export default CompnayRule
