import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../../Authenticaltion/api';
import { toast } from 'react-toastify';
import { useLoading } from '../../LoadingContext';

const AdminSalarySlip = () => {
  const { updateLoading } = useLoading();
  const [AllSalarySlip, setAllSalarySlip] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(12); // Default items per page
  const [searchQuery, setSearchQuery] = useState(''); // For storing search query
  const [sortBy, setSortBy] = useState('payslip_id'); // Default sorting column
  const [sortOrder, setSortOrder] = useState('desc'); // Default sort order

  const fetchSalarySlips = async (page = 1) => {
    try {
      updateLoading(true);
      const offset = (page - 1) * limit;
      const response = await api.get('/ShowAdminPayslip', {
        params: {
          limit,
          offset,
          searchName: searchQuery, // Use search term
          sortBy,
          sortOrder,
        }
      });
      
      setAllSalarySlip(response.data.items.map(item => ({
        ...item,
        issue_date: new Date(item.issue_date).toLocaleDateString('en-GB')
      })));
      setTotalRecords(response.data.totalRecords);
      setTotalPages(response.data.totalPages);
      setCurrentPage(page);
    } catch (error) {
      toast.error('Unable to fetch salary slips');
    } finally {
      updateLoading(false);
    }
  };

  useEffect(() => {
    fetchSalarySlips(currentPage);
  }, [currentPage, searchQuery, sortBy, sortOrder]);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1); // Reset to first page on search
    fetchSalarySlips(1);
  };

  const handleSort = (column) => {
    if (column === sortBy) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc'); // Reset to ascending on new column sort
    }
  };

  const renderPagination = () => {
    const pageNumbers = [];
    const visiblePages = 5;

    if (totalPages <= visiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - 2);
      const endPage = Math.min(totalPages, currentPage + 2);

      if (startPage > 1) {
        pageNumbers.push(1);
        if (startPage > 2) pageNumbers.push('...');
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (endPage < totalPages - 1) {
        pageNumbers.push('...');
      }
      if (endPage < totalPages) {
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers.map((page, index) => (
      <li
        key={index}
        className={`page-item ${currentPage === page ? 'active' : ''} ${page === '...' ? 'disabled' : ''}`}
      >
        {page === '...' ? (
          <span className="page-link">...</span>
        ) : (
          <button className="page-link" onClick={() => handlePageChange(page)}>
            {page}
          </button>
        )}
      </li>
    ));
  };

  return (
    <div className="shadow p-3 bg-white rounded" style={{ minHeight: '85vh' }}>
      <div className="h5 pb-2 mt-2 mb-2 border-bottom text-primary">
        Admin Salary Slips
      </div>

      {/* Search Bar and Generate Button */}
      <div className="d-flex justify-content-between mb-3">
        {/* Left side: Search Field */}
        <form className="form-inline" onSubmit={handleSearch}>
          <input
            type="text"
            className="form-control mr-sm-2"
            placeholder="Search by Employee Name"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button type="submit" className="btn btn-outline-primary my-2 my-sm-0">
            Search
          </button>
        </form>

        {/* Right side: Generate Salary Slip Button */}
        <Link to="/Generate-Salaryslip" className="btn btn-primary">
          Generate Salary Slip
        </Link>
      </div>

      {/* Salary Slip Cards */}
      <div className="row mt-4">
        {AllSalarySlip.length > 0 ? (
          AllSalarySlip.map((item) => (
            <div key={item.payslip_id} className="col-xl-3 col-sm-6 mb-4">
              <Link to={`/Admin-Salaryslip/${item.payslip_id}`} style={{ textDecoration: 'none', display: 'flex' }}>
                <div className="card leave-item p-3" style={{ backgroundColor: '#f8f9fa', cursor: 'pointer', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', transition: 'transform 0.2s', position: 'relative', width: '100%' }}>
                  <div className="leave_info" style={{ whiteSpace: 'nowrap', fontSize: '0.9rem', color: '#6c757d' }}>
                    <i className="fa fa-calendar mr-2" style={{ color: '#007bff' }} />
                    Generated on: <span className="ml-1">{item.issue_date}</span>
                  </div>
                  <div className="d-flex justify-content-between mt-2">
                    <div className="leave_info" style={{ whiteSpace: 'nowrap', fontSize: '0.9rem', color: '#6c757d' }}>
                      <i className="fa fa-calendar mr-2" style={{ color: '#007bff' }} />
                      Month: <span className="ml-1">{item.salary_month}-{item.salary_year}</span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-2">
                    <div className="leave_info" style={{ whiteSpace: 'nowrap', fontSize: '0.9rem', color: '#6c757d' }}>
                      <i className="fa fa-user mr-2" style={{ color: '#007bff' }} />
                      <span className="ml-2" style={{ fontSize: '1rem', fontWeight: '500', color: '#343a40' }}>{item.employee_name}</span>
                    </div>
                  </div>
                  <div className="icon-container" style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
                    <i className="fas fa-file-alt" style={{ fontSize: '3rem', opacity: '0.2' }}></i>
                  </div>
                </div>
              </Link>
            </div>
          ))
        ) : (
          <div className="col-12">
            <p className="text-center">No salary slips available at this time</p>
          </div>
        )}
      </div>

      {/* Pagination */}
      {totalRecords > 0 && (
        <div className="d-flex justify-content-end mt-4">
          <nav>
            <ul className="pagination">
              <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                  Previous
                </button>
              </li>
              {renderPagination()}
              <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
};

export default AdminSalarySlip;
