import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import api from '../../Authenticaltion/api';
import './HolidaysPage.css'
import { useLoading } from '../../LoadingContext';
const Holidays = () => {
  const [data, setData] = useState([]);
  const { updateLoading } = useLoading()

  const fetchDataFromApi = async () => {
    updateLoading(true)
    try {
      const response = await api.get('/Holidays');
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    finally {
      updateLoading(false)
    }
  };

  useEffect(() => {
    fetchDataFromApi();
  }, []);

  const columns = [
    {
      dataField: 'Sl_No',
      text: 'Sl. No',
    },
    {
      dataField: 'Holiday_Name',
      text: 'Holiday Name',
      formatter: (cell, row) => {
        const startDateParts = row.StartDate.split('-');
        const startDate = new Date(
          startDateParts[2],
          startDateParts[1] - 1,
          startDateParts[0]
        );
        const currentDate = new Date();

        let textColor = '';

        if (currentDate < startDate) {
          textColor = '#2e7d32'; // Green for upcoming leaves
        } else if (currentDate > startDate) {
          textColor = '#c62828'; // Red for passed leaves
        }

        return <div style={{ color: textColor }}>{cell}</div>;
      },
    },
    {
      dataField: 'StartDate',
      text: 'Start Date',
    },
    {
      dataField: 'End_Date',
      text: 'End Date',
    },
    {
      dataField: 'No_Of_Days',
      text: 'No. of Days',
      formatter: (cell, row) => {
        const startDateParts = row.StartDate.split('-');
        const endDateParts = row.End_Date.split('-');

        const startDate = new Date(
          startDateParts[2],
          startDateParts[1] - 1,
          startDateParts[0]
        );
        const endDate = new Date(
          endDateParts[2],
          endDateParts[1] - 1,
          endDateParts[0]
        );

        const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;
        return diffDays;
      },
    },
    {
      dataField: 'Day_Of_Week',
      text: 'Day of Week',
      formatter: (cell, row) => {
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
        const startDateParts = row.StartDate.split('-');
        const startDate = new Date(
          startDateParts[2],
          startDateParts[1] - 1,
          startDateParts[0]
        );
        const dayOfWeek = daysOfWeek[startDate.getDay()];
        return dayOfWeek;
      },
    },
  ];

  const options = {
    custom: true,
    totalSize: data.length,
  };

  return (
    <div class=" shadow p-3 bg-white rounded">
      <div class="h5 pb-2 mt-2 mb-2 border-bottom text-primary">
        Holidays
      </div>
      {data.length === 0 ? (
        <p>Holidays not available</p>
      ) : (
        <PaginationProvider pagination={paginationFactory(options)}>
          {({ paginationProps, paginationTableProps }) => (
            <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
              <div className="table-responsive">
                <div className="scroll-table-container">
                  {/* Add a container with fixed height and scroll */}
                  <div className="scroll-table">
                    <BootstrapTable
                      keyField="Sl_No"
                      data={data}
                      columns={columns}
                      responsive
                      {...paginationTableProps}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <PaginationListStandalone {...paginationProps} />
              </div>
            </div>
          )}
        </PaginationProvider>
      )}
    </div>
  );
};

export default Holidays;
