import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { useState, useEffect } from 'react';
import "./WorkFromHome.css"
import api from '../../Authenticaltion/api';
import { Dialog } from 'primereact/dialog';
const AssignedWorkFromhome = () => {
    const [ViewDialog, setViewDialog] = useState(false);
    const [AppliedWFH, setAppliedWFH] = useState("")
    const [SelectedWFH, setSelectedWFH] = useState("")
    const [ApprovalStatus, setApprovalStatus] = useState("")
    const [Approval_Text, setApproval_Text] = useState("")
    const ViewWFH = (leave) => {
        setSelectedWFH(leave);
        setViewDialog(true)
    }
    const ColoseDialog = () => {
        setViewDialog(false)
    }
    const getAssignedWfhData = async () => {
        try {
            const response = await api.get('/getAssignedWfhData');
            if (response.status === 200) {
                console.log("Assigned_WFH", response.data.data)
                setAppliedWFH(response.data.data)

            } else {
                console.error('Error fetching Work From Home', response.data.error);
            }
        } catch (error) {
            console.error('Error fetching Work From Home', error);
        }
    }
    const approval_Txt = (event) => {
        setApproval_Text(event.target.value);
    }
    const approval_status = (event) => {
        console.log(event.target.value);
    };

    // const handleApproval = async (event) => {
    //     event.preventDefault();

    //     const approveWFH = {
    //         id: SelectedWFH.id,
    //         approvalStatus: ApprovalStatus,
    //         approval_Text: Approval_Text
    //     };

    //     try {
    //         const response = await axios.post('/approvalWorkFromHome', approveWFH, {
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             }
    //         });
    //         console.log(response);
    //     } catch (error) {
    //         console.error('Error:', error);
    //     }
    // };

    useEffect(() => {
        getAssignedWfhData();
    }, [])
    return (
        <div class=" shadow p-3 bg-white rounded">
            <div class="h4 pb-2 mt-2 mb-2 border-bottom text-primary">
                Assigned Work From Home
            </div>
            <form class="WFHForm mt-4">
                <div class="row">
                    <div class="col-md-3 col-sm-4 mb-6">
                        <input type="date" class="form-control" id="input" />
                    </div>
                    <div class="col-md-3 col-sm-4 mb-6">
                        <input type="date" class="form-control" id="input" />
                    </div>
                    <div class="col-md-3 col-sm-4 mb-6">
                        <Dropdown
                            placeholder='Status'
                            options={[
                                { label: 'Pending', value: 1 },
                                { label: 'Approved', value: 0.5 },
                                { label: 'Rejected', value: 0.5 },
                            ]}
                            style={{ width: '100%', height: '37px' }}
                        />
                    </div>
                    <div class="col-md-3 col-sm-12 mb-6">
                        <button type="button" class="btn btn-light col-sm-12" style={{ borderRadius: "20px" }}>
                            <i class="fas fa-search"></i> Search
                        </button>
                    </div>
                </div>
                <div class="row mt-4">
                    {Array.isArray(AppliedWFH) && AppliedWFH.map(leave => (
                        <div class="col-md-3" key={leave.id}>
                            <div class="card" style={{ border: "none", boxShadow: "4px 6px 3px #B3A9A9" }} >
                                <div class="card-header" style={{ border: "none", backgroundColor: "#EBEEF8" }}>
                                    <h4 class="card-title d-flex justify-content-between align-items-center" style={{ color: "#1531EB" }}>
                                        Work From Home
                                    </h4>
                                </div>
                                <div class="card-body" style={{ backgroundColor: "#F8F8F8" }}>
                                    <div class="card-content" onClick={() => ViewWFH(leave)}>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <i class="fa fa-user" /><span class="contentLable ml-2" style={{ color: "#1531EB" }}> {leave.Name}</span>
                                            </div>
                                            <div class="col-md-12 mt-2">
                                                <i class="fa fa-clock" /><span class="contentLable ml-2">Applied on:<span class="AppliedDate"> {leave.applied_date}</span> </span>
                                            </div>
                                            <div class="col-md-12 mt-2">
                                                <i class="fa fa-calendar" />
                                                <span class="contentLable ml-2">WFH from: <span class="DateDuration"> {leave.wfh_from}</span></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer text-center" style={{ backgroundColor: "#2866FF", border: "none" }} onClick={() => ViewWFH(leave)}>
                                    <span class="CurrStatus" style={{ color: leave.status === 'Pending' ? '#FFE617' : (leave.status === 'Approved' ? '#7FFD0F' : '#FB1409') }}>{leave.status}</span>
                                </div>
                            </div>
                        </div>
                    ))}{AppliedWFH.length === 0 && (
                        <div className="col-12">
                            <p className="text-center">No one applied Work From Home yet .</p>
                        </div>
                    )}

                    <Dialog class="Dialogbox" visible={ViewDialog} showHeader={false} style={{ transition: 'opacity 0.01s ' }}>

                        <div class="card chat" >
                            <div class="card-header" style={{ backgroundColor: "#EBEEF8" }}>
                                <h5 class="card-title d-flex justify-content-between align-items-center" style={{ color: "#1531EB" }}>
                                    <u>Work From Home</u>
                                    <i class="fa fa-times" onClick={ColoseDialog}></i>
                                </h5>
                                <div class="card-content mt-3" >
                                    <div class="row">
                                        <div class="col-md-12">
                                            <i class="fa fa-user" /><span class="contentLable ml-2" style={{ color: "#1531EB" }}> {SelectedWFH?.Name}</span>
                                        </div>
                                        <div class="col-md-12 mt-2">
                                            <i class="fa fa-clock" /><span class="contentLable ml-2">Applied on:<span class="AppliedDate"> {SelectedWFH?.applied_date}</span> </span>
                                        </div>
                                        <div class="col-md-12 mt-2">
                                            <i class="fa fa-calendar" />
                                            <span class="contentLable ml-2">WFH from: <span class="DateDuration">{SelectedWFH?.wfh_from}</span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card-body " >
                                <div class="chat-history">
                                    <ul class="message-list">
                                        <li class="row justify-content-start">
                                            <span>
                                                <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/chat_avatar_01_green.jpg" alt="avatar" style={{ height: "30px" }} />
                                                <span class="message-data-time float-left ml-2">10:10 AM, Today</span>
                                            </span>
                                            <div class="message bg-primary mt-2">
                                                {SelectedWFH.me}
                                            </div>
                                        </li>

                                        <li class="row justify-content-end">
                                            <span class="d-flex justify-content-end">
                                                <span class="message-data-time mr-2">10:10 AM, Today</span>
                                                <img class="direct-chat-img" src="https://bootdey.com/img/Content/user_2.jpg" alt="avatar" style={{ height: "30px" }} />
                                            </span>

                                            <div class="message">
                                                <input type="textarea" class="col" placeholder="Enter text" onChange={approval_Txt} />
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="d-flex justify-content-end">
                                    <button className="btn btn-danger me-2 width-100" value="Reject" onClick={approval_status}>Reject</button>
                                    <button className="btn btn-success width-100" value="Approve" onClick={approval_status}>Approve</button>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </form >
        </div >
    );

}

export default AssignedWorkFromhome;
