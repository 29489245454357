import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import api from '../../../Authenticaltion/api';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const UserTableData = () => {
  const { key } = useParams();
  const [userData, setUserData] = useState([]);
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    api.get(`/getAnalyticsDetailsData/${key}`)
      .then(response => {
        const userArray = response.data;
        if (userArray.length > 0) {
          const generatedColumns = generateColumns(userArray[0]);
          console.log('Generated Columns:', generatedColumns);
          setColumns(generatedColumns);
        }
        console.log('User Data:', userArray);
        setUserData(userArray);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [key]);
  const generateColumns = (dataObject) => {
    return Object.keys(dataObject).map(columnName => {
      const column = {
        dataField: columnName,
        text: columnName,
        sort: true,
      };

      // Add a button for the "Action" column
      if (columnName === 'Action') {
        column.formatter = (cell, row) => (
          <button
            className='btn btn-sm'
            style={{ color: 'white', backgroundColor: '#F97316' }}
            onClick={() => toast.info("This feature is not active right now")}
          >
            Request For WFH
          </button>
        );
      }
      if (columnName === 'company_idLateFiekd') {
        column.hidden = true;
      }
      if (columnName === 'userEmp_Id') {
        column.hidden = true;
      }
      if (columnName === 'StatusOfLate') {
        column.formatter = (cell, row) => {
          const cellValue = row[columnName];
          const approved = cellValue === 'Approved';

          return (
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {approved ? (
                <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginRight: '5px' }} />
              ) : (
                <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red', marginRight: '5px' }} />
              )}
              {/* {approved ? 'Approved' : 'Not Approved'} */}
              {cellValue}
            </span>
          );
        };
      }
      if (columnName === 'Name') {
        column.hidden = true
      }
      return column;
    });
  };
  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="btn-group" role="group">
      {options.map((option) => {
        const isSelect = currSizePerPage === `${option.page}`;
        const buttonClass = isSelect ? 'btn' : 'btn btn-secondary';
        const buttonStyle = isSelect ? { backgroundColor: '#6366F1', color: '#fff' } : {};

        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={buttonClass}
            style={buttonStyle}
          >
            {option.text}
          </button>
        );
      })}
    </div>
  );

  return (

    <div className='row'>
      {userData.length === 0 ? <p style={{ textAlign: 'center', fontSize: '18px', color: '#555' }}>
        No Data Available
      </p> :
        <div className="shadow p-3 mb-5 bg-white rounded">
          <BootstrapTable
            keyField="Date"
            data={userData}
            columns={columns}
            pagination={paginationFactory({
              sizePerPageRenderer
            })}
          />
        </div>

      }

    </div>

  );
};

export default UserTableData;
