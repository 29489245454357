import React, { useEffect, useState } from 'react';
import api from '../../Authenticaltion/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useLoading } from '../../LoadingContext';
import { Calendar } from 'primereact/calendar';
import { Editor } from 'primereact/editor';
import Swal from 'sweetalert2';

const ApplyLeave = () => {
    const { updateLoading } = useLoading();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [leaveType, setLeaveType] = useState("");
    const [subLeaveType, setSubLeaveType] = useState(null);
    const [leaveDuration, setLeaveDuration] = useState('1'); // Default to Full Day
    const [leaveMessage, setLeaveMessage] = useState('');
    const [leaveDocument, setLeaveDocument] = useState(null);
    const [selectedLeaveDetails, setSelectedLeaveDetails] = useState(null);
    const [workingCount, setWorkingCount] = useState(0);
    const [leaveTypeOptions, setLeaveTypeOptions] = useState([]);
    const [selectedSubLeaveDetails, setSelectedSubLeaveDetails] = useState(null); // Initialize LeaveTypeOptions as an empty array

    useEffect(() => {
        // Fetch leave types from the backend
        const fetchLeaveTypes = async () => {
            updateLoading(true);
            try {
                const response = await api.get('/leaveTypeAndBalanceValue'); // Adjust the API endpoint as per your backend
                if (response.status === 200) {
                    console.log("Leave Type is :", response.data);
                    setLeaveTypeOptions(response.data); // Assuming the response contains an array of leave types
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Failed to fetch leave types',
                        timer: 1500,
                        showConfirmButton: false
                    });
                }
            } catch (error) {
                console.error('Error fetching leave types:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'An error occurred while fetching leave types',
                    timer: 1500,
                    showConfirmButton: false
                });
            }
            finally {
                updateLoading(false);
            }
        };

        fetchLeaveTypes();
    }, []);

    useEffect(() => {
        if (fromDate && toDate) {
            fetchWorkingdaysInRange(formatDate(fromDate), formatDate(toDate));
        }
    }, [fromDate, toDate]);

    const fetchWorkingdaysInRange = async (startDate, endDate) => {
        try {
            console.log(startDate, endDate);
            const response = await api.get(`/countWorkingDays?startDate=${startDate}&endDate=${endDate}`);
            if (response.status === 200) {
                console.log("Print work", response.data.workingDays)
                setWorkingCount(response.data.workingDays); // Set the count of working days
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Failed to fetch working days',
                    timer: 1500,
                    showConfirmButton: false
                });
            }
        } catch (error) {
            console.error('Error fetching working days:', error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'An error occurred while fetching working days',
                timer: 1500,
                showConfirmButton: false
            });
        }
    };


    const isApplicableForLeave = async (workingCount, leaveDuration, remaining_balance) => {
        console.log("working days", workingCount, leaveDuration, remaining_balance)
        console.log(typeof (workingCount));
        if (leaveDuration == 1) {
            console.log("working days", workingCount, leaveDuration, remaining_balance)
            return workingCount * 1 <= remaining_balance;
        }
        else {
            return workingCount * 0.5 <= remaining_balance;
        }

    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const cleanMessage = (message) => {
        // Remove HTML tags
        const cleanText = message.replace(/<\/?[^>]+(>|$)/g, "");
        // Trim whitespace
        return cleanText.trim();
    };
    
    const getFinaYearStartDate = (startDate, endDate) => {
        // Assuming the financial year starts on April 1st
        const startDateFY = new Date(startDate);
        const endDateFY = new Date(endDate);

        const startYear = startDateFY.getMonth() >= 3 ? startDateFY.getFullYear() : startDateFY.getFullYear() - 1;
        const endYear = endDateFY.getMonth() >= 3 ? endDateFY.getFullYear() : endDateFY.getFullYear() - 1;
        const currentFinancialYear = new Date().getMonth() >= 3 ? new Date().getFullYear() : new Date().getFullYear() - 1;

        return startYear === endYear && endYear === currentFinancialYear;
    };

    const handleApplyLeave = async () => {
        setIsLoading(true);
        console.log("Selected Leave Details", selectedLeaveDetails)
        try {
            if (leaveType === "") {
                return Swal.fire({
                    icon: "error",
                    text: "Select Valid leave type ",
                    showConfirmButton: false,
                    timer: 1500
                });
            }
            console.log("Printign Messahge",leaveMessage)
            if (fromDate && toDate && leaveType && leaveDuration && leaveMessage.trim()) {
                const cleanedMessage = cleanMessage(leaveMessage);
                if (cleanedMessage === "") {
                    return Swal.fire({
                        icon: "error",
                        text: "Leave message is required",
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
                const isSameFinancialYear = getFinaYearStartDate(fromDate, toDate);
                if (!isSameFinancialYear) {
                    Swal.fire({
                        icon: "warning",
                        text: "Leave date should be in the current financial year",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    return;
                }
                console.log("selected leave days", selectedSubLeaveDetails);
                if (selectedSubLeaveDetails != null) {
                    const minDays = selectedSubLeaveDetails.MinDays != null ? selectedSubLeaveDetails.MinDays : 0;
                    const maxDays = selectedSubLeaveDetails.MaximumDays != null ? selectedSubLeaveDetails.MaximumDays : 0;

                    if (workingCount < minDays || workingCount > maxDays) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: `Leave duration should be Min ${minDays} and Max ${maxDays} working  days for this leave type.`,
                            timer: 1500,
                            showConfirmButton: false
                        });
                        return;
                    }
                }
                if (selectedLeaveDetails.IsCreditType == 1) {
                    const isLeaveApplicable = await isApplicableForLeave(workingCount, leaveDuration, selectedLeaveDetails.remaining_balance);
                    console.log("Is applicable for leave", isLeaveApplicable)
                    if (!isLeaveApplicable) {
                        Swal.fire({
                            icon: "warning",
                            text: "Not enough balance for this type of leave",
                            showConfirmButton: false,
                            timer: 1500
                        });
                        return;
                    }
                }
                const formData = new FormData();
                formData.append('leave_type', leaveType);
                formData.append('leave_SubType', subLeaveType);
                formData.append('leave_duration', leaveDuration);
                formData.append('leave_start_date', formatDate(fromDate));
                formData.append('leave_end_date', formatDate(toDate));
                formData.append('reason', leaveMessage.trim());
                formData.append('Days', workingCount)
                if (leaveDocument) {
                    formData.append('file', leaveDocument);
                }
                console.log("Form Data", formData);
                const response = await api.post('/ApplyLeave', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                if (response.status === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Applied Successfully",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    resetForm();
                    navigate('/Leaves');
                } else {
                    Swal.fire({
                        icon: "error",
                        text: response.error || "Failed to apply leave",
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
            } else {
                Swal.fire({
                    icon: "error",
                    text: "Fill Up all Required Filled",
                    showConfirmButton: false,
                    timer: 1500
                });
            }
        } catch (error) {
            console.error('Error applying for leave:', error);
            Swal.fire({
                icon: "error",
                text: "An error occurred while applying for leave",
                showConfirmButton: false,
                timer: 1500
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleFileChange = (file) => {
        const selectedFile = file.target.files[0];
        if (selectedFile && selectedFile.size > 5 * 1024 * 1024) {
            // File size exceeds 5MB, show alert
            Swal.fire({
                icon: 'error',
                text: 'File size must be under 5 MB',
                confirmButtonColor: '#6366F1',
                showConfirmButton: false,
                timer: 1500
            });
            setLeaveDocument(null); // Clear the selected file
            return;
        }
        setLeaveDocument(selectedFile);
        // You can also show a success message if needed
    };


    const resetForm = () => {
        setFromDate('');
        setToDate('');
        setLeaveType('');
        setSubLeaveType(null);
        setLeaveDuration('1');
        setLeaveMessage('');
        setLeaveDocument(null);
    };

    const customHeader = (
        <span className="ql-formats">
            <button className="ql-bold"></button>
            <button className="ql-italic"></button>
            <button className="ql-underline"></button>
            <button className="ql-list" value="ordered"></button>
            <button className="ql-list" value="bullet"></button>
        </span>
    );

    return (
        <div class=" shadow p-3 bg-white rounded">
            <div class="h5 pb-2 mt-2 mb-2 border-bottom text-primary">
                Apply Leave
            </div>
            <form className="row mt-4">
                <div className="col-sm-6">
                    <div className="mb-3">
                        <label htmlFor="leaveType" className="form-label">
                            Select Leave Type <span className="text-danger fw-bold">*</span>
                        </label>
                        <select
                            className="form-select"
                            id="leaveType"
                            value={leaveType}
                            onChange={(e) => {
                                const selectedValue = e.target.value;
                                setLeaveType(selectedValue);
                                const selectedDetails = leaveTypeOptions.find((option) => option.leave_id === parseInt(selectedValue));
                                setSelectedLeaveDetails(selectedDetails);
                                setSubLeaveType('');
                            }}
                            name="leaveType"
                            required
                        >
                            <option value="">Select Leave Type</option>
                            {leaveTypeOptions.map((leaveOption) => (
                                <option key={leaveOption.leave_id} value={leaveOption.leave_id}>
                                    {leaveOption.leave_type}
                                </option>
                            ))}
                        </select>
                        {leaveType !== "" && (
                            <small style={{
                                color: selectedLeaveDetails?.remaining_balance > 0 ? '#6366F1' : 'red',
                                fontSize: 'large',
                                transition: 'opacity 0.5s ease-in-out',
                            }}>
                                {selectedLeaveDetails?.IsCreditType == 1 && (
                                    <p>You have {selectedLeaveDetails.remaining_balance} leaves left !!!</p>
                                )}
                                {selectedLeaveDetails?.IsCreditType == 0 && (
                                    <p>You have selected non credit type leaves  !!!</p>
                                )}
                            </small>
                        )}
                    </div>

                    {leaveType !== "" && selectedLeaveDetails?.subtypeOptions.length > 0 && (
                        <div className="mb-3">
                            <label htmlFor="subLeaveType" className="form-label">
                                Select Leave Sub-Type
                            </label>
                            <select
                                className="form-select"
                                id="subLeaveType"
                                value={subLeaveType}
                                onChange={(e) => {
                                    const selectedValue = e.target.value;
                                    setSubLeaveType(selectedValue);
                                    const selectedSubDetails = selectedLeaveDetails?.subtypeOptions.find((subType) => subType.LeaveSubTypeID === parseInt(selectedValue));
                                    setSelectedSubLeaveDetails(selectedSubDetails);
                                    if (selectedSubDetails && selectedSubDetails.RequiredDaysToApply) {
                                        const requiredDays = parseInt(selectedSubDetails.RequiredDaysToApply);
                                        const fromDateAdjusted = new Date();
                                        fromDateAdjusted.setDate(fromDateAdjusted.getDate() + requiredDays);
                                        setFromDate(fromDateAdjusted);
                                        setToDate('')
                                    }
                                    else {
                                        setFromDate(null); // Reset the fromDate if there's no RequiredDaysToApply
                                        setToDate(''); // Also reset toDate
                                    }
                                }}
                                name="subLeaveType"
                                required
                            >
                                <option value="">Select Leave Sub-Type</option>
                                {selectedLeaveDetails?.subtypeOptions.map((subType) => (
                                    <option key={subType.LeaveSubTypeID} value={subType.LeaveSubTypeID}>
                                        {subType.SubTypeName}
                                    </option>
                                ))}
                            </select>
                            {selectedSubLeaveDetails && (
                                <div>
                                    {selectedSubLeaveDetails.RequiredDaysToApply && (
                                        <p>Required Days to Apply: {selectedSubLeaveDetails.RequiredDaysToApply}</p>
                                    )}
                                </div>
                            )}


                        </div>
                    )}

                    <div className="mb-3 row">
                        <div className="col-md-6">
                            <label htmlFor="fromDate" className="form-label">
                                From Date <span className="text-danger fw-bold">*</span>
                            </label>
                            <Calendar
                                value={fromDate ? new Date(fromDate) : null}
                                showIcon
                                onChange={(e) => setFromDate(e.value)}
                                style={{ height: '35px', width: '100%' }}
                                dateFormat="dd/mm/yy"
                                maxDate={toDate ? new Date(toDate) : null}
                                minDate={fromDate ? new Date(fromDate) : null} // Use the fromDate directly as the minDate
                            />

                        </div>
                        <div className="col-md-6">
                            <label htmlFor="toDate" className="form-label">
                                To Date <span className="text-danger fw-bold">*</span>
                            </label>
                            <Calendar
                                value={toDate ? new Date(toDate) : null}
                                showIcon
                                onChange={(e) => setToDate(e.target.value)}
                                style={{ height: '35px', width: '100%' }}
                                minDate={fromDate}
                                dateFormat="dd/mm/yy"
                            />
                        </div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="leaveDuration" className="form-label">
                            Leave Duration <span className="text-danger fw-bold">*</span>
                        </label>
                        <select
                            className="form-select"
                            id="leaveDuration"
                            value={leaveDuration}
                            onChange={(e) => setLeaveDuration(e.target.value)}
                            required
                        >
                            <option value="1">Full Day</option>
                            <option value="0.5">First Half</option>
                            <option value="0.6">Second Half</option>
                        </select>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="leaveDocument" className="form-label">
                            Upload Document
                        </label>
                        <input
                            type="file"
                            accept=".pdf, image/*"
                            className="form-control"
                            onChange={handleFileChange}
                        />
                        <small className="form-text" style={{ color: 'red' }}>
                            Accepted file types: PDF ,JPEG, PNG Maximum size: 5MB.
                        </small>
                    </div>
                </div>

                <div className="col-sm-6">
                    <label htmlFor="leaveMessage" className="form-label">
                        Leave Reason <span className="text-danger fw-bold">*</span>
                    </label>
                    <Editor
                        id="leaveMessage"
                        value={leaveMessage}
                        onTextChange={(e) => setLeaveMessage(e.htmlValue)}
                        headerTemplate={customHeader}
                        style={{ height: '250px', }}
                        required
                    />
                </div>

                <div className="mb-3 text-center">
                    <button
                        type="button"
                        className="btn"
                        onClick={handleApplyLeave}
                        style={{ backgroundColor: '#6366F1', color: 'white', borderRadius: '20px' }}
                    >
                        Apply Leave
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ApplyLeave;
