import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
// import './forgot.css';
// import Constant from '../../Constant.js';
import api from "../../Authenticaltion/api.js";
import { toast, ToastContainer } from "react-toastify";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [otp, setOTP] = useState("");
  const [loading, setLoading] = useState(false);
  const [resendEnabled, setResendEnabled] = useState(true);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [step, setStep] = useState(0); // 0: Email Input, 1: OTP Input, 2: Password Input

  const isEmailValid = (email) => {
    // Regular expression to validate email format
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailPattern.test(email);
  };

  const startCountdown = () => {
    setTimeRemaining(60);
    setResendEnabled(false);
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();

    if (!isEmailValid(email)) {
      return;
    }

    setLoading(true);

    try {
      const response = await api.post(`/EmailCheck`, { email });
      if (response.data.exists) {
        toast.success("OTP Sent Successfully");
        startCountdown();
        setStep(1); // Move to the OTP input step
      } else {
        toast.error("Email Not Registered With Us");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleResendOTP = async (e) => {
    e.preventDefault();
    if (resendEnabled) {
      startCountdown();
      const result = await api.put(`/Optresend`, { email });
      if (result.data.Messaage) {
        toast.success("OTP Resent Successfully");
      } else {
        toast.error("Some Error Occurred, Try again Later");
      }
    }
  };

  const handleOTPSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const response = await api.post(`/VerifyOTP`, { email, otp });
      if (response.data.verified) {
        toast.success("OTP Verified Successfully");
        setStep(2); // Move to the password input step
      } else {
        toast.error("Invalid OTP. Please enter the correct OTP.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      if (password !== confirmPassword) {
        toast.error("Passwords do not match. Please try again.");
        return;
      }

      const response = await api.put(`/SetNewPassword`, { email, password });
      if (response.data.success) {
        toast.success("New Password Set Successfully");
        navigate("/"); // Redirect to login page
      } else {
        toast.error("Failed to set the new password. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let timer;
    if (timeRemaining > 0 && !resendEnabled) {
      timer = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      setResendEnabled(true);
    }

    return () => {
      clearInterval(timer);
    };
  }, [timeRemaining, resendEnabled]);

  return (
    <div className="container">
      <div className="col-md-6 d-flex justify-content-center align-items-center">
        <img src="./images/forgot.jpg" alt="" style={{ width: '750px', height: '500px' }} />

      </div>

      <div className="col-md-6 d-flex justify-content-center align-items-center">
        <ToastContainer />
        <div className="Forgot-wrap">
          <div className="Forgot-content">
            <div className="Forgot-logo">
              <a href="#">
                <img width="300" src="images/LogoSpc.png" alt="CoolAdmin" />
              </a>
              <h5 className="mb-2" >Forgot Password 👨‍💻</h5>
              <p className="mb-4 enter-message" style={{ fontSize: '12px' }}>Enter your email and we will send otp to your registered email.</p>
            </div>
            <div className="Forgot-form">
              {step === 0 && (
                <form onSubmit={handleEmailSubmit}>
                  {/* Email Input */}
                  <div className="form-group">
                    <label>Email Address</label>
                    <input
                      className="au-input au-input--full"
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <button
                    className="au-btn au-btn--block  m-b-20"
                    type="submit"
                    style={{ height: "40px", backgroundColor: "#6366F1" }}
                  >
                    {loading ? "Sending OTP..." : "Submit"}
                  </button>
                </form>
              )}
              {step === 1 && (
                <form onSubmit={handleOTPSubmit}>
                  {/* OTP Input */}
                  <div className="form-group">
                    <label>Enter OTP</label>
                    <input
                      className="au-input au-input--full"
                      type="text"
                      name="otp"
                      placeholder="OTP"
                      value={otp}
                      onChange={(e) => setOTP(e.target.value)}
                    />
                  </div>
                  <button
                    className="au-btn au-btn--block  m-b-20"
                    type="submit"
                    style={{ height: "40px", backgroundColor: "#6366F1" }}
                  >
                    {loading ? "Verifying OTP..." : "Verify OTP"}
                  </button>
                  <button
                    className="btn text-danger btn-link"
                    onClick={handleResendOTP}
                    disabled={!resendEnabled}
                  >
                    {resendEnabled
                      ? "Resend OTP"
                      : `Resend OTP in ${timeRemaining} seconds`}
                  </button>
                </form>
              )}
              {step === 2 && (
                <form onSubmit={handlePasswordReset}>
                  {/* Password Input */}
                  <div className="form-group">
                    <label>New Password</label>
                    <input
                      className="au-input au-input--full"
                      type="password"
                      name="password"
                      placeholder="New Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Confirm Password</label>
                    <input
                      className="au-input au-input--full"
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                  <button
                    className="au-btn au-btn--block  m-b-20"
                    type="submit"
                    style={{ height: "40px", backgroundColor: "#6366F1" }}
                  >
                    {loading ? "Setting Password..." : "Set New Password"}
                  </button>
                </form>
              )}
              <div className="text-center">
                {/* <Link to="/" style={{ textDecoration: "none", color: "#6366F1" }}>
                Back to Login
              </Link> */}

                <Link to='/Login' style={{ textDecoration: 'none', color: '#6366F1' }}>Back to Login</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
