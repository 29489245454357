// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import './EditEmployee.css'
// import { MultiSelect } from 'primereact/multiselect';
// import api from '../../../Authenticaltion/api';
// import { toast } from 'react-toastify';
// const EditEmployee = () => {
//   const { id } = useParams();
//   const [selectedDepartmentId, setSelectedDepartmentId] = useState('');
//   const [selectedDesignationId, setSelectedDesignationId] = useState('');
//   const [departments, setDepartments] = useState([]);
//   const [designations, setDesignations] = useState([]);
//   const [isBasicInfoEditing, setIsBasicInfoEditing] = useState(false);
//   const [isEmployeeInfoEditing, setIsEmployeeInfoEditing] = useState(false);
//   const [authorities, setAuthorities] = useState([]);
//   const [BasicInfo, setBasicInfo] = useState({})
//   const [EmployeeInfo, setEmployeeInfo] = useState({})
//   const [originalBasicInfo, setOriginalBasicInfo] = useState({});
//   const [originalEmployeeInfo, setOriginalEmployeeInfo] = useState({});
//   const [selectedAuthorities, setSelectedAuthorities] = useState(null);

//   const [isLeavesEditing, setIsLeavesEditing] = useState(false);
//   const [editedLeaveInfo, setEditedLeaveInfo] = useState([]);
//   const [userTotalLeave, setUserTotalLeave] = useState('0');
//   const [leaveDataAvailable, setLeaveDataAvailable] = useState(true);

//   const [leaveInfo, setLeaveInfo] = useState([]);

//   const handleBasicInfoSave = async () => {
//     try {
//       const updatedBasicInfo = {};
//       if (originalBasicInfo.name !== BasicInfo.name) {
//         updatedBasicInfo.name = BasicInfo.name;
//       }
//       if (BasicInfo.email !== originalBasicInfo.email) {
//         if ((BasicInfo.email == '' || null) && (originalBasicInfo.email != null)) {
//           toast.info('Email can not be empty.');
//           return;
//         }
//         updatedBasicInfo.email = BasicInfo.email;
//       }
//       if (BasicInfo.phoneno !== originalBasicInfo.phoneno) {
//         updatedBasicInfo.phoneno = BasicInfo.phoneno;
//       }
//       if (BasicInfo.Address !== originalBasicInfo.Address) {
//         updatedBasicInfo.Address = BasicInfo.Address;
//       }
//       if (Object.keys(updatedBasicInfo).length === 0) {
//         toast.info('No changes made.');
//         return;
//       }

//       const response = await api.put(`/updateBasicInfo/${id}`, updatedBasicInfo); // Replace with your actual API endpoint
//       if (response.status === 200) {
//         toast.success('Basic information updated successfully');
//         setIsBasicInfoEditing(false);
//         fetchEmployeeData()
//       } else {
//         toast.error('Failed to update basic information');
//       }
//     } catch (error) {
//       toast.error('Error updating basic information:', error);
//     }
//   };

//   const handleEmployeeInfoSave = async () => {

//     try {
//       const updatedEmployeeInfo = {};
//       const originalJoiningDate = new Date(originalEmployeeInfo.Joining_Date.split('-').reverse().join('-'));
//       console.log("getting original date in formated is", originalEmployeeInfo.Joining_Date)
//       console.log("getting date object is", originalJoiningDate)
//       const updatedJoiningDate = new Date(EmployeeInfo.Joining_Date.split('-').reverse().join('-'));
//       console.log("getting  update  date format is", EmployeeInfo.Joining_Date);
//       console.log("geeting updated date as date object is", updatedJoiningDate)

//       if (originalJoiningDate.getTime() !== updatedJoiningDate.getTime()) {
//         updatedEmployeeInfo.Joining_Date = updatedJoiningDate.toISOString();
//         console.log("prinitng date to send api", updatedEmployeeInfo.Joining_Date)
//       }
//       if (selectedDepartmentId !== "" && originalEmployeeInfo.department_id !== selectedDepartmentId) {
//         updatedEmployeeInfo.department_id = selectedDepartmentId;
//       }
//       if (selectedDesignationId !== "" && originalEmployeeInfo.designation_id !== selectedDesignationId) {
//         updatedEmployeeInfo.designation_id = selectedDesignationId;
//       }
//       if (originalEmployeeInfo.role !== EmployeeInfo.role) {
//         updatedEmployeeInfo.role = EmployeeInfo.role
//       }
//       console.log("Data send for selected authority is to be ", selectedAuthorities)
//       if (!arraysEqual(selectedAuthorities, originalEmployeeInfo.reporting_authority)) {
//         updatedEmployeeInfo.reporting_authority = selectedAuthorities;
//       }

//       const response = await api.put(`/updateEmployeeInfo/${id}`, updatedEmployeeInfo);
//       if (response.status === 200) {
//         toast.success('Employee information updated successfully');
//         setIsEmployeeInfoEditing(false)
//         fetchEmployeeData()
//       }
//       else {
//         toast.error('Failed to update basic information');
//       }
//     }
//     catch (error) {
//       console.log(error)
//       toast.error('Error updating employee information: ' + error.message);
//     }

//   };
//   const calculateTotalLeave = (leaveInfo) => {
//     // Use the reduce method to sum used_balance and remaining_balance for each element
//     const totalBalance = leaveInfo.reduce((total, leave) => {
//       return total + parseInt(leave.used_balance) + parseInt(leave.remaining_balance);
//     }, 0); // Initialize total to 0

//     setUserTotalLeave(totalBalance);
//   };
//   const handleLeaveTypeChange = (index, newValue) => {
//     // Update the editedLeaveInfo for the specific leave type
//     setEditedLeaveInfo((prevLeaveInfo) =>
//       prevLeaveInfo.map((leave, i) =>
//         i === index ? { ...leave, remaining_balance: newValue } : leave
//       )
//     );
//   }

//   const handleLeaveInfoSave = async (index) => {
//     try {
//       const updatedLeave = editedLeaveInfo[index];
//       const originalLeave = leaveInfo[index];

//       if (updatedLeave.remaining_balance !== originalLeave.remaining_balance) {
//         if (updatedLeave.remaining_balance > originalLeave.remaining_balance && updatedLeave.remaining_balance >= 0) {

//         }
//         else if (updatedLeave.remaining_balance < originalLeave.remaining_balance && updatedLeave.remaining_balance >= 0) {

//         }
//         else {
//           return toast.warning('leave balanced can not be negative')

//         }
//         const updatedLeaves = [{ balance_id: updatedLeave.balance_id, initial_balance: updatedLeave.initial_balance }];

//         const response = await api.put(`/updateLeaveInfo/${id}`, updatedLeaves); // Replace with your actual API endpoint

//         if (response.status === 200) {
//           toast.success('Leave information updated successfully');
//           setIsLeavesEditing(false);
//           fetchUserLeave();
//         } else {
//           toast.error('Failed to update leave information');
//         }
//       } else {
//         toast.info('No changes made.');
//       }
//     } catch (error) {
//       console.error('Error updating leave information:', error);
//     }
//   };

//   const fetchEmployeeData = async () => {
//     try {
//       const response = await api.get(`/EmployeeControl/${id}`);
//       const { basicInfo, employeeInfo } = response.data;
//       setBasicInfo(basicInfo);
//       setOriginalBasicInfo(basicInfo);
//       setEmployeeInfo(employeeInfo);
//       setOriginalEmployeeInfo(employeeInfo);
//       console.log("prining employee departmenet is")
//     }
//     catch (error) {
//       toast.error('Error fetching employee data:', error);
//     }
//   }
//   const fetchAuthorities = async () => {
//     try {
//       const response = await api.get('/getAvailableReportingAuthorities');
//       console.log('Fetched Authorities:', response.data);
//       const filteredAuthorities = response.data.filter(
//         authorities => authorities.id !== originalEmployeeInfo.id
//       );

//       // Set authorities only if there are new ones
//       if (filteredAuthorities.length > 0) {
//         console.log("filtered authority is", filteredAuthorities)
//         setAuthorities((prevAuthorities) => [...prevAuthorities, ...filteredAuthorities]);
//       }
//       // Set selectedAuthorities based on reporting_authority
//       const formattedAuthorities = (EmployeeInfo.reporting_authority || []).map(authority => (
//         authority.id
//       ));
//       setSelectedAuthorities(formattedAuthorities);

//     } catch (error) {
//       console.error('Error fetching reporting authorities:', error);
//     }
//   };

//   const fetchDepartments = async () => {
//     try {
//       const response = await api.get('/getDepartments'); // Replace with your API endpoint
//       setDepartments(response.data);
//     } catch (error) {
//       console.error('Error fetching departments:', error);
//     }
//   };
//   const fetchDesignations = async (departmentId) => {
//     try {
//       const response = await api.get(`/getDesignationsForDepartment/${departmentId}`);
//       setDesignations(response.data);
//     } catch (error) {
//       console.error('Error fetching designations:', error);
//     }
//   };
//   const fetchUserLeave = async () => {
//     try {
//       const response = await api.get(`/Employee-leave-balances/${id}`);

//       const leaveData = response.data;
//       if (leaveData.length === 0) {
//         setLeaveDataAvailable(false);
//       } else {
//         setLeaveDataAvailable(true);
//         setLeaveInfo(leaveData);
//         calculateTotalLeave(leaveData);
//         setEditedLeaveInfo(leaveData);
//       }
//     }
//     catch (error) {
//       console.log(error)
//       toast.error('Error Fetching Leaves', error)
//     }
//   }
//   useEffect(() => {
//     if (id) {
//       fetchEmployeeData();
//     }

//   }, [id]);

//   useEffect(() => {

//     if (id) {
//       fetchUserLeave();
//     }

//   }, [id]);  // Make sure `id` is the correct dependency

//   useEffect(() => {
//     if (isEmployeeInfoEditing) {
//       console.log("Rendering with reporting authority:", EmployeeInfo.reporting_authority);

//       fetchAuthorities();
//       fetchDepartments();
//     }
//   }, [isEmployeeInfoEditing, EmployeeInfo.reporting_authority]);

//   function arraysEqual(arr1, arr2) {
//     if (arr1.length !== arr2.length) return false;
//     for (let i = 0; i < arr1.length; i++) {
//       if (arr1[i].id !== arr2[i].id || arr1[i].name !== arr2[i].name) return false;
//     }
//     return true;
//   }
//   return (
//     <>
//       <div className="row">
//         <div className="col-md-2 text-center">
//           <img
//             src="/images/icon/Noprofile.webp"
//             alt="hbhddyyg"
//             className="rounded-circle img-fluid"
//             style={{ maxWidth: '120px', border: '2px solid rgb(99, 102, 241)' }}
//           />
//         </div>
//         <div className="col-md-10 text-md-start text-center mt-3 mt-md-0">
//           <h3 style={{ color: 'rgb(178, 0, 255)', marginTop: '10px' }}>{BasicInfo.name}</h3>
//           <p style={{ color: 'rgb(99, 102, 241)', fontWeight: '500' }}>{EmployeeInfo.department && EmployeeInfo.department.department_name}</p>
//         </div>
//       </div>
//       <div className="row mt-3">
//         <div className="col-md-12">
//           <ul className="nav nav-tabs" id="myTab" role="tablist">
//             <li className="nav-item" role="presentation">
//               <a className="nav-link active" id="about-tab" data-bs-toggle="tab" href="#about" role="tab" aria-controls="about" aria-selected="true">
//                 About
//               </a>
//             </li>
//             <li className="nav-item" role="presentation">
//               <a className="nav-link" id="leaves-tab" data-bs-toggle="tab" href="#leaves" role="tab" aria-controls="leaves" aria-selected="false">
//                 Leaves
//               </a>
//             </li>
//           </ul>

//           <div className="tab-content" id="myTabContent">
//             <div className="tab-pane fade show active" id="about" role="tabpanel" aria-labelledby="about-tab">
//               <div className="tabContentContainerStyle">
//                 <div className="row">
//                   <div className="row">
//                     <div className="d-flex justify-content-between align-items-center">
//                       <h5 style={{ color: 'rgb(99, 102, 241)' }}>Basic Information</h5>
//                       <i className="fa fa-edit fw-bold" style={{ cursor: 'pointer', color: 'rgb(99, 102, 241)' }} onClick={() => setIsBasicInfoEditing(!isBasicInfoEditing)}></i>
//                     </div>
//                   </div>

//                   {/* here show the detatils */}
//                   <div className="row">
//                     {isBasicInfoEditing ? (
//                       <div className="row">
//                         <div className="col-12 col-md-6 col-lg-3">
//                           <label htmlFor="name">Name</label>
//                           <input type="text" className='custom-input' value={BasicInfo.name}
//                             onChange={(e) => setBasicInfo({ ...BasicInfo, name: e.target.value })} />
//                         </div>
//                         <div className="col-12 col-md-6 col-lg-3">
//                           <label htmlFor="name">Phone</label>
//                           <input type="tel" minLength={10} maxLength={10} className='custom-input' value={BasicInfo.phoneno}
//                             onChange={(e) => setBasicInfo({ ...BasicInfo, phoneno: e.target.value })} />
//                         </div>
//                         <div className="col-12 col-md-6 col-lg-3">
//                           <label htmlFor="email">Email</label>
//                           <input type="text" className='custom-input' value={BasicInfo.email}
//                             onChange={(e) => setBasicInfo({ ...BasicInfo, email: e.target.value })} />
//                         </div>
//                         <div className="col-12 col-md-6 col-lg-3">
//                           <label htmlFor="address">Addres</label>
//                           <input type="text" className='custom-input' value={BasicInfo.Address}
//                             onChange={(e) => setBasicInfo({ ...BasicInfo, Address: e.target.value })} />
//                         </div>
//                         <div className="d-flex justify-content-between mt-2">
//                           <div className="d-flex justify-content-between">
//                             <button
//                               type="submit"
//                               className="btn"
//                               style={{ backgroundColor: '#6366F1', color: 'white', fontSize: '14px' }}
//                               onClick={handleBasicInfoSave}
//                             >
//                               Save Changes
//                             </button>
//                             <button
//                               type="button"
//                               className="btn btn-danger"
//                               onClick={() => setIsBasicInfoEditing(false)}
//                               style={{ marginLeft: '5px', fontSize: '14px' }}
//                             >
//                               Cancel
//                             </button>
//                           </div>
//                         </div>
//                       </div>
//                     ) : <div className='row'>
//                       <div className="col-12 col-md-6 col-lg-3">
//                         <label >Name</label>
//                         <p className='fw-bold'>{BasicInfo.name}</p>
//                       </div>
//                       <div className="col-12 col-md-6 col-lg-3">
//                         <label >Phone</label>
//                         <p className='fw-bold'>{BasicInfo.phoneno}</p>
//                       </div>
//                       <div className="col-12 col-md-6 col-lg-3">
//                         <label >Email</label>
//                         <p className='fw-bold text-truncate ' data-toggle="tooltip" data-placement="top" title={BasicInfo.email}>{BasicInfo.email}</p>
//                       </div>
//                       <div className="col-12 col-md-6 col-lg-3">
//                         <label >Addres</label>
//                         <p className='fw-bold'>{BasicInfo.Address}</p>
//                       </div>
//                     </div>}
//                   </div>
//                 </div>
//                 <hr></hr>
//                 <div className="row">
//                   <div className="row">
//                     <div className="d-flex justify-content-between align-items-center">
//                       <h5 style={{ color: 'rgb(99, 102, 241)' }}>Employee Information</h5>
//                       <i className="fa fa-edit fw-bold" style={{ cursor: 'pointer', color: 'rgb(99, 102, 241)' }} onClick={() => setIsEmployeeInfoEditing(!isEmployeeInfoEditing)}></i>
//                     </div>
//                   </div>
//                   <div className="row">
//                     {isEmployeeInfoEditing ? (
//                       <div className="row">
//                         <div className="col-12 col-md-6 col-lg-3">
//                           <label htmlFor="id">Employee Id</label>
//                           <input type="text" className='custom-input' value={EmployeeInfo.employee_id} disabled style={{ textAlign: 'center' }} />
//                         </div>
//                         <div className="col-12 col-md-6 col-lg-3">
//                           <label htmlFor="id">Joining Date</label>
//                           <input
//                             type="date"
//                             className='custom-input'
//                             value={EmployeeInfo.Joining_Date.split('-').reverse().join('-')}
//                             onChange={(e) => setEmployeeInfo({ ...EmployeeInfo, Joining_Date: e.target.value.split('-').reverse().join('-') })}
//                           />

//                         </div>
//                         <div className="col-12 col-md-6 col-lg-3">
//                           <label htmlFor="id">Department</label>
//                           {/* <input type="text" className='custom-input' /> */}
//                           <select
//                             className="custom-input"
//                             value={selectedDepartmentId}
//                             onChange={(e) => {
//                               const departmentId = e.target.value;
//                               setSelectedDepartmentId(departmentId);
//                               setSelectedDesignationId(''); // Clear the selected designation when the department changes
//                               fetchDesignations(departmentId);
//                             }}
//                           >
//                             <option value="">Select Department</option>
//                             {departments.map((department) => (
//                               <option key={department.department_id} value={department.department_id}>
//                                 {department.department_name}
//                               </option>
//                             ))}
//                           </select>
//                         </div>
//                         <div className="col-12 col-md-6 col-lg-3">
//                           <label htmlFor="id">Designation</label>
//                           <select
//                             className="custom-input"
//                             value={selectedDesignationId}
//                             onChange={(e) => setSelectedDesignationId(e.target.value)}
//                           >
//                             <option value="">Select Designation</option>
//                             {designations.map((designation) => (
//                               <option key={designation.designation_id} value={designation.designation_id}>
//                                 {designation.designation_name}
//                               </option>
//                             ))}
//                           </select>
//                         </div>
//                         <div className="col-12 col-md-6 col-lg-3 mt-2">
//                           <label htmlFor="id">Role</label>
//                           <select className="custom-input" name="role" value={EmployeeInfo.role} onChange={(e) => setEmployeeInfo({ ...EmployeeInfo, role: e.target.value })} style={{ height: '40px' }}>
//                             <option value="USER">User</option>
//                             <option value="MANAGER">Manager</option>
//                             <option value="ADMIN">Admin</option>
//                             <option value="SUPERADMIN">Super Admin</option>
//                           </select>
//                         </div>
//                         <div className="col-12 col-md-6 col-lg-3 mt-2">
//                           <label htmlFor="id">Reporting authority</label>
//                           <MultiSelect
//                             value={selectedAuthorities}
//                             onChange={(e) => {
//                               console.log("Selected Authorities:", e.value);
//                               setSelectedAuthorities(e.value);
//                             }}
//                             options={authorities}
//                             optionLabel="name"
//                             className='custom-input'
//                             optionValue="id"
//                             filter
//                             style={{ width: '100%', height: '40px', borderRadius: 'unset' }}
//                             placeholder="Select Authority"
//                           />
//                         </div>

//                         <div className="d-flex justify-content-between mt-2">
//                           <div className="d-flex justify-content-between">
//                             <button
//                               type="submit"
//                               className="btn"
//                               style={{ backgroundColor: '#6366F1', color: 'white', fontSize: '14px' }}
//                               onClick={handleEmployeeInfoSave}
//                             >
//                               Save Changes
//                             </button>
//                             <button
//                               type="button"
//                               className="btn btn-danger"
//                               onClick={() => setIsEmployeeInfoEditing(false)}
//                               style={{ marginLeft: '5px', fontSize: '14px' }}
//                             >
//                               Cancel
//                             </button>
//                           </div>
//                         </div>
//                       </div>
//                     ) : <div className='row'>
//                       <div className="col-12 col-md-6 col-lg-3">
//                         <label >Employee Id</label>
//                         <p className='fw-bold'>{EmployeeInfo.employee_id}</p>
//                       </div>
//                       <div className="col-12 col-md-6 col-lg-3">
//                         <label >Joining Date</label>
//                         <p className='fw-bold'>{EmployeeInfo.Joining_Date}</p>
//                       </div>
//                       <div className="col-12 col-md-6 col-lg-3">
//                         <label >Department</label>
//                         <p className='fw-bold'>{EmployeeInfo.department && EmployeeInfo.department.department_name}</p>
//                       </div>
//                       <div className="col-12 col-md-6 col-lg-3">
//                         <label >Designation</label>
//                         <p className='fw-bold'>{EmployeeInfo.designation && EmployeeInfo.designation.designation_name}</p>
//                       </div>
//                       <div className="col-12 col-md-6 col-lg-3">
//                         <label >Role</label>
//                         <p className='fw-bold'>{EmployeeInfo.role}</p>
//                       </div>
//                       <div className="col-12 col-md-6 col-lg-3 reporting-authority-section">
//                         <label>Reporting Authority</label>
//                         {EmployeeInfo.reporting_authority && EmployeeInfo.reporting_authority.length > 0 ? (
//                           <ul className="list-unstyled reporting-authority-list">
//                             {EmployeeInfo.reporting_authority.map(authority => (
//                               <li key={authority.id} className="fw-bold reporting-authority-item">
//                                 {authority.name}
//                               </li>
//                             ))}
//                           </ul>
//                         ) : (
//                           <p className="fw-bold no-authority-message">No Reporting Authority</p>
//                         )}
//                       </div>
//                     </div>}
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="tab-pane fade" id="leaves" role="tabpanel" aria-labelledby="leaves-tab">
//               <div className="tabContentContainerStyle">

//                 <table class="table">
//                   <thead>
//                     <tr>
//                       <th class="text-center">Year</th>
//                       <th class="text-center">Leave type</th>
//                       <th class="text-center">Available</th>
//                       <th class="text-center">Used</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {leaveInfo.map((leave) => (
//                       <tr>
//                         <td class="text-center">{leave.fy_year}</td>
//                         <td class="text-center">{leave.leave_name}</td>
//                         <td class="text-center">{leave.initial_balance}</td>
//                         <td class="text-center">{leave.used_balance}</td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>

//                 <div className="row">
//                   <div className="leave-balance-message">
//                     <p className="message-title">Important Notice</p>
//                     <p className="message-content">
//                       Total leave balance is automatically calculated based on individual leave types balance.
//                       <br />
//                       <span style={{ color: 'red' }}>
//                         Note: Changing leave balances should adhere to company rules and policies.
//                         For any changes to company rules, please contact the company's administration.
//                       </span>
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default EditEmployee;


import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useParams } from 'react-router-dom';
import './EditEmployee.css';
import { toast } from 'react-toastify';
import api from '../../../Authenticaltion/api';
import { useLoading } from '../../../LoadingContext';
import { MultiSelect } from 'primereact/multiselect';
import Swal from 'sweetalert2';
const EditEmployee = () => {
  const { updateLoading } = useLoading();
  const { id } = useParams();
  const initialBasicInfo = {
    Name: "",
    email: "",
    phone: "",
    gender: "",
    role: "",
    status: ""
  };
  const initialProfessionalInfo = {
    department: "",
    designation: "",
    Joining_Date: "",
    attendance_start_Date: "",
    reporting_authority: [],
    uan_number: "",
    epfo_number: "",
    pan_number: " ",
  };

  const initialAddress = {
    street: "",
    city: "",
    district: "",
    state: "",
    country: "",
    zip: ""
  };

  const initialLeaveSubtype = {
    leave_type: "",
    sub_type_name: "",
    RequiredDaytoApplied: "",
    MinDays: "",
    MaximumDays: "",
    applicable_for_all: false
  };
  const initialBankDetails = {
    bank_name: "",
    account_number: "",
    ifsc_code: ""
  };

  const [basicInfo, setBasicInfo] = useState(initialBasicInfo);
  const [professionalInfo, setProfessionalInfo] = useState(initialProfessionalInfo);
  const [permanentAddress, setPermanentAddress] = useState(initialAddress);
  const [presentAddress, setPresentAddress] = useState(initialAddress);
  const [bankDetails, setBankDetails] = useState(initialBankDetails);
  const [leaveSubtype, setLeaveSubtype] = useState([initialLeaveSubtype]);
  const [userId, setUserId] = useState(null);

  const [editableSections, setEditableSections] = useState({
    basicInfo: false,
    professionalInfo: false,
    permanentAddress: false,
    presentAddress: false,
    accountDetails: false,
    leaveSubtype: false,
  });


  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState('');
  const [selectedDesignationId, setSelectedDesignationId] = useState('');
  const [authorities, setAuthorities] = useState([]);
  const [selectedAuthorities, setSelectedAuthorities] = useState([]);
  const [SelectedPhoto, setSelectedPhoto] = useState(null);
  const fetchAuthorities = async () => {
    try {
      const response = await api.get('/getAvailableReportingAuthorities',{params: {
        Emp_id: id
      }});
      console.log("User id is",userId)
      const filteredAuthorities =await  response.data.filter(authority => authority.id !== userId);
      setAuthorities(filteredAuthorities);
    } catch (error) {
      console.error('Error fetching reporting authorities:', error);
    }
  };

  const [initialData, setInitialData] = useState({
    basicInfo: initialBasicInfo,
    professionalInfo: initialProfessionalInfo,
    permanentAddress: initialAddress,
    presentAddress: initialAddress,
    bankDetails: initialBankDetails
  });


  useEffect(() => {
    fetchData();

  }, []);

  const fetchData = async () => {
    try {
      updateLoading(true);
      const response = await api.get(`/EmployeeControl/${id}`);
      const data = response.data;

      setBasicInfo(data.basicInfo || initialBasicInfo);
      setProfessionalInfo(data.employeeInfo || initialProfessionalInfo);
      setPermanentAddress(data.permanentAddress || initialAddress);
      setPresentAddress(data.presentAddress || initialAddress);
      setLeaveSubtype(data.leaveSubtype || [initialLeaveSubtype]);
      setBankDetails(data.bankDetails || initialBankDetails);
      setUserId(data.user_id);
      setSelectedPhoto(data.employee_photo);
      setInitialData({
        basicInfo: data.basicInfo || initialBasicInfo,
        professionalInfo: data.employeeInfo || initialProfessionalInfo,
        permanentAddress: data.permanentAddress || initialAddress,
        presentAddress: data.presentAddress || initialAddress,
        bankDetails: data.bankDetails || initialBankDetails
      });
      console.log("Before SetDepartment", data.employeeInfo.department.department_id)
      console.log("Before SetDesignation", data.employeeInfo.designation.designation_id)
      setSelectedDepartmentId(data.employeeInfo.department.department_id || '');
      setSelectedDesignationId(data.employeeInfo.designation.designation_id || '');
      console.log("Before SetAuthorities", data.employeeInfo.reporting_authority)
      const selectedAuthorities = data.employeeInfo.reporting_authority.map(authority => authority.id);
      setSelectedAuthorities(selectedAuthorities);
      await fetchAuthorities();
      await fetchDepartments();
      if (data.employeeInfo && data.employeeInfo.department.department_id) {
        await fetchDesignations(data.employeeInfo.department.department_id);
      }
    } catch (error) {
      console.error('Error fetching employee data:', error);
    } finally {
      updateLoading(false);

    }
  };

  const fetchDepartments = async () => {
    try {
      const response = await api.get('/getDepartments');
      setDepartments(response.data);
    } catch (error) {
      console.error('Error fetching departments:', error);
    }
  };

  const fetchDesignations = async (departmentId) => {
    try {
      const response = await api.get(`/getDesignationsForDepartment/${departmentId}`);
      setDesignations(response.data);
    } catch (error) {
      console.error('Error fetching designations:', error);
    }
  };

  const handleDepartmentChange = async (e) => {
    const departmentId = e.target.value;
    setSelectedDepartmentId(departmentId);
    setProfessionalInfo((prevState) => ({
      ...prevState,
      department: departmentId
    }));
    await fetchDesignations(departmentId);
  };

  const handleDesignationChange = (e) => {
    const designationId = e.target.value;
    setSelectedDesignationId(designationId);
    setProfessionalInfo((prevState) => ({
      ...prevState,
      designation: designationId
    }));
  };

  const handleInputChange = (section, setSection) => (e) => {
    const { name, value } = e.target;
    const formattedValue = name.includes("Date") ? parseDate(value) : value;
    setSection(prevState => ({
      ...prevState,
      [name]: formattedValue
    }));
  };

  const handleReportingAuthoritiesChange = (e) => {
    setSelectedAuthorities(e.value);
  };

  const parseDate = (dateString) => {
    if (!dateString) return "";
    const [day, month, year] = dateString.split('-');
    return `${year}-${month}-${day}`;
  };



  const handleEdit = (section) => () => {
    setEditableSections(prevState => ({
      ...prevState,
      [section]: true
    }));
  };

  const handleSaveBasicInfo = async () => {
    try {
      updateLoading(true);
      console.log("Printing basic info is", basicInfo);
      const response = await api.put(`/updateBasicInfo/${id}`, basicInfo);
  
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Basic info updated successfully',
        });
  
        setEditableSections(prevState => ({
          ...prevState,
          basicInfo: false
        }));
        setInitialData(prevState => ({
          ...prevState,
          basicInfo
        }));
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.data?.error || 'An error occurred while updating basic info',
        });
      }
    } catch (error) {
      console.error('Error saving basic info:', error);
      
      let errorMessage = 'An error occurred while updating basic info';
      
      if (error.response) {
        if (error.response.status === 400 && error.response.data?.error === 'This email is already registered') {
          errorMessage = 'This email is already registered';
        } else {
          errorMessage = error.response.data?.error || errorMessage;
        }
      }
  
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: errorMessage,
      });
    } finally {
      updateLoading(false);
    }
  };

  const handleSaveProfessionalInfo = async () => {
    try {
      updateLoading(true);
      console.log("professionalInfo", professionalInfo);
      console.log("authorietes", selectedAuthorities);
      const dataToSend = {
        Joining_Date: professionalInfo.Joining_Date,
        attendance_start_Date: professionalInfo.attendance_start_Date,
        selectedAuthorities,
        pan_number: professionalInfo.pan_number,
        epfo_number: professionalInfo.epfo_number,
        uan_number: professionalInfo.uan_number
      };
      if (professionalInfo.department) {
        dataToSend.department_id = parseInt(professionalInfo.department);
      }


      // Only include designation_id if it's not null
      if (professionalInfo.designation) {
        dataToSend.designation_id = parseInt(professionalInfo.designation);
      }
      console.log("Data tro send is", dataToSend)
      const response = await api.put(`/updateEmployeeInfo/${id}`, dataToSend);
      setEditableSections(prevState => ({
        ...prevState,
        professionalInfo: false
      }));
      fetchData();
      setInitialData(prevState => ({
        ...prevState,
        professionalInfo
      }));
    } catch (error) {
      console.error('Error saving professional info:', error);
    } finally {
      updateLoading(false);
    }
  };

  const handleSavePermanentAddress = async () => {
    try {
      updateLoading(true);
      const response = await api.post(`/employee/${userId}/permanentAddress`, permanentAddress);
      console.log(response.data);
      setEditableSections(prevState => ({
        ...prevState,
        permanentAddress: false
      }));
      setInitialData(prevState => ({
        ...prevState,
        permanentAddress
      }));
    } catch (error) {
      console.error('Error saving permanent address:', error);
    } finally {
      updateLoading(false);
    }
  };

  const handleSavePresentAddress = async () => {
    try {
      updateLoading(true);
      const response = await api.post(`/employee/${userId}/presentAddress`, presentAddress);
      console.log(response.data);
      setEditableSections(prevState => ({
        ...prevState,
        presentAddress: false
      }));
      if (response.status === 200) {
        toast.success(response.data.message);
        setPresentAddress(response.data.address);
      }
      setInitialData(prevState => ({
        ...prevState,
        presentAddress
      }));
    } catch (error) {
      console.error('Error saving present address:', error);
      toast.error(error.response.data.message)
    } finally {
      updateLoading(false);
    }
  };

  const handleCancel = (section, resetFunction) => () => {
    resetFunction();
    setEditableSections(prevState => ({
      ...prevState,
      [section]: false
    }));
  };

  const resetBasicInfo = () => {
    setBasicInfo(initialData.basicInfo);
  };

  const resetProfessionalInfo = () => {
    setProfessionalInfo(initialData.professionalInfo);
  };
  const resetPermanentAddress = () => {
    setPermanentAddress(initialData.permanentAddress);
  };
  const resetPresentAddress = () => {
    setPresentAddress(initialData.presentAddress);
  };
  const resetBankDetails = () => {
    setBankDetails(initialData.bankDetails)
  }
  const handleSaveBankInfo = async () => {
    try {
      updateLoading(true);
      const response = await api.post(`/employee/${userId}/bankDetails`, bankDetails);

      if (response.status === 200) {
        setBankDetails(response.data.bankDetails);
        toast.success('Bank details saved successfully');
        setEditableSections(prevState => ({
          ...prevState,
          bankInfo: false
        }));
        setInitialData(prevState => ({
          ...prevState,
          bankDetails
        }));

      }
      else {
        toast.error(response.data.error)
      }
    } catch (error) {
      toast.error('Error saving bank info');
    } finally {
      updateLoading(false);
    }
  };

  const renderSection = (title, section, data, setSection, resetFunction, handleSave, defaultOpen) => {
    const isEditable = editableSections[section];
    const showClass = defaultOpen ? 'show' : '';

    return (
      <div className="accordion-item">
        <h2 className="accordion-header" id={`heading-${section}`}>
          <button
            className="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#collapse-${section}`}
            aria-expanded={defaultOpen ? "true" : "false"}
            aria-controls={`collapse-${section}`}
            style={{ 'backgroundColor': ' #eeeff1' }}
          >
            {title}
          </button>
        </h2>
        <div
          id={`collapse-${section}`}
          className={`accordion-collapse collapse ${showClass}`}
          aria-labelledby={`heading-${section}`}
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body" style={{ backgroundColor: '#f9f9f9', padding: '20px' }}>
            <div className="row">
              <div className="d-flex justify-content-end">
                {isEditable && (
                  <div className="btn-group">
                    <button type="button" className="btn btn-primary mx-1" onClick={handleSave}>
                      Save
                    </button>
                    <button type="button" className="btn btn-outline-secondary mx-1" onClick={handleCancel(section, resetFunction)}>
                      Cancel
                    </button>
                  </div>
                )}
                {!isEditable && (
                  <button type="button" className="btn btn-link" onClick={handleEdit(section)} style={{ alignItems: 'end' }}>
                    <i className="fas fa-edit"></i> Edit Info
                  </button>
                )}
              </div>
              {Object.keys(data).map(key => (
                <div className="col-md-6 mb-3" key={key}>
                  <label htmlFor={key} style={{ 'text-transform': 'capitalize' }} className="form-label">{key.replace(/_/g, ' ')}</label>
                  {key === "role" ? (
                    <select
                      id={key}
                      name={key}
                      value={data[key]}
                      onChange={handleInputChange(section, setSection)}
                      className="form-control"
                      disabled={!isEditable}
                    >
                      <option value="USER">User</option>
                      <option value="MANAGER">Manager</option>
                      <option value="ADMIN">Admin</option>
                      <option value="SUPERADMIN">Super Admin</option>
                    </select>
                  ) : key === "gender" ? (
                    <select
                      id={key}
                      name={key}
                      value={data[key]}
                      onChange={handleInputChange(section, setSection)}
                      className="form-control"
                      disabled={!isEditable}
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  ) : key === "MaritalStatus" ? (
                    <select
                      id={key}
                      name={key}
                      value={data[key]}
                      onChange={handleInputChange(section, setSection)}
                      className="form-control"
                      disabled={!isEditable}
                    >
                      <option value="Single">Single</option>
                      <option value="Married">Married</option>
                      <option value="Other">Other</option>
                    </select>
                  ) : key === "department" ? (
                    <select
                      id={key}
                      name={key}
                      value={selectedDepartmentId}
                      onChange={handleDepartmentChange}
                      className="form-control"
                      disabled={!isEditable}
                    >
                      <option value="">Select Department</option>
                      {departments.map(department => (
                        <option key={department.department_id} value={department.department_id}>
                          {department.department_name}
                        </option>
                      ))}
                    </select>
                  ) : key === "designation" ? (
                    <select
                      id={key}
                      name={key}
                      value={selectedDesignationId}
                      onChange={handleDesignationChange}
                      className="form-control"
                      disabled={!isEditable}
                    >
                      <option value="">Select Designation</option>
                      {designations.map(designation => (
                        <option key={designation.designation_id} value={designation.designation_id}>
                          {designation.designation_name}
                        </option>
                      ))}
                    </select>
                  ) : key === "employee_id" ? (
                    <input
                      type="text"
                      id={key}
                      name={key}
                      value={data[key]}
                      className="form-control"
                      disabled={true} // Always disabled for employee_id
                    />
                  ) : key === "reporting_authority" ? (
                    <MultiSelect
                      value={selectedAuthorities}
                      onChange={handleReportingAuthoritiesChange}
                      options={authorities}
                      optionLabel="name"
                      optionValue="id"
                      filter
                      className="custom-input"
                      style={{ width: '100%', height: '40px', borderRadius: 'unset' }}
                      placeholder="Select Authorities"
                      disabled={!isEditable}
                    />
                  )
                    :
                    (
                      <input
                        type={key.includes("Date") ? "date" : "text"}
                        id={key}
                        name={key}
                        value={key.includes("Date") ? parseDate(data[key]) : data[key]} // Format date fields
                        onChange={handleInputChange(section, setSection)}
                        className="form-control"
                        disabled={!isEditable}
                      />

                    )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (


    <div className="row">
      <div class="h5 pb-2 mt-2 mb-2 border-bottom text-primary ml-2">
       Employee Details
      </div>
      <div className="col-12 col-md-6 col-lg-3 mb-3">
        <div className="card bg-white shadow-sm">
          <div className="row g-0">
            <div className="col-md-4 d-flex justify-content-center align-items-center rounded">
              <div className="position-relative d-inline-block rounded-circle overflow-hidden">
                <img src={SelectedPhoto != null ? SelectedPhoto : "/images/icon/Noprofile.webp"} className="card-img-center img-fluid" alt="Person Image" />
              </div>
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <h5 className="card-title mb-0">{basicInfo.Name}</h5>
                <p className="card-text text-muted mb-1">{professionalInfo.designation.designation_name || ""}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 col-lg-9" style={{ height: '100vh', overflowY: 'auto' }}>
        <div className="accordion" id="accordionExample">
          {renderSection("Basic Information", "basicInfo", basicInfo, setBasicInfo, resetBasicInfo, handleSaveBasicInfo, true)}
          {renderSection("Professional Information", "professionalInfo", professionalInfo, setProfessionalInfo, resetProfessionalInfo, handleSaveProfessionalInfo)}
          {renderSection("Permanent Address", "permanentAddress", permanentAddress, setPermanentAddress, resetPermanentAddress, handleSavePermanentAddress)}
          {renderSection("Present Address", "presentAddress", presentAddress, setPresentAddress, resetPresentAddress, handleSavePresentAddress)}
          {renderSection("Bank Information", "bankInfo", bankDetails, setBankDetails, resetBankDetails, handleSaveBankInfo)}
        </div>
      </div>
    </div>
  );
};

export default EditEmployee;

