import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../Authenticaltion/api';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { setAuthToken } from '../../Authenticaltion/api';
import { Dialog, Button, InputText } from 'primereact';

const ProfilePage = () => {
    const navigate = useNavigate();
    const [uploadedPhotoURL, setUploadedPhotoURL] = useState('');
    const [photoSelected, setPhotoSelected] = useState(false);
    const [file, setFile] = useState(null);
    const [profileData, setProfileData] = useState({});
    const [bankName, setBankName] = useState('');
    const [bankAccountNumber, setBankAccountNumber] = useState('');
    const [bankIFSC, setBankIFSC] = useState('');
    const [epfoUAN, setEpfoUAN] = useState('');
    const [epfoNumber, setEpfoNumber] = useState('');
    const [permanentStreet, setPermanentStreet] = useState('');
    const [permanentCity, setPermanentCity] = useState('');
    const [permanentDistrict, setPermanentDistrict] = useState('');
    const [permanentState, setPermanentState] = useState('');
    const [permanentCountry, setPermanentCountry] = useState('');
    const [permanentZip, setPermanentZip] = useState('');
    const [presentStreet, setPresentStreet] = useState('');
    const [presentCity, setPresentCity] = useState('');
    const [presentDistrict, setPresentDistrict] = useState('');
    const [presentState, setPresentState] = useState('');
    const [presentCountry, setPresentCountry] = useState('');
    const [presentZip, setPresentZip] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    // Function to handle file selection for profile image


    const handleFileChange = (event) => {
        const uploadedFile = event.target.files[0];
        setFile(uploadedFile);
        setPhotoSelected(true);
        const reader = new FileReader();
        reader.onloadend = () => {
            setUploadedPhotoURL(reader.result);
        };
        reader.readAsDataURL(uploadedFile);
    };

    useEffect(() => {
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            api.patch('/updateProfileImage', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }).then((response) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Profile Image Updated',
                    text: 'Your profile image has been successfully updated!',
                    timer: 1000,
                    showConfirmButton: false,
                });
                setProfileData((prevData) => ({
                    ...prevData,
                    img_url: response.data.img_url,
                }));
            }).catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'An error occurred while updating your profile image. Please try again later.',
                });
            });
        }
    }, [file]);
    const handlePasswordChange = async (e) => {
        e.preventDefault();
    
        if (newPassword !== confirmPassword) {
            setPasswordError('New password and confirm password do not match.');
            return;
        }
    
        try {
            const response = await api.post('/changePassword', {
                currentPassword,
                newPassword,
            });
    
            if (response.status === 200) {
                setShowDialog(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Password Changed',
                    text: 'Your password has been successfully changed!',
                    timer: 1000,
                    showConfirmButton: false,
                }).then(() => {
                    // Clear local storage and navigate to the home page
                    localStorage.clear();
                    navigate('/');
                });
    
                // Reset form fields
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
                setPasswordError('');
            } else {
                setPasswordError(response.data.error);
            }
        } catch (error) {
            setPasswordError(error.response.data.error);
        }
    };
    
    const clsoeModal = () => {
        setCurrentPassword('')
        setNewPassword('')
        setConfirmPassword('')
        setShowDialog(false)

    }

    // Effect to fetch initial profile data
    useEffect(() => {
        fetchProfileDataFromAPI()
            .then((data) => {
                setProfileData(data);
                setBankName(data.bankName || '');
                setBankAccountNumber(data.bankAccountNumber || '');
                setBankIFSC(data.bankIFSC || '');
                setEpfoUAN(data.epfoUAN || '');
                setEpfoNumber(data.epfoNumber || '');
                setPermanentStreet(data.permanentStreet || '');
                setPermanentCity(data.permanentCity || '');
                setPermanentDistrict(data.permanentDistrict || '');
                setPermanentState(data.permanentState || '');
                setPermanentCountry(data.permanentCountry || '');
                setPermanentZip(data.permanentZip || '');
                setPresentStreet(data.presentStreet || '');
                setPresentCity(data.presentCity || '');
                setPresentDistrict(data.presentDistrict || '');
                setPresentState(data.presentState || '');
                setPresentCountry(data.presentCountry || '');
                setPresentZip(data.presentZip || '');
            })
            .catch((error) => console.error('Error fetching profile data:', error));
    }, []);

    // Function to fetch profile data from API
    const fetchProfileDataFromAPI = () => {
        return api.get("/profiledetails")
            .then((response) => {
                const data = response.data;
                if (data) {
                    const profileDataFromAPI = {
                        Empid: data.Emp_Id || "1",
                        Name: data.Name,
                        manager: data.ManagerName || 'Not Assigned',
                        email: data.Email_Id,
                        joiningDate: data.Joining_Date,
                        phoneNumber: data.Phone_No || '',
                        designation: data.Designation || '',
                        address: data.Address || '',
                        img_url: data.image || '/images/icon/Noprofile.webp',
                        bankName: data.bankName || '',
                        bankAccountNumber: data.bankAccountNumber || '',
                        bankIFSC: data.bankIFSC || '',
                        epfoUAN: data.epfoUAN || '',
                        epfoNumber: data.epfoNumber || '',
                        permanentStreet: data.permanentStreet || '',
                        permanentCity: data.permanentCity || '',
                        permanentDistrict: data.permanentDistrict || '',
                        permanentState: data.permanentState || '',
                        permanentCountry: data.permanentCountry || '',
                        permanentZip: data.permanentZip || '',
                        presentStreet: data.presentStreet || '',
                        presentCity: data.presentCity || '',
                        presentDistrict: data.presentDistrict || '',
                        presentState: data.presentState || '',
                        presentCountry: data.presentCountry || '',
                        presentZip: data.presentZip || '',
                    };
                    return profileDataFromAPI;
                } else {
                    return {};
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <div class=" shadow p-3 bg-white rounded">
            <div class="h5 pb-2 mt-2 mb-2 border-bottom text-primary">
                My Profile
            </div>
            <div className="row mt-3 p-3">
                <div className="col-md-3 mb-4">
                    <div className="card" >
                        <div className="card-body text-center" style={{ backgroundColor: "546EEF" }}>
                            <div className="row">
                                <img
                                    src={profileData.img_url || "/images/icon/Noprofile.webp"}
                                    alt="Profile"
                                    className="rounded-circle "
                                    style={{ width: '120px', height: '100px', margin: 'auto' }}
                                />
                                <label htmlFor="fileInput" className="edit-icon">
                                    <i className="fa fa-edit"> <input
                                        id="fileInput"
                                        type="file"
                                        accept="image/*"
                                        className="d-none"
                                        onChange={handleFileChange}
                                    /></i>
                                </label>
                            </div>
                            <div className='row '>
                                <h5 className="User_Name text-center">{profileData.Name}</h5>
                                <p className="User_Email text-center">{profileData.email}</p>
                            </div>
                            <div className='row'>

                                {/* <Button
                                        label="Change Password"
                                        className=" btn-sm"
                                        onClick={() => setShowDialog(true)}
                                    /> */}
                                <div className='btn btn-sm btn-outline-primary' onClick={() => setShowDialog(true)}>Change Password</div>

                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="card mb-4">
                        <div className="card-header">Personal Details</div>
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-sm-3">
                                    <label className="form-label">Full Name</label>
                                </div>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={profileData.Name}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-3">
                                    <label className="form-label">Email</label>
                                </div>
                                <div className="col-sm-9">
                                    <input
                                        type="email"
                                        className="form-control"
                                        value={profileData.email}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-3">
                                    <label className="form-label">Phone</label>
                                </div>
                                <div className="col-sm-9">
                                    <input
                                        type="tel"
                                        className="form-control"
                                        value={profileData.phoneNumber}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-3">
                                    <label className="form-label">Date of Joining</label>
                                </div>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={profileData.joiningDate}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-3">
                                    <label className="form-label">Reporting Authority</label>
                                </div>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={profileData.manager}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-3">
                                    <label className="form-label">Designation</label>
                                </div>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={profileData.designation}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header">Permanent Address</div>
                                <div className="card-body">
                                    <div className="mb-3">
                                        <label className="form-label">Street</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={permanentStreet}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">City</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={permanentCity}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">District</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={permanentDistrict}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">State</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={permanentState}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Country</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={permanentCountry}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Zip</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={permanentZip}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header">Present Address</div>
                                <div className="card-body">
                                    <div className="mb-3">
                                        <label className="form-label">Street</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={presentStreet}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">City</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={presentCity}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">District</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={presentDistrict}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">State</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={presentState}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Country</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={presentCountry}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Zip</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={presentZip}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card mb-4">
                                <div className="card-header">Bank Information</div>
                                <div className="card-body">
                                    <div className="mb-3">
                                        <label className="form-label">Bank Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={bankName}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Bank Account Number</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={bankAccountNumber}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Bank IFSC</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={bankIFSC}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="card mb-4">
                                <div className="card-header">EPFO and Salary Information</div>
                                <div className="card-body">
                                    <div className="mb-3">
                                        <label className="form-label">UAN Number</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={epfoUAN}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">EPF Number</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={epfoNumber}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog
                header="Change Password"
                visible={showDialog}
                onHide={() => clsoeModal()}
                style={{ width: '400px' }}
            >
                <form onSubmit={handlePasswordChange} className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="currentPassword">Current Password <span
                            style={{ color: 'red' }}
                        >
                            *
                        </span></label>
                        <InputText
                            id="currentPassword"
                            type="text"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            required
                            className="p-inputtext p-component"
                        />
                    </div>
                    <div className="p-field">
                        <label htmlFor="newPassword">New Password <span
                            style={{ color: 'red' }}
                        >
                            *
                        </span></label>
                        <InputText
                            id="newPassword"
                            type="text"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                            className="p-inputtext p-component"
                        />
                    </div>
                    <div className="p-field">
                        <label htmlFor="confirmPassword">Confirm New Password <span
                            style={{ color: 'red' }}
                        >
                            *
                        </span></label>
                        <InputText
                            id="confirmPassword"
                            type="text"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                            className="p-inputtext p-component"
                        />
                        {passwordError && (
                            <small className="p-error">{passwordError}</small>
                        )}
                    </div>
                    <div className="d-flex pt-1   justify-content-center align-items-center">
                        <button type="submit" className="btn btn-primary">Submit</button>

                    </div>
                </form>
            </Dialog>

        </div>
    );
};

export default ProfilePage;
