import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import "./Late15.css";
import { RotatingLines } from "react-loader-spinner";
import api from "../../../Authenticaltion/api";

const AbsentGraphChart = () => {
  const navigate = useNavigate();
  const [graphData, setGraphData] = useState([]);
  const [graphType, setGraphType] = useState("last7days");
  const [isLoading, setIsLoading] = useState(false);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"];

  useEffect(() => {
    fetchData();
  }, [graphType]); // Fetch data whenever graphType changes

  const fetchData = () => {
    setIsLoading(true);
    // Fetch data based on the selected graph type
    let url = "";
    if (graphType === "last7days") {
      url = "/Last7Days";
    } else if (graphType === "last3months") {
      url = "/Last3Month";
    }

    api
      .get(url)
      .then((res) => {
        const data = res.data;
        console.log(data);
        let newData = [];

        if (graphType === "last7days") {
          newData = data.map((obj) => {
            const dateParts = obj.FormattedDate.split("-");
            const date = new Date(
              `${dateParts[1]}-${dateParts[0]}-${dateParts[2]}`
            );
            const absent = obj.absentEmployees;
            return { Name: day[date.getDay()], Absent: absent.length };
          });
        } else if (graphType === "last3months") {
          const currentDate = new Date();
          const threeMonthsAgo = new Date(currentDate);
          threeMonthsAgo.setMonth(currentDate.getMonth() - 2);
          const monthData = {};
          data.forEach((item) => {
            const dateParts = item.FormattedDate.split("-");
            const year = parseInt(dateParts[2], 10);
            const month = parseInt(dateParts[1], 10) - 1; // Months are 0-based in JavaScript
            const monthKey = `${year}-${month}`;
            if (monthData[monthKey]) {
              monthData[monthKey].Absent += 1; // Employees are always absent
            } else {
              monthData[monthKey] = {
                Name: `${monthNames[month]}`,
                Absent: 1,
              };
            }
          });

          newData = Object.values(monthData);
        }

        setGraphData(newData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleGraphTypeChange = (type) => {
    console.log(type);
    setGraphType(type);
  };

  const options = {
    series: [
      {
        name: "Absent",
        data: graphData ? graphData.map((item) => item.Absent) : [],
      },
    ],
    annotations: {
      points: [
        {
          x: "Bananas",
          seriesIndex: 0,
          label: {
            // borderColor: '#775DD0',
            offsetY: 0,
            // style: {
            //   color: '#fff',
            //   background: '#775DD0',
            // },
            // text: 'Bananas are good',
          },
        },
      ],
    },
    chart: {
      height: 100,
      width: "100%",
      type: "bar",
    },
    plotOptions: {
      bar: {
        columnWidth: "30%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
      colors: "#e16767",
    },
    grid: {
      row: {
        colors: ["#fff"],
      },
    },
    xaxis: {
      labels: {
        rotate: -45,
      },
      title: {
        text: graphType === "last7days" ? "Day" : "Month",
      },
      categories: graphData ? graphData.map((item) => item.Name) : [],
    },
    yaxis: {
      title: {
        text: "Employee",
      },
    },
    fill: {
      colors: ["#ff3333"], // Set the color to red for all bars
    },
  };

  return (
    <>
      <div className="col-lg-6">
        <h3 className="text-primary">Absent Employee</h3>
        <div className="au-card recent-report">
          <div className="d-flex align-items-center justify-content-between">
            <div className="form-group">
              <select
                className="form-control"
                value={graphType}
                onChange={(e) => handleGraphTypeChange(e.target.value)}
              >
                <option value="last7days">Last 7 Days</option>
                <option value="last3months">Last 3 Months</option>
              </select>
            </div>
            <div className="form-group">
              <button
                type="button"
                className="btn btn-primary ml-2"
                onClick={() =>
                  navigate("/Dashboard/DisplayData", {
                    state: { Title: graphType },
                  })
                }
              >
                View
              </button>
            </div>
          </div>
          <div className="recent-report__chart">
            <Chart
              options={options}
              series={options.series}
              width="100%"
              type="bar"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AbsentGraphChart;
