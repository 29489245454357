import React from 'react'
import './Loader.css'

const LoaderComponenet = () => {
  return (
    // <div className='loading-overlay'>

    //  <img src="/images/icon/title.png"  className='loading-icon1' alt="WFMS"  />
    // </div>
<div class="fond conatiner-fluid">
  {/* <div class="contener_general">
      <div class="contener_mixte"><div class="ballcolor ball_1">&nbsp;</div></div>
      <div class="contener_mixte"><div class="ballcolor ball_2">&nbsp;</div></div>
      <div class="contener_mixte"><div class="ballcolor ball_3">&nbsp;</div></div>
      <div class="contener_mixte"><div class="ballcolor ball_4">&nbsp;</div></div>
  </div> */}
  <div className="loader"></div>
  </div>
    
  )
}
export default LoaderComponenet
