import React, { useState, useEffect } from 'react';
import {useLoading} from '../../LoadingContext'
import api from '../../Authenticaltion/api';
import { toast } from 'react-toastify';

const UserProfile = () => {

  const [photoSelected, setPhotoSelected] = useState(false);
  const [uploadedPhotoURL, setUploadedPhotoURL] = useState(null);
  const [file, setFile] = useState(null);
  const [profileData, setProfileData] = useState({});
  const [originalProfileData, setOriginalProfileData] = useState({})
  const { updateLoading } = useLoading();

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
    setPhotoSelected(true);
    const reader = new FileReader();
    reader.onloadend = () => {
      setUploadedPhotoURL(reader.result);
    };
    reader.readAsDataURL(uploadedFile);

  };
  const [isEditing, setIsEditing] = useState(false);


  // Define your functions here
  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setProfileData(originalProfileData)

    setIsEditing(false);

  }
  const validateEmail = (email) => {
    // Use a regular expression to check for a valid email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(profileData.company_email)) {
      toast.error('Please enter a valid email address.');
      setProfileData(originalProfileData);
      setIsEditing(false);
      return;
    }

    try {
      const formData = new FormData();
      if (uploadedPhotoURL) {
        formData.append('file', file)
      }
      console.log("Prtingn profile data is", profileData)
      console.log("Printing updated data is", originalProfileData)
      Object.keys(profileData).forEach((key) => {
        if (profileData[key] !== originalProfileData[key]) {
          formData.append(key, profileData[key]);
        }
      });

      // Send only the updated fields to the backend
      console.log("Prining updated data is", formData)
      const response = await api.put('/updateCompanyProfile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        toast.success('Profile updated successfully');
        fetchProfileData();
        // You may want to refetch the data or perform other actions here
      } else {
        console.log('Failed to update profile');
      }
    } catch (error) {
      toast.error('Error updating profile');
    }

    setIsEditing(false);
  };
  const fetchProfileData = async () => {
    updateLoading(true)
    try {
      const response = await api.get('/CompanyProfile');
      if (response.status === 200) {
        setProfileData(response.data[0]);
        console.log("Pringng original data", profileData)
        setOriginalProfileData(response.data[0])
      } else {
        console.log('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    finally{
      updateLoading(false)
    }
  };
  useEffect(() => {
    fetchProfileData();
  }, []);
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card mb-4" style={{ maxWidth: 'none' }}>
          <h5 className="card-header" style={{ borderBottom: 'none', backgroundColor: 'transparent' }}>
            Profile Details
          </h5>
          <div className="card-body">
            <div className="d-flex align-items-start align-items-sm-center gap-4">
              <img
                src={photoSelected ? uploadedPhotoURL : profileData.company_image === null
                  ? '/images/icon/Noprofile.webp'
                  : profileData.company_image}
                alt="user-avatar"
                className="d-block rounded-circle"
                height="100"
                width="100"
                id="uploadedAvatar"
                style={{ border: '2px solid blue' }}
              />
              <div >
                {!isEditing && (

                  <button className="btn  me-2 mb-4" onClick={handleEditClick} tabIndex="0" style={{ backgroundColor: '#6366F1', color: 'white' }}>
                    <span className="d-none d-sm-block"><i className="far fa-edit"></i></span>
                    <i className="bx bx-edit d-block d-sm-none"><i className="far fa-edit"></i></i>
                  </button>
                )}
                {isEditing && (
                  <>
                    <label htmlFor="upload" className="btn  me-2 mb-4" tabIndex="0" style={{ backgroundColor: '#6366F1', color: 'white' }}>
                      <span className="d-none d-sm-block" ><i className="fas fa-upload" ></i></span>
                      <i className="bx bx-upload d-block d-sm-none"> <i className="fas fa-upload" ></i></i>
                      <input
                        type="file"
                        id="upload"
                        className="account-file-input"
                        hidden
                        name="file"
                        accept="image/png, image/jpeg"
                        onChange={handleFileChange}
                      />
                    </label>
                  </>
                )}

              </div>
            </div>
          </div>
          <hr className="my-0" />
          <div className="card-body">
            <form id="formAccountSettings" method="POST" onSubmit={() => false}>
              <div className="row">
                <div className="mb-3 col-md-6">
                  <label htmlFor="companyName" className="form-label">
                    Company Name
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="companyName"
                    name="companyName"
                    value={profileData.company_name}
                    onChange={(e) => setProfileData({ ...profileData, company_name: e.target.value })}
                    disabled={!isEditing}
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <label htmlFor="cinNumber" className="form-label">
                    CIN Number
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="cinNumber"
                    name="cinNumber"
                    value={profileData.cin_number}
                    onChange={(e) => setProfileData({ ...profileData, cin_number: e.target.value })}
                    disabled={!isEditing}
                  />
                </div>
                {/* Other fields */}
                <div className="mb-3 col-md-6">
                  <label htmlFor="email" className="form-label">
                    E-mail
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="email"
                    name="email"
                    value={profileData.company_email}
                    onChange={(e) => setProfileData({ ...profileData, company_email: e.target.value })}
                    placeholder="email"
                    disabled
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <label className="form-label" htmlFor="phoneNumber">
                    Phone Number
                  </label>
                  <div className="input-group input-group-merge">
                    <span className="input-group-text">IN(+91)</span>
                    <input
                      type="text"
                      id="phoneNumber"
                      name="phoneNumber"
                      className="form-control"
                      placeholder=""
                      maxLength={10}
                      minLength={10}
                      value={profileData.company_phone}
                      onChange={(e) => setProfileData({ ...profileData, company_phone: e.target.value })}
                      disabled={!isEditing}
                    />
                  </div>
                </div>
                <div className="mb-3 col-md-6">
                  <label htmlFor="address" className="form-label">
                    Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    name="address"
                    placeholder="Address"
                    value={profileData.company_address}
                    onChange={(e) => setProfileData({ ...profileData, company_address: e.target.value })}
                    disabled={!isEditing}
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <label htmlFor="industry" className="form-label">
                    Industry
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="industry"
                    name="industry"
                    value={profileData.industry}
                    onChange={(e) => setProfileData({ ...profileData, industry: e.target.value })}
                    disabled={!isEditing}
                  />
                </div>
                {/* <div className="mb-3 col-md-6">
                  <label htmlFor="foundingDate" className="form-label">
                    Founding Date
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="foundingDate"
                    name="foundingDate"
                    value={profileData.foundingDate}
                    disabled
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <label htmlFor="founders" className="form-label">
                    Founder(s)
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="founders"
                    name="founders"
                    value={profileData.founders ? profileData.founders.join(', ') : ''}
                    disabled
                  />
                </div> */}
              </div>
              <div className="d-flex justify-content-end">
                {isEditing && (
                  <>
                    <button type="submit" className="btn btn-primary me-2 mb-4" onClick={handleSubmit}>
                      <span className="d-none d-sm-block">Save changes</span>
                      <i className="bx bx-save d-block d-sm-none">Save</i>
                    </button>
                    <button type="button" className="btn btn-danger mb-4" onClick={handleCancel}>
                      <span className="d-none d-sm-block">Cancel</span>
                      <i className="bx bx-x-circle d-block d-sm-none">Cancel</i>
                    </button>
                  </>
                )}
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>


  );
};

export default UserProfile;
