import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../Authenticaltion/roleFinder";
const ProtectedRoute = ({ allowedRoles }) => {
  const { auth, role } = useAuth();
  if (allowedRoles) {
    const isAllowed = allowedRoles.includes(role);
    return auth && isAllowed ? <Outlet /> : <Navigate to="/" />;
  } else {
    return auth ? <Outlet /> : <Navigate to="/" />;
  }
};

export default ProtectedRoute;
