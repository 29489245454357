import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Link } from 'react-router-dom';
import api from '../../Authenticaltion/api';
import { Dialog } from 'primereact/dialog';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import "./WorkFromHome.css";

const UserWorkFromHome = () => {
    const [selectedValue, setSelectedValue] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [ViewDialog, setViewDialog] = useState(false);
    const [AppliedWFH, setAppliedWFH] = useState([]);
    const [filteredWFH, setFilteredWFH] = useState([]);
    const [SelectedWFH, setSelectedWFH] = useState('');

    const handleDropdownChange = (e) => {
        setSelectedValue(e.value);
    };

    const ViewWFH = (leave) => {
        setSelectedWFH(leave);
        setViewDialog(true);
    }

    const ColoseDialog = () => {
        setViewDialog(false);
    }

    const handelDeleteWFH = async (id) => {
        try {
            const response = await api.delete(`/DeleteWfH/${id}`);
            if (response.status === 200) {
                toast.success("WFH deleted successfully");
                AppliedWorkFromHome();
            } else {
                toast.error("An error occurred while deleting WFH");
            }
        } catch (err) {
            toast.error("An error occurred while deleting WFH");
        }
    }

    const DeleteWfh = async (id) => {
        try {
            const result = await Swal.fire({
                title: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Delete it!"
            });

            if (result.isConfirmed) {
                await handelDeleteWFH(id);
                Swal.fire({
                    icon: "success",
                    title: "Successfully Deleted",
                    showConfirmButton: false,
                    timer: 1500
                });
                AppliedWorkFromHome();
            }
        } catch (error) {
            toast.error("An error occurred while deleting WFH");
        }
    }

    const AppliedWorkFromHome = async () => {
        try {
            const response = await api.get('/GetWFHdata');
            if (response.status === 200) {
                setAppliedWFH(response.data.data);
                setFilteredWFH(response.data.data); // Set filteredWFH initially to all data
            } else {
                console.error('Error fetching Work From Home', response.data.error);
            }
        } catch (error) {
            console.error('Error fetching Work From Home', error);
        }
    }

    const handleSearch = () => {
        // Filter the AppliedWFH based on selected dates and status
        const filtered = AppliedWFH.filter(wfh => {
            const wfhDate = new Date(wfh.wfh_from);
            const start = startDate ? new Date(startDate) : null;
            const end = endDate ? new Date(endDate) : null;

            return (!start || (wfhDate >= start)) &&
                (!end || (wfhDate <= end)) &&
                (!selectedValue || wfh.status === selectedValue);
        });
        setFilteredWFH(filtered);
    }

    useEffect(() => {
        AppliedWorkFromHome();
    }, []);

    return (
        <div className="shadow p-3 bg-white rounded" style={{ minHeight: '85vh ' }}>
            <div className="h3 pb-2 mt-2 mb-2 border-bottom text-primary">
                Your Work From Home
            </div>
            <form className="WFHForm mt-4">
                <div className="row">
                    <div className="col-md-3 col-sm-4 mb-6">
                        <input type="date" className="form-control" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    </div>
                    <div className="col-md-3 col-sm-4 mb-6">
                        <input type="date" className="form-control" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                    </div>
                    <div className="col-md-3 col-sm-4 mb-6">
                        <Dropdown
                            value={selectedValue}
                            options={[
                                { label: 'Pending', value: 'Pending' },
                                { label: 'Approved', value: 'Approved' },
                                { label: 'Rejected', value: 'Rejected' },
                            ]}
                            placeholder='Status'
                            style={{ width: '100%', height: '37px' }}
                            onChange={handleDropdownChange}
                        />
                    </div>
                    <div className="col-md-3 col-sm-12 mb-6">
                        <div className="row">
                            <button type="button" className="btn btn-light col-sm-6" style={{ borderRadius: "20px", color: 'blue' }} onClick={handleSearch}>
                                <i className="fas fa-search"></i> Search
                            </button>

                            <Link to="/apply-WFH" className="btn col-sm-6" style={{ backgroundColor: "#6366F1", color: "white", borderRadius: "20px" }}>
                                Apply
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    {Array.isArray(filteredWFH) && filteredWFH.map(leave => (
                        <div className="col-md-3" key={leave.id}>
                            <div className="card" style={{ border: "none", boxShadow: "4px 6px 3px #B3A9A9" }}>
                                <div className="card-header" style={{ border: "none", backgroundColor: "#EBEEF8" }}>
                                    <h5 className="card-title d-flex justify-content-between align-items-center" style={{ color: "#1531EB" }}>
                                        Work From Home
                                        {leave.status === 'Pending' &&
                                            <i className="fa fa-trash" style={{ color: "#F03939" }} onClick={() => DeleteWfh(leave.WFH_id)}></i>
                                        }
                                    </h5>
                                </div>

                                <div className="card-body" style={{ backgroundColor: "#F8F8F8" }}>
                                    <div className="card-content" onClick={() => ViewWFH(leave)}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <i className="fa fa-clock" /><span className="contentLable ml-2">Applied on:<span className="AppliedDate"> {leave.applied_date}</span> </span>
                                            </div>
                                            <div className="col-md-12 mt-2">
                                                <i className="fa fa-calendar" />
                                                <span className="contentLable ml-2">WFH from: <span className="DateDuration"> {leave.wfh_from}</span></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer text-center" style={{ backgroundColor: "#2866FF", border: "none" }} onClick={() => ViewWFH(leave)}>
                                    <span className="CurrStatus" style={{ color: leave.status === 'Pending' ? '#FFE617' : (leave.status === 'Approved' ? '#7FFD0F' : '#FB1409') }}>{leave.status}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                    {filteredWFH.length === 0 && (
                        <div className="col-12">
                            <p className="text-center">You haven't taken any work from home.</p>
                        </div>
                    )}

                    <Dialog className="Dialogbox" visible={ViewDialog} showHeader={false} style={{ transition: 'opacity 0.01s ' }}>
                        <div className="card chat">
                            <div className="card-header" style={{ backgroundColor: "#EBEEF8" }}>
                                <h5 className="card-title d-flex justify-content-between align-items-center" style={{ color: "#1531EB" }}>
                                    <u>Work From Home</u>
                                    <i class="fa fa-times" onClick={ColoseDialog}></i>
                                </h5>
                                <div class="card-content mt-3" >
                                    <div class="row">
                                        <div class="col-md-12">
                                            <i class="fa fa-clock" /><span class="contentLable ml-2">Applied on:<span class="AppliedDate"> {SelectedWFH?.applied_date}</span> </span>
                                        </div>
                                        <div class="col-md-12 mt-2">
                                            <i class="fa fa-calendar" />
                                            <span class="contentLable ml-2">WFH from: <span class="DateDuration">{SelectedWFH?.wfh_from}</span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card-body " >
                                <div class="chat-history">
                                    <ul class="message-list">
                                        <li class="row justify-content-start">
                                            <span>
                                                <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/chat_avatar_01_green.jpg" alt="avatar" style={{ height: "30px" }} />
                                                <span class="message-data-time float-left ml-2">10:10 AM, Today</span>
                                            </span>
                                            <div class="message bg-primary mt-2">
                                                {SelectedWFH.me}
                                            </div>
                                        </li>

                                        {/* <li class="row justify-content-end">
                                            <span class="d-flex justify-content-end">
                                                <span class="message-data-time mr-2">10:10 AM, Today</span>
                                                <img class="direct-chat-img" src="https://bootdey.com/img/Content/user_2.jpg" alt="avatar" style={{ height: "30px" }} />
                                            </span>

                                            <div class="message bg-primary mt-2">
                                                {SelectedWFH.me}
                                            </div>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="card-footer text-center d-flex justify-content-center align-items-center" style={{ backgroundColor: "#2866FF", height: "6vh" }}>
                                <span className="CurrStatus" style={{ color: SelectedWFH?.status === 'Pending' ? '#FFE617' : (SelectedWFH?.status === 'Approved' ? '#7FFD0F' : '#FB1409') }}>{SelectedWFH?.status}</span>
                            </div>

                        </div>
                    </Dialog>
                </div>
            </form>
        </div>
    );
}

export default UserWorkFromHome;
