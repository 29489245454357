import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import { useNavigate } from 'react-router-dom';
import api from '../../../Authenticaltion/api';
import { useLoading } from '../../../LoadingContext';

const Latecomer15Days = () => {
  const [graphAbest, setGraphData] = useState([]);
  const [graphType, setGraphType] = useState('LateCorner15Days');
  const { updateLoading } = useLoading();
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [graphType]);

  const fetchData = () => {
    updateLoading(true);
    let url = '';
    if (graphType === 'LateCorner15Days') {
      url = '/LateCorner15Days';
    } else if (graphType === 'Last1Month') {
      url = '/Last1Month';
    } else if (graphType === 'LastMonth') {
      url = '/LastMonth';
    }
    api.get(url)
      .then((res) => {
        const pidataForLate15Days = res.data;
        const entryCounts = {};
        pidataForLate15Days.forEach((item) => {
          const { FormattedDate } = item;
          if (entryCounts[FormattedDate]) {
            entryCounts[FormattedDate] += 1;
          } else {
            entryCounts[FormattedDate] = 1;
          }
        });
        let newData = Object.entries(entryCounts).map(([FormattedDate, late]) => ({
          ABS_DATE: FormattedDate.split('-')[0],
          late,
        }));
        setGraphData(newData);
        updateLoading(false);
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  };

  const handleGraphTypeChange = (type) => {
    setGraphType(type);
    console.log(type);
  };

  const options = {
    series: [{
      name: 'Latecomers',
      data: graphAbest ? graphAbest.map((item) => item.late) : []
    }],
    annotations: {
      points: [{
        x: 'Bananas',
        seriesIndex: 0,
        label: {
          borderColor: '#775DD0',
          offsetY: 0,
          style: {
            color: '#fff',
            background: '#775DD0',
          },
          text: 'Bananas are good',
        }
      }]
    },
    chart: {
      height: 350,
      type: 'bar',
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 1,
      colors: '#e16767',
    },
    grid: {
      row: {
        colors: ['#fff']
      }
    },
    xaxis: {
      labels: {
        rotate: -45
      },
      categories: graphAbest ? graphAbest.map((item) => item.ABS_DATE) : [],
      title: {
        text: 'Date',
      },
    },
    yaxis: {
      title: {
        text: 'Employee',
      },
    },
    fill: {
      colors: ['#a7b610'],
    }
  };

  return (
    <div className="col-lg-6">
      <h3 className="text-primary">Late Comer Employee</h3>
      <div className="au-card recent-report">
        <div className="au-card-inner">
          <div className="d-flex align-items-center justify-content-between">
            <div className="form-group">
              <select className="form-control" value={graphType} onChange={(e) => handleGraphTypeChange(e.target.value)}>
                <option value="LastMonth">Previous Month</option>
                <option value="LateCorner15Days">This Month (15 Days)</option>
                <option value="Last1Month">This Month</option>
              </select>
            </div>
            <div className="form-group">
              <button type="button" className="btn btn-primary ml-2" onClick={() => navigate('/Dashboard/DisplayData', { state: { Title: graphType } })}>View</button>
            </div>
          </div>
          <div className="recent-report__chart">
            <Chart options={options} series={options.series} type="bar" width="100%" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Latecomer15Days;
