import React, { useEffect, useState } from 'react'
import "./Reimbursement.css";
import { Dialog } from 'primereact/dialog';
import api from '../../Authenticaltion/api';
import { toast } from 'react-toastify';
const AdminReimbursement = () => {
    const [Userreimbursement, setUserreimbursement] = useState([])
    const [ViewDialog, setViewDialog] = useState(false);
    const [SelectedReimbursement, SetSelectedReimbursement] = useState(null);
    const [selectedAction, setSelectedAction] = useState('');
    const [dialogInput, setDialogInput] = useState("");
    const closeDialog = () => {
        setViewDialog(false);
        setDialogInput('');
        setSelectedAction(''); // Reset the action when closing the dialog
    };

    const viewreiumberseDialog = (select) => {
        console.log("Calling the fun");
        setViewDialog(true);
        SetSelectedReimbursement(select);
    };
    const CloseDialog = () => {
        setViewDialog(false);
        setDialogInput("");
    }

    const viewreiumbersements = async () => {
        console.log("Calling the function");
        try {
            const response = await api.get('/getReimbursementForEmployee');
            console.log("My_Rem", response);
            setUserreimbursement(response.data.reimbursements);
        } catch (error) {
            console.error("Unable to fetch Reimbursement data", error);
        }
    }
    const handleReimbursement = (action) => {
        if (action === 'Approved' || action === 'DisApproved') {
            setSelectedAction(action);
        }
    };
    const handleDialogSubmit = async () => {
        setViewDialog(false);

        const formReimbursementData = {
            message: dialogInput,
            status: selectedAction
        };

        try {
            const response = await api.post(`/AssignedOperation/${SelectedReimbursement?.reimbursement_id}`, formReimbursementData);
            toast.success(response.data.message);
            viewreiumbersements();
            closeDialog();
        } catch (error) {
            console.error(error);
        }

        setDialogInput("");
    };
    useEffect(() => {
        viewreiumbersements();
    }, [])

    return (
        <div class=" shadow p-3 bg-white rounded">
            <div class="h5 pb-2 mt-2 mb-2 border-bottom text-primary">
                Reimbursement
            </div>
            <div className="row mt-4">
                {Userreimbursement?.map((Rem_data) => (
                    <div key={Rem_data.id} className="col-xl-3 col-sm-6 mb-4">
                        <div className='card reimbursement-item p-2 leave-item' style={{
                            backgroundColor: '#D1CFCF',
                            cursor: 'pointer',
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            transition: 'transform 0.3s, box-shadow 0.3s',
                            animation: 'fadeIn 1s',
                            borderRadius: '10px',
                            animationName: 'fadeIn',
                            animationDuration: '1s'
                        }} onClick={() => viewreiumberseDialog(Rem_data)}>
                            <div className='text-center' style={{ backgroundColor: "#ffffff", borderRadius: "5px" }}>
                                <div className='reimbursement_Type p-2' style={{ color: '#2643FF', fontSize: '13px', fontWeight: "700" }}>
                                    {Rem_data.typeName}
                                </div>
                            </div>
                            <div className='row mt-3 flex-grow-1'>
                                <div className='col'>
                                    <div className='reimbursement_Amount'>
                                        <i className="fa fa-rupee" />
                                        <span className='ml-1' style={{ fontSize: "12px", fontWeight: "700" }}>
                                            Amount Spent: <span style={{ color: "red", fontSize: "16px" }}>
                                                {Rem_data.amount}
                                            </span> Rs.
                                        </span>
                                    </div>
                                    <div className='reimbursement_info' style={{ fontSize: "12px" }}>
                                        <i className="fa fa-user" /> {Rem_data.userApplied}
                                    </div>
                                    <div className='reimbursement_info mt-2' style={{ fontSize: "12px" }}>
                                        <i className="fa fa-calendar" />
                                        <span className='ml-1'>Spent on: {Rem_data.date_spent}</span>
                                    </div>
                                    <div className='reimbursement_info' style={{ fontSize: "12px" }}>
                                        <i className="fa fa-calendar" />
                                        <span className='ml-1'>Applied on: {Rem_data.Applied_date}</span>
                                    </div>
                                </div>
                                <div className='icon-container justify-content-end' style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
                                    <i className='fas fa-rupee' style={{ fontSize: '4rem', opacity: '0.2' }}></i>
                                </div>
                            </div>

                            <div className={`text-center mt-3 pt-1 pb-1 ${Rem_data.final_status === "Pending" ? "bg-warning" : Rem_data.final_status === "Approved" ? "bg-success" : "bg-danger"}`} style={{ borderRadius: "10px" }}>
                                <span className='Reimbursement_status text-white'>
                                    {Rem_data.final_status}
                                </span>
                            </div>
                        </div>
                    </div>
                ))}
                {Userreimbursement?.length === 0 && (
                    <div className="col-12">
                        <p className="text-center">Their is no applied reimbursement</p>
                    </div>
                )}
            </div>

            <Dialog className="dialogbox " showHeader={false} visible={ViewDialog} style={{ width: '50vw', borderRadius: "10px" }}>
                <div className='card chat w-100 ' style={{ padding: '0', margin: '0' }} >
                    <div className='card-header' style={{ backgroundColor: '#5B8FFF' }}>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='col text-center text-white'>
                                <span className='h-100 fw-bold' style={{ fontSize: '20px' }}>{SelectedReimbursement?.typeName}
                                </span>
                            </div>
                            <div className='col-auto'>
                                <i className="fa fa-times" onClick={CloseDialog}></i>
                            </div>
                        </div>
                    </div>
                    <div className='card-body max-w-60' style={{ backgroundColor: '#D7D9DE' }}>
                        <div className='row'>
                            <div style={{ color: '#021317', fontWeight: "700" }}>
                                <i className="fa fa-rupee" />{' '}
                                <span className='ml-2' style={{ fontSize: "14px", fontWeight: "700" }}>
                                    Amount Spent: <span style={{ color: "red", fontSize: "18px" }}>{SelectedReimbursement?.amount}</span> Rs.
                                </span>
                            </div>
                            <div style={{ color: '#021317', fontWeight: "700" }} >
                                <i className='fas fa-calendar-check'></i>
                                <span className='ml-2'>
                                    Status:
                                    <span style={{ fontSize: "16px", fontWeight: "700" }} className={`status-text ml-2 ${SelectedReimbursement?.final_status === 'Approved' ? 'text-success' :
                                        SelectedReimbursement?.final_status === 'Rejected' ? 'text-danger' :
                                            'text-warning'
                                        }`}>
                                        {SelectedReimbursement?.final_status}
                                    </span>
                                </span>
                            </div>
                            <div className='mt-2'>
                                <div style={{ color: '#021317', fontWeight: "700" }}>
                                    <i className='fa fa-calendar' />
                                    <h6 className="d-inline ml-2" style={{ fontSize: "14px" }}>
                                        Spent on: {SelectedReimbursement?.date_spent}
                                    </h6>
                                </div>
                                <div style={{ color: '#021317', fontWeight: "700" }}>
                                    <i className='fa fa-calendar' />
                                    <h6 className="d-inline ml-2" style={{ fontSize: "14px" }}>
                                        Applied on: {SelectedReimbursement?.Applied_date}
                                    </h6>
                                </div>
                                <div style={{ color: '#021317', fontWeight: "700" }}>
                                    <i className="fa fa-file" />
                                    <h6 className="d-inline ml-2" style={{ fontSize: "14px" }}>
                                        Document: {SelectedReimbursement?.file_path ? (
                                            <a
                                                href={SelectedReimbursement.file_path}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.open(SelectedReimbursement.file_path, '_blank');
                                                }}
                                                role="button"
                                                target="_blank"
                                                aria-label={`Download ${SelectedReimbursement.original_filename}`}
                                                style={{ color: '#6366F1', fontWeight: 'bold' }}
                                            >
                                                {SelectedReimbursement.original_filename}
                                                <i className="fa fa-download ml-1"></i>
                                            </a>
                                        ) : (
                                            'Not provided'
                                        )}
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h5 className="mt-4 ml-2 " style={{ color: '#021317', fontWeight: "700" }}>Apply History</h5>
                        </div>
                        <div class=" card chat-history mt-2" style={{ backgroundColor: '#F9FCFF' }}>
                            <div>
                                <ul className="list-unstyled">
                                    <li>
                                        <div className="d-flex flex-column mt-3">
                                            <div className="d-flex align-items-center ml-3" style={{ fontSize: "12px", color: "#6366F1" }}>
                                                <strong> {SelectedReimbursement?.statusHistory[0].updated_by}</strong>
                                                <div className="message-data-time ml-2">
                                                    {SelectedReimbursement?.statusHistory[0].date_changed}
                                                </div>
                                            </div>
                                            <div className="d-flex mt-1 ml-2">
                                                <div>
                                                    <img
                                                        src={SelectedReimbursement?.statusHistory[0].user_image || `/images/icon/Noprofile.webp`}
                                                        className="rounded-circle mr-2"
                                                        style={{ width: "50px", height: "50px", objectFit: "cover" }}
                                                        alt="avatar"
                                                    />
                                                </div>
                                                <div
                                                    className="card message other-message p-2 ml-2 mr-4"
                                                    style={{ backgroundColor: "#A4A6A8", color: "#000000" }}
                                                    dangerouslySetInnerHTML={{ __html: SelectedReimbursement?.statusHistory[0].message }}
                                                />
                                            </div>
                                        </div>
                                    </li>
                                    {SelectedReimbursement?.statusHistory.map((historyItem, index) => (
                                        index >= 1 && (
                                            <li className="clearfix mt-2 d-flex justify-content-end ml-auto mr-3" key={index}>
                                                <div className="d-flex flex-column align-items-end mt-3">
                                                    <div className="d-flex align-items-center mr-3" style={{ fontSize: "12px", color: "#6366F1" }}>
                                                        <div className="message-data-time mr-2">
                                                            {historyItem.date_changed}
                                                        </div>
                                                        <strong>{historyItem.updated_by}</strong>
                                                    </div>
                                                    <div className="d-flex mt-1">
                                                        <div
                                                            className="card message other-message p-2"
                                                            style={{ backgroundColor: "#A4A6A8", color: "#000000" }}
                                                            dangerouslySetInnerHTML={{ __html: historyItem.message }}
                                                        >{historyItem.statusReson}
                                                        </div>
                                                        <div>
                                                            <img
                                                                src={historyItem.user_image || `/images/icon/Noprofile.webp`}
                                                                className="rounded-circle ml-2"
                                                                style={{ width: "50px", height: "50px", objectFit: "cover" }}
                                                                alt="avatar"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    ))}
                                </ul>
                            </div>
                        </div>
                        {SelectedReimbursement?.final_status === "Pending" && (
                            <div>
                                <div className="d-flex justify-content-end">
                                    <button type="button" className="btn btn-success me-2" onClick={() => handleReimbursement('Approved')}>Approve</button>
                                    <button type="button" className="btn btn-danger" onClick={() => handleReimbursement('DisApproved')}>Reject</button>
                                </div>
                                {selectedAction && (
                                    <div className="card mt-2 p-2">
                                        <textarea
                                            className="form-control mt-2"
                                            value={dialogInput}
                                            placeholder="Enter Message Here"
                                            onChange={(e) => setDialogInput(e.target.value)}
                                            style={{ height: '100px' }}
                                        />
                                        <div className="d-flex justify-content-center mt-2">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={handleDialogSubmit}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div >
            </Dialog >

        </div >
    )
}

export default AdminReimbursement
