import React, { useEffect, useState, } from 'react';
import { useLoading } from '../../LoadingContext';
import { Dialog } from 'primereact/dialog';
import { toast } from 'react-toastify';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import './ManageHolidays.css';
import api from '../../Authenticaltion/api';


const { SearchBar } = Search;

const ManageHolidays = () => {
  const [file, setFile] = useState(null);
  const { updateLoading } = useLoading();
  const [uploadFile, setUploadFile] = useState(null);

  const [isDialogVisible, setDialogVisible] = useState(false);
  const [isEditDialogVisible, setEditDialogVisible] = useState(false);
  const [UploadDialogVisible, setUploadDialogVisible] = useState(false)
  // const [editingHoliday, setEditingHoliday] = useState(null);
  const [holidayName, setHolidayName] = useState('');
  const [holidayDate, setHolidayDate] = useState('');
  const [editingHoliday, setEditingHoliday] = useState({
    Holiday_Name: '',
    StartDate: '',
  });
  const [holidays, setHolidays] = useState([]);

  const openDialog = () => {
    setDialogVisible(true);
  };
  const handleHolidaysDataUpload = () => {
    if (!uploadFile) {
      toast.error('Please upload a file.');
      return;
    }

    updateLoading(true);

    if (uploadFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      console.log(uploadFile)
      const formData = new FormData();
      formData.append('file', uploadFile);

      api.post('/upload_holidays', formData)
        .then((response) => {
          if (response.status === 200) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.error);
          }
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.error || 'An unexpected error occurred';
          toast.error(errorMessage);
        })
        .finally(() => {
          updateLoading(false);
          setUploadFile(null);
          setUploadDialogVisible(false)
          fetchCompanyLeaves()
        });
    } else {
      updateLoading(false);
      toast.error('Invalid file format. Only Excel files (XLSX) are supported.');
    }
  };
  const closeDialog = () => {
    setDialogVisible(false);
    // Optionally, you can reset the form fields here
    setHolidayName('');
    setHolidayDate('');
  };

  const columns = [
    {
      dataField: 'Holidays_id',
      text: 'ID',
      hidden: true,
    },
    {
      dataField: 'Sl_No',
      text: 'Sl. No.',
    },
    {
      dataField: 'Holiday_Name',
      text: 'Holiday Name',
    },
    {
      dataField: 'StartDate',
      text: 'Start Date',
    },
    {
      dataField: 'End_Date',
      text: 'End Date',
    },
    {
      dataField: 'Actions',
      text: 'Actions',
      formatter: (cell, row) => (
        <>
          {/* Add buttons for editing and deleting */}
          {/* Uncomment the line below when handleEditHolidayModal is implemented */}
          <button className="btn btn-warning btn-sm mr-2" onClick={() => handleEditHolidayModal(row)} > <i className="fas fa-pencil-alt"></i></button>
          <button className="btn btn-danger btn-sm" onClick={() => handleDeleteHolidayModal(row)}>
            <i className="fa fa-trash"></i>
          </button>
        </>
      ),
    },
  ];
  const formatNewData = (dateString) => {
    const parts = dateString.split('-');
    if (parts.length === 3) {
      const [day, month, year] = parts;
      return `${year}-${month}-${day}`;
    }
    return dateString; // Return as is if unable to parse
  };
  const handleEditHolidayModal = (row) => {
    const formattedStartDate = formatNewData(row.StartDate);

    setEditingHoliday({
      ...row,
      StartDate: formattedStartDate,
    });

    setEditDialogVisible(true);
  };

  const handleDeleteHolidayModal = (row) => {
    console.log("delete modal call", row)
    setEditingHoliday(row);
    confirmDialog({
      message: (
        <div>
          <i
            className="fa fa-exclamation-triangle"
            style={{ fontSize: '2em', marginRight: '1rem', color: '#578ad6' }}
          ></i>
          Are you sure you want to delete {row.Holiday_Name}?
        </div>
      ),
      header: 'Confirmation',
      icon: 'pi pi-check',
      accept: () => handleDeleteHoliday(row),
    });
  };

  const handleDeleteHoliday = async (row) => {
    try {

      // Perform the logic to delete the holiday, e.g., make an API cal
      const deleteResult = await api.delete(`/DeleteHolidays/${row.Holidays_id}`);
      if (deleteResult.status === 200) {
        toast.success('Holiday Deleted Successfully');
        fetchCompanyLeaves();

      } else {
        toast.error(deleteResult.data);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setEditingHoliday(null);
    }
  };

  const fetchCompanyLeaves = async () => {
    updateLoading(true);
    try {
      const holidaysData = await api.get('/CompanyHolidays');
      setHolidays(holidaysData.data);
    } catch (error) {
      toast.error(error);
    } finally {
      updateLoading(false);
    }
  };

  const handleAddHoliday = async () => {
    // Perform the logic to add the holiday, e.g., make an API call
    const newHoliday = { name: holidayName, date: holidayDate };
    if (!holidayName || !holidayDate) {
      toast.warning('Please enter holiday name and date.');
      return;
    }

    try {
      const insertResult = await api.post('/InsertHolidays', {
        Holiday_Name: holidayName,
        StartDate: holidayDate,
        End_Date: holidayDate,
      });

      if (insertResult.status === 200) {
        fetchCompanyLeaves();
        toast.success('Holidays Added Successfully');
      } else {
        toast.error(insertResult.data);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'An unexpected error occurred';
      toast.error(errorMessage);
    } finally {
      closeDialog();
    }
  };
  const formatDateForInput = (dateString) => {
    const date = new Date(dateString);
    if (!isNaN(date.getTime())) {
      return date.toISOString().split('T')[0]; // Format as "yyyy-MM-dd"
    } else {
      return dateString;
    }
  };
  const setEditedHolidaysData = async () => {
    console.log("Prinitnfg edited adta", editingHoliday)
    try {
      const updateResult = await api.put('/updateHolidays', editingHoliday)
      if (updateResult.status === 200) {
        setEditDialogVisible(false)
        fetchCompanyLeaves();
      }
      else {
        toast.error(updateResult.data)
        setEditDialogVisible(false)
      }
    } catch (error) {
      toast.error(error)
      setEditDialogVisible(false)
    }
  }

  useEffect(() => {
    fetchCompanyLeaves();
  }, []);
  const handleDateChange = (e) => {
    console.log('New Date:', e.target.value);
    setEditingHoliday((prevEditingHoliday) => ({ ...prevEditingHoliday, StartDate: e.target.value }));
  };
  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="btn-group" role="group">
      {options.map((option) => {
        const isSelect = currSizePerPage === `${option.page}`;
        const buttonClass = isSelect ? 'btn' : 'btn btn-secondary';
        const buttonStyle = isSelect ? { backgroundColor: '#6366F1', color: '#fff' } : {};

        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={buttonClass}
            style={buttonStyle}
          >
            {option.text}
          </button>
        );
      })}
    </div>
  );

  return (
    <>

      <Dialog header="Add Holiday" visible={isDialogVisible} onHide={closeDialog} className="custom-Holidays-dialog">
        <form>
          <div className="p-fluid">
            <div className="p-field">
              <label htmlFor="holidayName">
                Holiday Name<span style={{ color: 'red' }}>*</span>
              </label>
              <input id="holidayName" type="text" value={holidayName} onChange={(e) => setHolidayName(e.target.value)} />
            </div>
            <div className="p-field">
              <label htmlFor="holidayDate">
                Holiday Date
                <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                id="holidayDate"
                type="date"
                value={holidayDate}
                onChange={(e) => setHolidayDate(e.target.value)}
              />
            </div>
          </div>

          <div className="p-mt-2">
            <button type="button" className="p-button p-button-primary" onClick={handleAddHoliday}>
              Add Holiday
            </button>
            <button type="button" className="p-button p-button-danger p-ml-2" onClick={closeDialog}>
              Cancel
            </button>
          </div>
        </form>
      </Dialog>

      {/* Display added holidays in a table */}
      <div class=" shadow p-3 bg-white rounded">
        <div class="h5 pb-2 mt-2 mb-2 border-bottom text-primary">
          Manage Holidays
        </div>
        <div className="col  text-end" style={{ paddingBottom: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <button
            className="btn btn-primary"
            style={{ backgroundColor: 'rgb(99, 102, 241)', color: 'white' }}
            onClick={openDialog}
          >
            Add Holidays
          </button>
          <button className="btn btn-secondary ml-2"
            // onClick={() => fileInputRef.current.click()}
            onClick={() => setUploadDialogVisible(true)}
          >
            Upload Holidays Data
          </button>
        </div>
        {holidays.length === 0 ? (
          <div style={{ textAlign: 'center', marginTop: '20px', color: '#555' }}>
            <i className="fas fa-calendar-times" style={{ fontSize: '40px', marginBottom: '10px' }}></i>
            <p style={{ fontSize: '20px', fontWeight: 'bold', margin: '0' }}>No holidays available.</p>
          </div>


        ) : (
          <div className="p-grid ">
            <ToolkitProvider
              keyField="Sl_No"
              data={holidays}
              columns={columns}
              search
            >
              {(props) => (
                <div>
                  <SearchBar {...props.searchProps} />
                  <div className="table-responsive overflow-hidden">
                    <BootstrapTable
                      className="pagination"
                      {...props.baseProps}
                      condensed
                      hover
                      responsive
                      pagination={paginationFactory({
                        sizePerPageRenderer,
                        paginationSize: 4,
                        pageStartIndex: 1,
                        firstPageText: 'First',
                        prePageText: 'Prev',
                        nextPageText: 'Next',
                        lastPageText: 'Last',
                        hideSizePerPage: true,
                        showTotal: true,
                        sizePerPageList: [{
                          text: '10', value: 10
                        }, {
                          text: '20', value: 20
                        }, {
                          text: '50', value: 50
                        }, {
                          text: 'All', value: holidays.length
                        }],
                        paginationTotalRenderer: (from, to, size) => (
                          <span className="react-bootstrap-table-pagination-total">
                            Showing {from} to {to} of {size} Results
                          </span>
                        ),
                        classes: 'b-pagination-nav'
                      })}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
        )}
      </div>
      <Dialog header="Edit Holiday" visible={isEditDialogVisible} onHide={() => setEditDialogVisible(false)} className="custom-Holidays-dialog">
        <form>
          <div className="p-fluid">
            <div className="p-field">
              <label htmlFor="editedHolidayName">
                Holiday Name<span style={{ color: 'red' }}>*</span>
              </label>
              <input id="editedHolidayName" type="text" value={editingHoliday ? editingHoliday.Holiday_Name : 'nulll'} onChange={(e) => setEditingHoliday({ ...editingHoliday, Holiday_Name: e.target.value })} />
            </div>
            <div className="p-field">
              <label htmlFor="editedStartDate">
                Start Date
                <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                id="editedStartDate"
                type="date"
                value={editingHoliday ? formatDateForInput(editingHoliday.StartDate) : ''}
                onChange={handleDateChange}
              />

            </div>
          </div>

          <div className="p-mt-2">
            <button type="button" className="p-button p-button-primary" onClick={() => {
              setEditedHolidaysData()
            }}>
              Save Changes
            </button>
            <button type="button" className="p-button p-button-danger p-ml-2" onClick={() => setEditDialogVisible(false)}>
              Cancel
            </button>
          </div>
        </form>
      </Dialog>
      <Dialog header="Holidays" visible={UploadDialogVisible} onHide={() => setUploadDialogVisible(false)} className="custom-Holidays-dialog">
        <div className="text-center ">
          <p className="mb-4">
            Download the Holidays Excel Format:
            <a
              href="/Template/holidays_format.xlsx"
              download
              className="text-primary ml-2"
              onClick={() => toast.info('Downloading Excel format...')}
            >
              Click here
            </a>
          </p>
          <div className="mb-4 pl-5 pr-5">
            <input
              type="file"
              className="form-control"
              accept=".xlsx"
              onChange={(e) => {
                setUploadFile(e.target.files[0]);
              }}
              style={{ padding: '10px' }}
            />
          </div>
          <button
            className="btn btn-primary"
            onClick={() => handleHolidaysDataUpload()}
          >
            Upload
          </button>
        </div>
      </Dialog>

    </>
  );
};

export default ManageHolidays;
