import React, { useState, useEffect } from 'react'
import LoaderComponenet from '../../LoaderComponenet'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import api from '../../Authenticaltion/api';
const UserTimeTracker = () => {
  const [useTimeTracker, SetUserTimeTracker] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [filterData, setFilterData] = useState({
    intime: '',
    startDate: '',
    endDate: '',
  });
  useEffect(() => {
    fetchuserTimeTracker();
  }, []);
  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="btn-group" role="group">
      {options.map((option) => {
        const isSelect = currSizePerPage === `${option.page}`;
        const buttonClass = isSelect ? 'btn' : 'btn btn-secondary';
        const buttonStyle = isSelect ? { backgroundColor: '#6366F1', color: '#fff' } : {};

        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={buttonClass}
            style={buttonStyle}
          >
            {option.text}
          </button>
        );
      })}
    </div>
  );
  const columns = [
    // { dataField: 'sl_no', text: 'Serial No', style: { width: '10%', overflow: 'hidden', textOverflow: 'ellipsis' }, },
    { dataField: 'FormattedDate', text: 'Date', style: { width: '10%', overflow: 'hidden', textOverflow: 'ellipsis' }, },
    { dataField: 'Emp_Id', text: 'Employee ID', style: { width: '10%', overflow: 'hidden', textOverflow: 'ellipsis' }, },
    { dataField: 'Name', text: 'Name', style: { width: '20%', overflow: 'hidden', textOverflow: 'ellipsis' }, },
    { dataField: 'InTime', text: 'In Time', style: { width: '10%', overflow: 'hidden', textOverflow: 'ellipsis' } },
    { dataField: 'OutTime', text: 'Out Time', style: { width: '10%', overflow: 'hidden', textOverflow: 'ellipsis' } },
  ];
  const fetchuserTimeTracker = () => {
    setIsloading(true);
    api.get('/userTimeTracker')
      .then(res => {
        const DataName = res.data;
        SetUserTimeTracker(DataName);
      });
    setIsloading(false)
  }
  const handleFilter = () => {
    const filteredData = { ...filterData };
    setIsloading(true);
    if (filteredData.intime) {
      const intimeDate = new Date(filteredData.intime);
      const formattedIntime = `${intimeDate.getHours()}:${intimeDate.getMinutes()}:${intimeDate.getSeconds()}`;
      filteredData.intime = formattedIntime;
    }
    api
      .get('/userTimeTracker', {
        params: filteredData,
      })
      .then((res) => {
        console.log(res.data)
        SetUserTimeTracker(res.data);
        setFilterData({
          intime: '',
          startDate: '',
          endDate: '',
        })
        setIsloading(false)
        // Handle the response data as needed
      })
      .catch((error) => {
        setIsloading(false)
      });

  }
  return (
    <div class=" shadow p-3 bg-white rounded">
      <div class="h5 pb-2 mt-2 mb-2 border-bottom text-primary">
        Time Tracker
      </div>
      <div className="row mb-2" >
        <div className="col-md-2 col-sm-6 mb-2">
          <Calendar
            value={filterData.startDate}
            onChange={(e) => setFilterData({ ...filterData, startDate: e.target.value })}
            style={{ width: '100%', height: '30px' }}
            placeholder="From Date"
            dateFormat="dd/mm/yy"
          />
        </div>
        <div className="col-md-2 col-sm-6 mb-2">
          <Calendar
            value={filterData.endDate}
            onChange={(e) => setFilterData({ ...filterData, endDate: e.target.value })}
            style={{ width: '100%', height: '30px', marginBottom: '2px' }}
            placeholder="To Date"
            minDate={filterData.startDate}
            dateFormat="dd/mm/yy"
          />
        </div>
        <div className="col-md-2 col-sm-6 mb-2">
          <Calendar
            value={filterData.intime}
            style={{ width: '100%', height: '30px' }}
            timeOnly={true}
            onChange={(e) => setFilterData({ ...filterData, intime: e.target.value })}
            placeholder="Time"
            showTime={true}
          />
        </div>
        <div className="col-md-2 col-sm-12 mb-2" >
          <button type="button" class="btn btn-light col-sm-12" style={{ borderRadius: "20px", color: 'blue' }} onClick={handleFilter} >
            <i class="fas fa-search"></i> Search
          </button>
          {/* <Button label="Search" onClick={handleFilter} style={{ width: '100%', height: '30px' }} /> */}
        </div>
      </div>
      {isLoading ? (<LoaderComponenet />) : (
        <BootstrapTable
          keyField="SlNo"
          data={useTimeTracker}
          columns={columns}
          responsive
          pagination={paginationFactory({
            sizePerPageRenderer
          })}
        />
      )}
    </div>
  )
}
export default UserTimeTracker
