import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast, ToastContainer } from 'react-toastify';
import api from '../../Authenticaltion/api';
import './FileUploadPage.css';
import { useLoading } from '../../LoadingContext';

const FileUploadPage = () => {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isAttendanceRuleFilled, setIsAttendanceRuleFilled] = useState(true);
  const [attendance_rule, setAttendaceRule] = useState(null);
  const [inTime, setInTime] = useState("Not Available");
  const [outTime, setOutTime] = useState("");
  const [lateExclusion, setLateExclusion] = useState("");
  const [workStartDate, setWorkStartDate] = useState("");
  const [workEndDate, setWorkEndDate] = useState("");
  const { updateLoading } = useLoading();
  const [lateSkip, SetLateSkip] = useState("");
  const [latestAttendanceData, setLatestAttendanceData] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      updateLoading(true);
      try {
        const attendanceRuleData = await api.get('/company_attendance_rule');
        const dataformated = attendanceRuleData.data[0];
        if (dataformated) {
          setAttendaceRule(dataformated.attendance_rules_id);
          setInTime(dataformated.in_time);
          setOutTime(dataformated.out_time);
          setLateExclusion(dataformated.InTimeThreshold);
          SetLateSkip(dataformated.late_tolerance);
          setWorkStartDate(dataformated.work_week_start);
          setWorkEndDate(dataformated.work_week_end);
        }
        const latestAttendanceResponse = await api.get('/LatestAttendanceDateData');
        setLatestAttendanceData(latestAttendanceResponse.data.MaxDateData);

        console.log(dataformated);
      } catch (error) {
        console.error(error);
      } finally {
        updateLoading(false);
      }
    };

    fetchData();
  }, []);

  const DayName = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      toast.error('Please upload a file.');
      return;
    }
    if (attendance_rule == null) {
      setIsAttendanceRuleFilled(false);
      toast.warning('Kindly fill Attendance Rule');
      return;
    }
    updateLoading(true);

    if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await api.post('/upload', formData);
        console.log(response);
        if (response.data.type === 'success') {
          toast.success(response.data.message);

          const latestAttendanceResponse = await api.get('/LatestAttendanceDateData');
          setLatestAttendanceData(latestAttendanceResponse.data.MaxDateData);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        updateLoading(false);
        setFile(null);
        fileInputRef.current.value = null;  // Clear the file input
      }
    } else {
      updateLoading(false);
      toast.error('Invalid file format. Only Excel files (XLSX) are supported.');
    }
  };

  return (
    <div className="shadow p-3 bg-white rounded">
      <div className="file-upload-page">
        <div className="row">
          <div className="col-md-12 mb-2">
            <div className="file-upload-info-card">
              <div className="h4 pb-2 mt-2 mb-2 border-bottom text-primary">
                File Upload Information
              </div>
              <p style={{ color: 'green' }}>
                Please upload an Excel file (.xlsx) containing attendance data. The file should include the following
                information: EMP ID, Name, DATE, MIN of TIME, MAX of TIME.
              </p>
              <p style={{ color: 'green' }}>
                <a href="/Template/attendance_format.xlsx" download style={{ textDecoration: 'none' }}>
                  Click here
                </a>
                {' '}to see the format of the file.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <ToastContainer />
              <div className="card-body">
                <h5 className="card-title mb-2">Attendance Upload</h5>
                {latestAttendanceData && (
                  <p style={{ color: 'green' }}>Updated upto: <strong>{latestAttendanceData}</strong></p>
                )}
                <div className="form-group">
                  <label htmlFor="file" className="file-upload-title">Select an XLSX file:</label>
                  <input id="file" className="form-control-file" type="file" onChange={handleFileChange} accept=".xlsx" style={{ padding: '0', border: 'none' }} ref={fileInputRef} />
                </div>
                <div className="text-center">
                  <button className="btn btn-primary" style={{ backgroundColor: '#6366f1', color: 'white' }} onClick={handleUpload}>Upload</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className={`card${!isAttendanceRuleFilled ? ' border-danger' : ''}`}>
              <div className="card-body">
                <h5 className="card-title mb-4">Attendance Rules</h5>
                <ul className="list-group">
                  <li className="list-group-item"><strong>In-Time:</strong> {inTime == null ? 'Not Available' : inTime}</li>
                  <li className="list-group-item"><strong>Out-Time:</strong> {outTime == null ? 'Not Available' : outTime}</li>
                  <li className="list-group-item"><strong>Late Exclusion:</strong> {lateExclusion == null ? 'Not Available' : lateExclusion}</li>
                  <li className="list-group-item"><strong>Total Days Late Excluded Each Month:</strong> {lateSkip == null ? 'Not Available' : lateSkip}</li>
                  <li className="list-group-item"><strong>Work Start Day:</strong> {workStartDate == null ? 'Not Available' : DayName[workStartDate - 1]}</li>
                  <li className="list-group-item"><strong>Work End Day:</strong> {workEndDate == null ? 'Not Available' : DayName[workEndDate - 1]}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileUploadPage;
