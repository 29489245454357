import React, { useState, useEffect } from 'react';
import api from '../../Authenticaltion/api';
import { useLoading } from '../../LoadingContext';
import './TimeTracker.css';
import { InputText } from 'primereact/inputtext';

import { InputNumber } from 'primereact/inputnumber';

import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import ExcelJS from 'exceljs';



const TimeTracker = () => {
  const [dataList, setDataList] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  });
  const [appliedFilters, setAppliedFilters] = useState({});
  const [showFilterForm, setShowFilterForm] = useState(false);
  const [searchOptions, setSearchOptions] = useState({
    empId: '',
    name: '',
    nameType: '',
    inTime: '',
    inTimeComparison: '',
    outTime: '',
    outTimeComparison: '',
  });
  const { updateLoading } = useLoading();

  useEffect(() => {
    fetchData();
  }, [pagination.page, pagination.sizePerPage]);


  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);  // Set to 30 days ago

  const [filterData, setFilterData] = useState({
    startDate: thirtyDaysAgo,
    endDate: new Date(),  // Set initial value to today
  });
  const formatDateForBackend = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };
  const clearAllFilters = async () => {

    setSearchOptions({
      empId: '',
      name: '',
      nameType: '',
      inTime: '',
      inTimeComparison: '',
      outTime: '',
      outTimeComparison: '',
    });
    setTempSearchOptions({
      empId: '',
      name: '',
      nameType: '',
      inTime: '',
      inTimeComparison: '',
      outTime: '',
      outTimeComparison: '',
    });

    fetchData();
  };



  const fetchData = async () => {
    console.log('fetch data function calll', searchOptions);
    try {
      updateLoading(true);
      setShowFilterForm(false)
      console.log("Prinitng filyer date", filterData.startDate);
      console.log('end date', filterData.endDate)
      const res = await api.get('/TimeTracker', {
        params: {
          page: pagination.page,
          pageSize: pagination.sizePerPage,
          startDate: formatDateForBackend(filterData.startDate),
          endDate: formatDateForBackend(filterData.endDate),
          ...getFilterParams(),
        },
      });
      const { data, totalCount } = res.data;
      setDataList(data);
      setPagination((prev) => ({
        ...prev,
        totalSize: totalCount,
      }));
      setAppliedFilters(getFilterParams());
    } catch (error) {
      // Handle errors
    } finally {

      updateLoading(false);

    }
  };
  const getFilterParams = () => {
    const filterParams = {};

    if (searchOptions.empId) {
      filterParams.empId = searchOptions.empId;
    }
    if (searchOptions.nameType) {
      filterParams.nameType = searchOptions.nameType;
    }
    if (searchOptions.name) {
      filterParams.name = searchOptions.name;
    }

    if (searchOptions.inTimeComparison && searchOptions.inTime) {
      filterParams.inTimeComparison = searchOptions.inTimeComparison;

      // Format inTime to include only hours and minutes
      const inTime = new Date(searchOptions.inTime);
      const formattedInTime = `${String(inTime.getHours()).padStart(2, '0')}:${String(inTime.getMinutes()).padStart(2, '0')}:00`;
      filterParams.inTime = formattedInTime;
    }

    if (searchOptions.outTimeComparison && searchOptions.outTime) {
      filterParams.outTimeComparison = searchOptions.outTimeComparison;

      // Format outTime to include only hours and minutes
      const outTime = new Date(searchOptions.outTime);
      const formattedOutTime = `${String(outTime.getHours()).padStart(2, '0')}:${String(outTime.getMinutes()).padStart(2, '0')}:00`;
      filterParams.outTime = formattedOutTime;
    }

    return filterParams;
  };


  const exportCurrentPageData = async () => {
    try {
      updateLoading(true);
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('TimeTracker');
      const modifiedHeader = ['Date', 'Employee ID', 'Name', 'In Time', 'Out Time'];
      worksheet.addRow(modifiedHeader);
      dataList.forEach((item) => {
        const row = [
          item.Date,
          item.Emp_Id,
          item.Name,
          item.InTime,
          item.OutTime,
        ];
        worksheet.addRow(row);
      });
      const blob = await workbook.xlsx.writeBuffer();
      const blobUrl = URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = 'TimeTracker.xlsx';
      link.click();

    }
    catch (error) {
      console.log(error)

    }
    finally {
      updateLoading(false);
    }

  }
  const exportAllPageData = async () => {
    try {
      updateLoading(true);
      const res = await api.get('/TimeTracker/exportAll', {
        params: {
          startDate: formatDateForBackend(filterData.startDate),
          endDate: formatDateForBackend(filterData.endDate),
          ...getFilterParams(),
        }

      });
      const { data } = res.data
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('TimeTracker');
      const modifiedHeader = ['Date', 'Employee ID', 'Name', 'In Time', 'Out Time'];
      worksheet.addRow(modifiedHeader);
      data.forEach((item) => {
        const row = [
          item.Date,
          item.Emp_Id,
          item.Name,
          item.InTime,
          item.OutTime,
        ];
        worksheet.addRow(row);
      });
      const blob = await workbook.xlsx.writeBuffer();
      const blobUrl = URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = 'TimeTracker.xlsx';
      link.click();

    }
    catch (error) {
      console.log(error)

    }
    finally {
      updateLoading(false);
    }

  }
  const handlePageChange = (newPage) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handleSizePerPageChange = (pageSize) => {
    setPagination({ page: 1, sizePerPage: pageSize, totalSize: pagination.totalSize });
  };
  const handleFilterIconClick = () => {
    // Toggle visibility of filter form
    setShowFilterForm((prev) => !prev);
  };
  const [tempSearchOptions, setTempSearchOptions] = useState({ ...searchOptions });
  const applyFiltersAndFetchData = async () => {
    try {
      await setAsyncValues();
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [searchOptions]);

  const setAsyncValues = async () => {
    setSearchOptions({ ...tempSearchOptions });
  };

  return (
    <>
      <div class=" shadow p-3 bg-white rounded">
        <div className="d-flex h5 pb-2 mt-2 mb-2 border-bottom text-primary">
          Time Tracker
          <div className="ml-auto">
            <button className="btn btn-sm btn-success" style={{ backgroundColor: '#ffffff', color: 'black', marginRight: '5px' }} onClick={exportCurrentPageData}>
              <i className="fas fa-download"></i> Export Current Page
            </button>
            <button className="btn btn-sm btn-success" style={{ backgroundColor: '#ffffff', color: 'black' }} onClick={exportAllPageData}>
              <i className="fas fa-download"></i> Export All Data
            </button>
          </div>
        </div>
        <div className="row mb-3">
          {/* Date Filters */}
          <div className="col-md-2 col-sm-6 mb-2">
            <Calendar
              value={filterData.startDate}
              onChange={(e) => setFilterData({ ...filterData, startDate: e.target.value })}
              style={{ width: '100%', height: '30px' }}
              placeholder="From Date"
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="col-md-2 col-sm-6 mb-2">
            <Calendar
              value={filterData.endDate}
              onChange={(e) => setFilterData({ ...filterData, endDate: e.target.value })}
              style={{ width: '100%', height: '30px' }}
              minDate={filterData.startDate}
              placeholder="To Date"
              dateFormat="dd/mm/yy"
            />
          </div>

          {/* Search Button */}
          <div className="col-md-2 col-sm-12 mb-2">
            <button type="button" className="btn btn-light w-100" style={{ borderRadius: "20px", color: 'blue' }} onClick={fetchData}>
              <i className="fas fa-search"></i> Search
            </button>
          </div>

          {/* Export Buttons */}
          <div className="col-md-4 col-sm-12 d-flex justify-content-between mb-2">

          </div>

          {/* Filter Button */}
          <div className="col-md-2 col-sm-12 text-end mb-2">
            <button className="btn btn-sm" style={{ backgroundColor: '#6366F1', color: 'white', width: '100px' }} onClick={handleFilterIconClick}>
              <i className="fas fa-filter"></i> Filters
            </button>
          </div>
        </div>

        <div className="row h-1">
          {showFilterForm && (
            <div className="filter-formdd">
              <div className="close-icon-container">
                <i className="fas fa-times" style={{ cursor: 'pointer' }} onClick={handleFilterIconClick}></i>
              </div>
              <label>Emp Id</label>
              <InputNumber
                placeholder="Employee ID"
                value={tempSearchOptions.empId != 0 ? tempSearchOptions.empId : null}
                onChange={(e) => setTempSearchOptions((prev) => ({ ...prev, empId: e.value }))}
                style={{ display: 'flex' }}
                min={0}
              />
              <label>Name</label>
              <select
                value={tempSearchOptions.nameType}
                onChange={(e) => {
                  const selectedType = e.target.value;
                  setTempSearchOptions({
                    ...tempSearchOptions,
                    nameType: selectedType,
                    name: selectedType ? tempSearchOptions.name : '',
                  });
                }}
              >
                <option value="">Select Option</option>
                <option value="startwith">Starts With</option>
                <option value="endwith">Ends With</option>
              </select>
              <InputText
                placeholder="Name"
                value={tempSearchOptions.name}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
                  setTempSearchOptions({ ...tempSearchOptions, name: sanitizedValue });
                }}
                disabled={!tempSearchOptions.nameType}
                className={!tempSearchOptions.nameType ? 'disabled' : ''}
              />
              <label>In Time</label>
              <select
                value={tempSearchOptions.inTimeComparison}
                onChange={(e) => {
                  const selectedComparison = e.target.value;
                  setTempSearchOptions({
                    ...tempSearchOptions,
                    inTimeComparison: selectedComparison,
                    inTime: selectedComparison ? tempSearchOptions.inTime : '',
                  });
                }}
              >
                <option value="">Select Comparison</option>
                <option value="greater">Greater Than</option>
                <option value="less">Less Than</option>
                <option value="equal">Equal To</option>
              </select>
              <Calendar
                value={tempSearchOptions.inTime}
                style={{ width: '100%', height: '30px', paddingBottom: '5px', marginBottom: '10px' }}
                timeOnly={true}
                onSelect={(e) => setTempSearchOptions({ ...tempSearchOptions, inTime: e.value })}
                placeholder="Time"
                showTime={true}
                disabled={!tempSearchOptions.inTimeComparison}
              />
              <label>Out Time</label>
              <select
                value={tempSearchOptions.outTimeComparison}
                onSelect={(e) => {
                  const selectedComparison = e.target.value;
                  setTempSearchOptions({
                    ...tempSearchOptions,
                    outTimeComparison: selectedComparison,
                    outTime: selectedComparison ? tempSearchOptions.outTime : '',
                  });
                }}
              >
                <option value="">Select Comparison</option>
                <option value="greater">Greater Than</option>
                <option value="less">Less Than</option>
                <option value="equal">Equal To</option>
              </select>
              <Calendar
                value={tempSearchOptions.outTime}
                style={{ width: '100%', height: '30px', paddingBottom: '5px', marginBottom: '15px' }}
                timeOnly={true}
                onSelect={(e) => setTempSearchOptions({ ...tempSearchOptions, outTime: e.target.value })}
                placeholder="Time"
                showTime={true}
                disabled={!tempSearchOptions.outTimeComparison}
              />
              <Button
                label="Apply Filters"
                onClick={
                  applyFiltersAndFetchData
                }
              />
              <button className="clear-filters-btn" onClick={clearAllFilters} style={{ textDecoration: 'none' }}>
                Clear Filters
              </button>
            </div>
          )}
        </div>
        {dataList.length === 0 ? (
          <div className="no-data-message">
            <p>No data available</p>
          </div>
        ) : (
          <>
            <table className="time-tracker-table">
              <thead>
                <tr>
                  <th>Date
                  </th>
                  <th>Employee ID
                  </th>
                  <th>Name
                  </th>
                  <th>In Time
                  </th>
                  <th>Out Time
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataList.map((item) => (
                  <tr key={item.sl_no}>
                    <td>{item.Date}</td>
                    <td>{item.Emp_Id}</td>
                    <td>{item.Name}</td>
                    <td>{item.InTime}</td>
                    <td>{item.OutTime}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="pagination-container pb-3">
              <div className="pagination-info">
                <span>Show </span>
                <select
                  className="pagination-select"
                  onChange={(e) => handleSizePerPageChange(Number(e.target.value))}
                  value={pagination.sizePerPage}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={50}>50</option>
                </select>
                <span> entries</span>
              </div>
              <div className="pagination-controls">
                <div className="pagination-buttons gap-2">
                  <button
                    className="btn btn-sm btn-primary"
                    style={{ backgroundColor: '#6366F1', color: 'white' }}
                    disabled={pagination.page === 1}
                    onClick={() => handlePageChange(pagination.page - 1)}
                  >
                    Previous
                  </button>
                  <button
                    className="btn btn-sm btn-primary"
                    style={{ backgroundColor: '#6366F1', color: 'white' }}
                    disabled={pagination.page === Math.ceil(pagination.totalSize / pagination.sizePerPage)}
                    onClick={() => handlePageChange(pagination.page + 1)}
                  >
                    Next
                  </button>
                </div>
                <span>
                  Page {pagination.page} of {Math.ceil(pagination.totalSize / pagination.sizePerPage)}
                </span>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TimeTracker;


