import React, { useState, useEffect } from 'react';
import './Leaves.css';
import { toast, ToastContainer } from 'react-toastify';
import api from '../../Authenticaltion/api'
import { Link } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { format } from 'date-fns';

const Leaves = () => {
  // ... other state variables ...
  const [isLoading, setIsLoading] = useState(false);
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState(null);
  const [showDocument, setShowDocument] = useState(false);
  const [userLeaves, setUserLeaves] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [page, setPage] = useState(1); // Start from page 1
  const pageSize = 50; // Default to 50 items per page
  const [hasMore, setHasMore] = useState(true); // To check if there's more data to fetch
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    leaveType: null,
    duration: null,
    status: null,
  });


  const updateFilter = (filterName, value) => {
    console.log("Filter name is", filterName);
    console.log("Filter value is", value)
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };
  const fetchUserLeaves = async () => {
    console.log("Filter data ", filters)
    setIsLoading(true)
    try {
      const response = await api.get('/myLeaves',{
        params: {
            page,
            pageSize,
        },
    });
      if (response.status === 200) {
        console.log(response.data.leaves)
        setUserLeaves(response.data.leaves);
        if (response.data.leaves.length < pageSize) {
          setHasMore(false);
      } else {
          setHasMore(true);
      }
      } else {
        console.error('Error fetching leaves:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching leaves:', error);
    }
    finally {
      setIsLoading(false)
      setFilters({
        startDate: null,
        endDate: null,
        leaveType: null,
        duration: null,
        status: null,
      });
    }
  };
  const getLatestStatus = (leave) => {
    if (leave.statusHistory && leave.statusHistory.length > 0) {
      return leave.statusHistory[leave.statusHistory.length - 1].status;
    }
    return 'Pending';
  };
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'pending':
        return 'status-badge warning';
      case 'rejected':
        return 'status-badge danger';
      case 'approved':
        return 'status-badge success';
      default:
        return 'status-badge info'; // Default class if status doesn't match any of the above
    }
  };

  const fetchLeaveTypes = async () => {
    try {
      const response = await api.get('/companyLeaveTypeFinder'); // Replace with your actual API endpoint
      if (response.status === 200) {
        console.log(response.data)
        setLeaveTypes(response.data);
      } else {
        console.error('Error fetching leave types:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching leave types:', error);
    }
  };
  useEffect(() => {
    fetchUserLeaves();
  }, [page]);
  useEffect(() => {
    fetchLeaveTypes(); // Fetch leave types on component mount
  }, []);


  const handleDeleteLeave = async (id) => {
    try {
      const response = await api.delete(`/Deleteleave`, { params: { id } });

      if (response.status === 200) {
        toast.success("Leave deleted successfully");
        setShowDialog(false);
        fetchUserLeaves()

      } else {
        toast.error("An error occurred while deleting leave");
      }

    }
    catch (err) {
      toast.error("An error occurred while deleting leave");
    }
  };

  const handleFilter = async (filters) => {
    try {
      console.log(filters)
      setIsLoading(true);
      const response = await api.get('/getUserApplyLeaves', { params: filters });
      if (response.status === 200) {
        setUserLeaves(response.data.leaves);
      }
      else {

      }

    } catch (err) {
      toast.error(err)

    }
    finally {
      setIsLoading(false);

    }
    console.log("filter calling", filters)
  }
  const handleViewDetails =async (leaveId) => {
    const Result=await api.get( `/viewMyLeaveDetails/${leaveId}`)
    console.log("Result is print",Result)
    setSelectedLeave(Result.data.leaves[0]);
    setShowDialog(true);
  };
  const handleAutomaticDownload = (documentUrl, originalFilename) => {
    const link = document.createElement('a');
    link.href = documentUrl;
    link.download = originalFilename;

    // Trigger the download
    link.click();
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'dd-MM-yyyy');
  };
  const calculateDays = () => {
    if (selectedLeave?.Days && selectedLeave?.Duration) {
      const adjustedDuration = (selectedLeave.Duration === 0.5 || selectedLeave.Duration === 0.6) ? 0.5 : selectedLeave.Duration;
      const totalDays = selectedLeave.Days * adjustedDuration;
      return totalDays // Adjust this logic based on the specific requirement
    }
    return 0; // Default value if data is not available
  };
  const handlePageChange = (newPage) => {
    if (newPage > 0 && (newPage !== page || hasMore)) {
        setPage(newPage);
    }
};

  return (
    <>
      {/* ... other JSX content ... */}
      <div class=" shadow p-3 bg-white rounded" style={{minWidth: '100%'}}>
        <div class="h5 pb-2 mt-2 mb-2 border-bottom text-primary">
          My Leaves
        </div>
        
        <div className="row align-items-center justify-content-between ">
        <div className="col-md-6 ">
          <a href="/apply-leave" className="btn btn-primary ml-2 mr-2">Apply Leave</a>
        </div>
        
        
          {/* <div className="col-md-2 col-sm-6 mb-2">
            <Calendar
              value={filters.startDate}
              onChange={(e) => updateFilter('startDate', e.value)}
              style={{ width: '100%', height: '37px' }}
              placeholder="From Date"
              dateFormat="dd/mm/yy"
              maxDate={filters.endDate ? new Date(filters.endDate) : null}
            />
          </div>
          <div className="col-md-2 col-sm-6 mb-2">
            <Calendar
              value={filters.endDate}
              onChange={(e) => updateFilter('endDate', e.value)}
              style={{ width: '100%', height: '37px', marginBottom: '2px' }}
              placeholder="To Date"
              dateFormat="dd/mm/yy"
              minDate={filters.startDate ? new Date(filters.startDate) : null}
            />
          </div>
          <div className="col-md-2 col-sm-6 mb-2">
            <Dropdown
              value={filters.duration}
              options={[
                { label: 'Full Day', value: 1 },
                { label: 'First Half', value: 0.5 },
                { label: 'Second Half', value: 0.5 },
              ]}
              placeholder="Day"
              onChange={(e) => updateFilter('duration', e.value)}
              style={{ width: '100%', height: '37px' }}
            />
          </div>
          <div className="col-md-2 col-sm-6 mb-2">
            <Dropdown
              value={filters.leaveType}
              options={leaveTypes.map(leave => ({ label: leave.leave_name, value: leave.leave_type_id }))}
              onChange={(e) => updateFilter('leaveType', e.value)}
              style={{ width: '100%', height: '37px' }}
              placeholder="Type"
            />
          </div>
          <div className="col-md-2 col-sm-6 mb-2">
            <Dropdown
              placeholder="Status"
              value={filters.status}
              options={['Pending', 'Rejected', 'Approved']}
              onChange={(e) => updateFilter('status', e.value)}
              style={{ width: '100%', height: '37px' }}
            />
          </div> */}
          {/* <div className="col-md-2 col-sm-12 mb-2">
            <div class="row">

              <button type="button" class="btn btn-light col-sm-6" style={{ borderRadius: "20px", color: 'blue' }} onClick={fetchUserLeaves}>
                <i class="fas fa-search"></i> Search
              </button>

              <Link to={`/apply-leave`} className="btn col-sm-6" style={{ backgroundColor: "#6366F1", color: "white", borderRadius: "20px" }}>
                Apply
              </Link>
            </div>
          </div> */}
          {/* <div className="col-md-2 col-sm-12 mb-2">
            <div class="row">
              <Link to={`/apply-leave`} className="btn col-sm-6" style={{ backgroundColor: "#6366F1", color: "white", borderRadius: "20px" }}>
                Apply
              </Link>
            </div>
          </div> */}
        </div>
        
        <div className="row mt-4">
          {userLeaves.length > 0 ? (
            userLeaves.map((leave) => (
              <div className="col-xl-3 col-sm-6">
                <div className='card leave-item p-2' key={leave.ApplicationID} style={{ backgroundColor: '#D1CFCF', cursor: 'pointer', position: 'relative' }} onClick={() => handleViewDetails(leave.ApplicationID)}>
                  <div className='text-center' style={{ backgroundColor: "#ffffff", borderRadius: "5px" }}>
                    <div className='leave_info' style={{ color: '#2643FF', fontSize: '20px', fontWeight: "700" }}>{leave.type}</div>
                  </div>

                  <div className='mt-4'>
                    <span style={{ color: '#021317', fontWeight: '700' }}>Leave Sub Type: </span>
                    {leave.subType != null ? (
                      <span className='text-danger' style={{ color: '#FDFDFD', fontWeight: '700' }}> {leave.subType}</span>
                    ) : (
                      <span className='text-dark' style={{ color: 'black', fontWeight: '700' }}> Not Available</span>
                    )}

                  </div>
                  <div className='row mt-4'>
                    <div className='col'>
                      <div className='leave_info'><i className="fa fa-clock" /> Duration: {leave.Duration === 0.5
                        ? "First Half"
                        : leave.Duration === 0.6
                          ? "Second Half"
                          : leave.Duration === 1.0
                            ? "Full Day"
                            : ""}
                      </div>
                      <div className='leave_info'><i className="fa fa-calendar" />  From: {leave.startDate}</div>
                      <div className='leave_info'><i className="fa fa-calendar" /> To: {leave.endDate}</div>
                    </div>
                    <div className='col-auto'>
                      <i className='fas fa-home' style={{ fontSize: '4rem', opacity: '0.2' }}></i>
                    </div>
                  </div>
                  <div className={`text-center mt-3 p-1 ${leave.finalstatus === 'Pending' ? 'bg-warning' : leave.finalstatus === 'Approved' ? 'bg-success' : leave.finalstatus === 'Rejected' ? 'bg-danger' : 'bg-danger'}`} style={{ borderRadius: "10px" }}>
                    <span className='status text-white'>{leave.finalstatus}</span>
                  </div>
                </div>
              </div>
            ))
            
          ) : <div className="col-12">
            <p className="text-center">You have no leaves.</p>
          </div>
          }
          <div className="pagination-controls" style={{ display: 'flex', justifyContent: 'end' }}>
                <button
                    onClick={() => handlePageChange(page - 1)}
                    disabled={page === 1}
                    className='btn btn-primary'
                >
                    Previous
                </button>
                <span>Page {page}</span>
                <button
                    onClick={() => handlePageChange(page + 1)}
                    disabled={!hasMore}
                    className='btn btn-primary'
                >
                    Next
                </button>
        </div>
        </div>
      </div >

      <Dialog className="dialogbox " showHeader={false} visible={showDialog} style={{ width: '50vw', borderRadius: "10px" }}>
        <div className='card chat w-100 ' style={{ padding: '0', margin: '0' }}>
          <div className='card-header' style={{ backgroundColor: '#5B8FFF' }}>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='col text-center text-white'>
                <span className='h-100 fw-bold' style={{ fontSize: '20px' }}>{selectedLeave?.type} - {selectedLeave?.Duration === 0.5 ? "First Half" : selectedLeave?.Duration === 0.6 ? "Second Half" : selectedLeave?.Duration === 1.0 ? "Full Day" : `${selectedLeave?.Duration} ${selectedLeave?.subType}`} {selectedLeave?.subType}</span>
              </div>
              <div className='col-auto'>
                <i className="fa fa-times" onClick={() => setShowDialog(false)}></i>
              </div>
            </div>
          </div>
          <div className='card-body max-w-60' style={{ backgroundColor: '#D7D9DE' }}>
            <div className='row'>
              <div>
                {/* <span style={{ color: '#021317', fontWeight: '700', fontSize: '14px' }}>
                  Leave Durations:
                </span>
                <span className='fw-bold' style={{ color: '#F50000', fontSize: '18px' }}>
                  {selectedLeave?.Duration === 0.5 ? "First Half" : selectedLeave?.Duration === 0.6 ? "Second Half" : selectedLeave?.Duration === 1.0 ? "Full Day" : `${selectedLeave?.Duration} ${selectedLeave?.subType}`} {selectedLeave?.subType}
                </span> */}
                <span style={{ color: '#021317', fontWeight: '700', fontSize: '14px' }}>
                  Status :
                  <span style={{ fontSize: '16px', fontWeight: '700' }} className={`status-text ${selectedLeave?.finalstatus === 'Approved' ? 'text-success' : selectedLeave?.finalstatus === 'Rejected' ? 'text-danger' : 'text-warning'}`}>
                    {selectedLeave?.finalstatus}
                  </span>
                </span>
              </div>
              <div className='mt-2'>
                <div style={{ color: '#021317', fontWeight: '700' }}>
                  <i className='fa fa-suitcase' />
                  <h6 className="d-inline ml-2" style={{ fontSize: '14px' }}>Leave is of <strong>{calculateDays()}</strong> {calculateDays() <= 1 ? "day" : "days"}</h6>
                </div>
                <div style={{ color: '#021317', fontWeight: '700' }}>
                  <i className='fa fa-calendar' />
                  <h6 className="d-inline ml-2" style={{ fontSize: '14px' }}>From: {selectedLeave?.startDate ? formatDate(selectedLeave.startDate) : 'No start date'}</h6>
                </div>
                <div style={{ color: '#021317', fontWeight: '700' }}>
                  <i className='fa fa-calendar' />
                  <h6 className="d-inline ml-2" style={{ fontSize: '14px' }}>To: {selectedLeave?.endDate ? formatDate(selectedLeave.endDate) : 'No End date'}</h6>
                </div>
                <div style={{ color: '#021317', fontWeight: '700' }}>
                  <i className='fa fa-file' />
                  <h6 className="d-inline ml-2" style={{ fontSize: '14px' }}>Attached Document: {selectedLeave?.AttachedDocumnet.pdf_url ? (
                    <a
                      href={selectedLeave.AttachedDocumnet.pdf_url}
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(selectedLeave.AttachedDocumnet.pdf_url, '_blank');
                      }}
                      role="button"
                      target='_blank'
                      aria-label={`Download ${selectedLeave.AttachedDocumnet.originalFilename}`}
                      style={{ color: '#6366F1', fontWeight: 'bold' }}
                    >
                      {selectedLeave.AttachedDocumnet.originalFilename}
                    </a>
                  ) : (
                    <span>Not provided</span>
                  )}
                  </h6>
                </div>
              </div>
            </div>

            <div>
              <h5 className="mt-4 ml-2 " style={{ color: '#021317', fontWeight: "700" }}>Leave History</h5>
            </div>
            <div class=" card chat-history mt-2" style={{ backgroundColor: '#F9FCFF' }}>
              <div>
                <ul className="list-unstyled">
                  <li>
                    <div className="d-flex flex-column mt-3">
                      <div className="d-flex align-items-center ml-3" style={{ fontSize: "12px", color: "#6366F1" }}>
                        <strong> {selectedLeave?.statusHistory[0].updatedBy}</strong>
                        <div className="message-data-time ml-2">
                          {selectedLeave?.statusHistory[0].dateChanged}
                        </div>
                      </div>
                      <div className="d-flex mt-1 ml-2">
                        <div>
                          <img
                            src={selectedLeave?.statusHistory[0].user_image || `/images/icon/Noprofile.webp`}
                            className="rounded-circle mr-2"
                            style={{ width: "50px", height: "50px", objectFit: "cover" }}
                            alt="avatar21"
                          />
                        </div>
                        <div
                          className="card message other-message p-2 ml-1 mr-5"
                          style={{ backgroundColor: "#A4A6A8", color: "#000000" }}
                          dangerouslySetInnerHTML={{ __html: selectedLeave?.statusHistory[0].statusReson }}
                        />
                      </div>
                    </div>
                  </li>
                  {selectedLeave?.statusHistory.map((historyItem, index) => (
                    index >= 1 && (
                      <li className="clearfix mt-2 d-flex justify-content-end ml-auto mr-3" key={index}>
                        <div className="d-flex flex-column align-items-end mt-3">
                          <div className="d-flex align-items-center " style={{ fontSize: "12px", color: "#6366F1" }}>
                            <div className="message-data-time mr-2">
                              {historyItem.dateChanged}
                            </div>
                            <strong>{historyItem.updatedBy}</strong>
                          </div>
                          <div className="d-flex mt-1">
                            <div
                              className="card message other-message p-2"
                              style={{ backgroundColor: "#A4A6A8", color: "#000000" }}
                            >{historyItem.statusReson}
                            </div>
                            <div>
                              <img
                                src={historyItem.user_image || `/images/icon/Noprofile.webp`}
                                className="rounded-circle ml-2 mr-3"
                                style={{ width: "60px", height: "50px", objectFit: "cover" }}
                                alt="avatar"
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: "600",
                              color: historyItem.status === "Rejected" ? "red" : "green"
                            }}
                          >
                            {historyItem.status}
                          </div>

                        </div>
                      </li>
                    )
                  ))}
                </ul>
              </div>
            </div>
            {selectedLeave?.statusHistory?.length === 1 &&
              <div className="d-flex justify-content-end mt-3">
                <button className='btn btn-danger btn-sm' onClick={() => handleDeleteLeave(selectedLeave?.id)}>Delete</button>
              </div>
            }
          </div>
        </div>
      </Dialog >
      <ToastContainer />
    </>
  );
}
export default Leaves;