import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../Authenticaltion/api';
import ReactApexChart from 'react-apexcharts';
import { toast } from 'react-toastify';
import { useLoading } from '../../LoadingContext';
import "./UserDashboard.css";

const UserDashboard = () => {
  const navigate = useNavigate()
  const [userPresent, SetUserPresent] = useState("0");
  const [UserAbsent, SetUserAbsent] = useState("0");
  const [UserThisMonthPresent, SetUserThisMonthPresent] = useState("0")
  const [UserLateComming, SetUserLateComming] = useState("0")
  const [showDetails, setShowDetails] = useState(false);
  const [detailsFor, setDetailsFor] = useState(null)
  const { updateLoading } = useLoading()


  const overviewItems = [
    {
      title: 'Total Attendance',
      icon: 'fas fa-clipboard-check',
      value: userPresent,
      detailsKey: 'Present',
      column: "c1",
      color: "#455dfc, #3945d5, #2a2daf, #19168a, #000068"
    },

    {
      title: 'Monthly Present',
      icon: 'zmdi zmdi-check-circle',
      value: UserThisMonthPresent,
      detailsKey: 'ThisMonthPresent',
      column: "c2",
      color: "#03a622, #02951d, #018519, #017514, #006610"
    },
    {
      title: 'Monthly Tardiness',
      icon: 'zmdi zmdi-alarm-snooze',
      value: UserLateComming,
      detailsKey: 'ThisMonthLateArrivals',
      column: "c3",
      color: "#d6df22, #cdcf1b, #c3bf15, #b9b00f, #afa109"
    },

    {
      title: 'Monthly Absent',
      icon: 'zmdi zmdi-minus-circle',
      value: UserAbsent,
      detailsKey: 'Absent',
      column: "c4",
      color: " #ff0000, #ed0000, #db0000, #ca0000, #b90000"
    },

  ];

  const NewOverviewItems = [
    {
      title: 'Total Attendance',
      icon: 'fas fa-clipboard-check',
      value: userPresent,
      detailsKey: 'Present',
      column: "c1",
      color: "primary"
    },

    {
      title: 'Monthly Present',
      icon: 'zmdi zmdi-check-circle',
      value: UserThisMonthPresent,
      detailsKey: 'ThisMonthPresent',
      column: "c2",
      color: "success"
    },
    {
      title: 'Monthly Absent',
      icon: 'zmdi zmdi-minus-circle',
      value: UserAbsent,
      detailsKey: 'Absent',
      column: "c3",
      color: "danger"
    },
    {
      title: 'Monthly Tardiness',
      icon: 'zmdi zmdi-alarm-snooze',
      value: UserLateComming,
      detailsKey: 'ThisMonthLateArrivals',
      column: "c4",
      color: "warning"
    },
  ];
  const [chartData, setChartData] = useState(null);
  const [leavecount, setLeavecount] = useState({ 'Sick Leave': 8, 'Casual Leave': 3 })
  useEffect(() => {
    updateLoading(true)
    Promise.all([fetchUserLeaveBalance(), fetchuserAnalyticsData(), fetchUserAttendanceOverview()])
      .then(() => {
        // Set loading to false when all data fetching is complete
        updateLoading(false);
      })
      .catch((error) => {
        updateLoading(false); // Set loading to false on error as well
        toast.error(error.message); // Handle and display error messages
      });
  }, []);
  const fetchUserLeaveBalance = async () => {
    try {
      const response = await api.get('/getUserLeaveCount'); // Use await to wait for the promise to resolve
      if (response.status === 200) {
        const data = response.data.data; // Assuming the data is returned in a structure like { data: formattedData }
        // Update the state with the fetched user leave data
        setLeavecount(data); // Update the leave count state

        // You can also update other state variables or perform additional logic if needed
      } else {
        toast.error("Failed to fetch leave balance"); // Display an error message if the response status is not 200
      }


    } catch (error) {
      toast.error(error.message); // Display the error message from the exception
      // Handle the error and display an error message in your frontend UI
    }
  };
  const fetchuserAnalyticsData = async () => {
    try {
      const response = await api.get('/userAnalysisData')


      if (response.status === 200) {
        const formattedData = response.data; // Assuming you receive the data from the backend
        console.log(formattedData)
        SetUserPresent(formattedData.Total_Present)
        SetUserAbsent(formattedData.This_Month_Absent)
        SetUserThisMonthPresent(formattedData.This_Month_Present)
        SetUserLateComming(formattedData.This_Month_Late)
      }
    }
    catch {

    }
  }
  const fetchUserAttendanceOverview = async () => {
    try {
      const graphresponse = await api.get('/userAttendanceOverview')
      if (graphresponse.status === 200) {
        console.log("Priming Graph Result is", graphresponse.data)
        const data = graphresponse.data
        const categories = data.map(item => item.Month_Name);
        const absent = data.map(item => parseInt(item.Missing_Attendance_Count, 10));
        const lateArrival = data.map(item => parseInt(item.Late_Attendance, 10));
        const present = data.map(item => parseInt(item.Total_Attendance, 10));
        setChartData({ categories, absent, lateArrival, present });

      }

    }
    catch {

    }

  }

  const attendanceoverviewoptions = {
    chart: {
      type: 'line', // Use 'line' for a spline chart
    },
    title: {
      // text: 'Attendance Overview',
      style: {
        fontSize: '20px',
        color: '#5188EE'
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '10px',

      },
    },
    xaxis: {
      categories: chartData ? chartData.categories : [], // Use the month labels from state
    },
    dataLabels: {
      enabled: false,
    },
    toolbar: {
      show: false, // Hide the toolbar (zoom-in, zoom-out, menu)
    },
    colors: ['#17990C', '#E41414', '#F9E81D'],
  };
  const attendanceoverviewseries = [
    {
      name: 'Present',
      data: chartData ? chartData.present : [], // Use data from state
    },
    {
      name: 'Absent',
      data: chartData ? chartData.absent : [], // Use data from state
    },
    {
      name: 'Late',
      data: chartData ? chartData.lateArrival : [],// Use data from state
    }
  ];

  const LeaveDistributionOptions = {
    chart: {
      type: 'pie',
      toolbar: {
        show: false,
      },
    },
    title: {
      // text: 'Leave Type Distribution',
      style: {
        fontSize: '20px',
        color: '#5188EE'
      },
    },
    labels: Object.keys(leavecount),
    colors: ['#6572d6', '#F1C40F', '#3498DB'], // Set colors for the sections
    legend: {
      show: true, // Show the legend by default
      position: 'bottom', // Position the legend at the bottom
    },
    toolbar: {
      show: false, // Hide the toolbar (zoom-in and download icons)
    },
    responsive: [
      {
        breakpoint: 768, // Adjust the breakpoint as needed for your layout
        options: {
          chart: {
            width: '100%', // Adjust chart width for smaller devices
          },
          legend: {
            show: true, // Hide the legend for smaller screens
          },
        },
      },
    ],
  }
  // const handleDetailsClick = (value) => {
  //   if (showDetails && detailsFor === value) {
  //     // If details are already shown for this value, hide them
  //     setShowDetails(false);
  //     setDetailsFor(null);
  //   } else {
  //     // If details are not shown for this value, show them
  //     setDetailsFor(value);
  //     setShowDetails(true);
  //   }
  // };
  const handleViewClick = (key) => {
    // Navigate to the new route with the key parameter
    navigate(`/userDashboard/View/${key}`);
  };
  return (
    <>
      <div class=" shadow p-3 bg-white rounded">
        <div class="h5 pb-2 mt-2 mb-2 border-bottom text-primary">
          User Dashboard
        </div>
        <div className="row mt-4">
          {overviewItems.map((item) => (
            <div className="col-lg-3 col-xs-6" key={item.title}>
              <div
                className="card text-white p-2 rounded"
                onClick={() => handleViewClick(item.detailsKey)}
                style={{
                  backgroundImage: `linear-gradient(to right, ${item.color})`,
                  border: "none"
                }}
              >
                <div className="row icon align-items-center">
                  <div className="row">
                    <div className="col">
                      <h4 className='row text-white text-nowrap ml-2'>{item.title}</h4>
                      <h4 className='row text-white text-nowrap ml-2 mt-3'>{item.value}</h4>
                    </div>
                    <div className='col text-right'>
                      <i className={item.icon} style={{ opacity: "0.8" }}></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div>
        </div>

        <div className="row " >
          <div className="col-xl-9 col-lg-12">
            <div class="h5 pb-2 mt-2 mb-2 text-primary ml-2">
              Attendance Overview
            </div>
            <div className="recent-report">
              <ReactApexChart options={attendanceoverviewoptions} series={attendanceoverviewseries} type="bar" height="230%" />
            </div>
          </div>

          <div className="col-xl-3 col-lg-12">
            <div class="h5 pb-2 mt-2 mb-2 text-primary ml-2">
              Leave Type Distribution
            </div>
            <div className="recent-report mt-3" >
              <ReactApexChart
                options={LeaveDistributionOptions}
                series={Object.values(leavecount)}
                type="pie"
                height="260%"
              />
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default UserDashboard
