import React, { useState } from 'react';
import api from '../../Authenticaltion/api';
import './Register.css';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';


const Register = () => {
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    company_name: '',
    password: '',
    confirm_password: '',
    company_email: '',
    phone: '',
    cin_number: '',
    industry: '',
    company_description: '',
    company_address: ''
  });
  const [otp, SetOtp] = useState('');
  const [currentStep, setCurrentStep] = useState(1);

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let processedValue = value;

    if (name === 'company_name') {
      processedValue = value.trimLeft();
    } else if (name === 'company_email') {
      processedValue = value.toLowerCase().trimLeft();
    } else if (name === 'industry') {
      // Only allow string values (letters and spaces)
      processedValue = value.replace(/[^a-zA-Z\s]/g, '').trimLeft();
    }

    setFormData({ ...formData, [name]: processedValue });
  };


  const handleNextStep = async () => {
    try {
      if (currentStep === 1) {
        const { company_name, company_email, password, confirm_password } = formData;

        if (company_name.trim() !== '' && company_email.trim() !== '' && password.trim() !== '' && confirm_password.trim() !== '') {
          if (isValidEmail(company_email)) {
            if (password === confirm_password) {
              // Move to the next step
              setCurrentStep(currentStep + 1);
            } else {
              // Passwords do not match
              toast.warning('Password and Confirm Password do not match.');
            }
          } else {
            // Invalid email
            toast.warning('Please enter a valid email address.');
          }
        } else {
          // Empty fields
          Swal.fire({
            text: "Please fill all the mandatory fields",
            icon: "warning",
            showConfirmButton: false,
            timer: 1500
          });
        }
      } else if (currentStep === 2) {
        const { cin_number, industry, company_description, company_address } = formData;

        if (cin_number.trim() === '' || industry.trim() === '' || company_description.trim() === '' || company_address.trim() === '') {
          Swal.fire({
            text: "Please fill all the mandatory fields",
            icon: "warning",
            showConfirmButton: false,
            timer: 1500
          });
        } else if (cin_number.length !== 21) {
          Swal.fire({
            text: "The CIN number must be 21 characters",
            icon: "warning",
            showConfirmButton: false,
            timer: 1500
          });
        } else if (/\s/.test(cin_number)) {
          // Check for spaces in CIN number
          Swal.fire({
            text: "Please enter a valid CIN number",
            icon: "warning",
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          // Making an asynchronous API call to send OTP
          const response = await api.post('/SendOtpToCompanyEmail', { email: formData.company_email });

          // Check if the API call was successful
          if (response.status === 200) {
            if (response.data.error === "Email already exists.") {
              // Handle email already exists error
              Swal.fire({
                text: "Email already exists. Please try with another email",
                icon: "error",
                confirmButtonText: "OK"
              }).then(() => {
                navigate('/');
              });
            } else {
              // Move to the next step
              console.log("response is print", response);
              setCurrentStep(currentStep + 1);
            }
            // Move to the next step
           
          }
           else {
            // Handle API error
            toast.error('Failed to send OTP. Please try again.');
          }
        }
      }
    } catch (error) {
      // Handle unexpected errors
      console.error('An error occurred:', error);
      toast.error('An unexpected error occurred. Please try again.');
    }
  };

  const handlePrevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const VerifyOtpMethod = async (email, otp) => {
    try {
      if (!otp || otp.trim() === '' || otp.length !== 6 || isNaN(otp)) {
        toast.warning('Please enter a valid 6-digit OTP.');
      } else {
        const VerifyOtpResult = await api.post('/VerifyOtpToCreateCompany', { email: email, otp: otp })
        if (VerifyOtpResult.status === 200) {
          if (VerifyOtpResult.data.message) {
            const filteredFormData = Object.fromEntries(
              Object.entries(formData).filter(([key, value]) => value.trim() !== '')
            );
            const result = await api.post('/CreateCompany', filteredFormData)
            if (result.status === 200) {
              toast.success('Company Registration Successful');
              navigate('/');
            } else {
              toast.error(result.error);
            }
          } else {
            toast.error(VerifyOtpResult.data.error);
          }
        } else {
          toast.error(VerifyOtpResult.error);
        }
      }
    } catch (error) {
      console.error('An error occurred during OTP verification:', error);
      toast.error('An unexpected error occurred during OTP verification. Please try again.');
    }
  };

  const handleResendOtp = async (email) => {
    try {
      const resendOtpResult = await api.post('/ResendOtpCompanyEmail', { email: email });
      if (resendOtpResult.status === 200) {
        toast.success('Otp resend To registered email');
      } else {
        toast.error(resendOtpResult);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className='container'>
      <div className="col-md-6 d-flex justify-content-center align-items-center">
        <img src="./images/RegNew.png" alt="" style={{ width: '618px', height: '500px' }} />
      </div>

      <div className="col-md-6 d-flex justify-content-center align-items-center">
        <div className='Register-wrap'>
          <div className='Register-content'>
            <div className="register-logo">
              <a href="#">
                <img width="300" src="images/LogoSpc.png" alt="CoolAdmin" />
              </a>
              <h5 className="mb-2">Register 👇</h5>
              <p className="mb-4 enter-message" style={{ fontSize: '12px' }}>Please complete to create an account.</p>
            </div>
            <form>
              {currentStep === 1 && (
                <div className="form-group">
                  <label htmlFor="email" style={{ marginBottom: '0px', paddingTop: '0px' }}> Company Name <span style={{ color: 'red' }}>*</span></label>
                  <input
                    type="text"
                    className="input-field"
                    name="company_name"
                    value={formData.company_name}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="email" style={{ marginBottom: '0px', paddingTop: '10px' }}> Email <span style={{ color: 'red' }}>*</span></label>
                  <input
                    type="email"
                    className="input-field"
                    name="company_email"
                    value={formData.company_email}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="password" style={{ marginBottom: '0px', paddingTop: '10px' }}>Password<span style={{ color: 'red' }}>*</span></label>
                  <input
                    type="text"
                    className="input-field"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="confirm_password" style={{ marginBottom: '0px', paddingTop: '10px' }}>Confirm Password<span style={{ color: 'red' }}>*</span></label>
                  <div className="password-input-container">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className="input-field"
                      name="confirm_password"
                      value={formData.confirm_password}
                      onChange={handleChange}
                      required
                    />
                    <i
                      className={`fas ${showPassword ? "fa-duotone fa-eye-slash" : "fa-light fa-eye"} password-toggle-icon`}
                      onClick={() => setShowPassword(!showPassword)}
                    ></i>
                  </div>
                </div>
              )}
              {currentStep === 2 && (
                <div>
                  <label htmlFor="cin" style={{ marginBottom: '0px' }}>CIN No:</label>
                  <input
                    type="text"
                    className="input-field"
                    name="cin_number"
                    value={formData.cin_number}
                    onChange={handleChange}
                    maxLength={21}
                    minLength={21}
                  />
                  <label htmlFor="industry" style={{ marginBottom: '0px', paddingTop: '10px' }}>Industry:</label>
                  <input
                    type="text"
                    className="input-field"
                    name="industry"
                    value={formData.industry}
                    onChange={handleChange}
                  />
                  <label htmlFor="description" style={{ marginBottom: '0px', paddingTop: '10px' }}>Company Description:</label>
                  <textarea
                    className="input-field"
                    name="company_description"
                    value={formData.company_description}
                    onChange={handleChange}
                  />
                  <label htmlFor="address" style={{ marginBottom: '0px', paddingTop: '10px' }}>Address:</label>
                  <textarea
                    className="input-field"
                    name="company_address"
                    value={formData.company_address}
                    onChange={handleChange}
                  />
                </div>
              )}
              {currentStep === 3 && (
                <div>
                  <label htmlFor="otp" style={{ marginBottom: '0px' }}>OTP:</label>
                  <input
                    type="number"
                    className="input-field"
                    name="otp"
                    value={otp}
                    onChange={(e) => SetOtp(e.target.value)}
                    required
                    maxLength={6}
                  />
                  <span type="button" style={{ alignItems: 'flex-end', color: 'red' }} onClick={(e) => { handleResendOtp(formData.company_email) }}>
                    Resend OTP
                  </span>
                </div>
              )}

              <div className='button-container'>
                {currentStep === 1 && (
                  <button type="button" onClick={handleNextStep} style={{ backgroundColor: 'rgb(99, 102, 241)', padding: '5px', width: '100px', borderRadius: '5px', color: 'white', justifyContent: 'flex-start' }}>
                    Next
                  </button>
                )}
                {currentStep === 2 && (
                  <>
                    <button type="button" onClick={handlePrevStep} style={{ backgroundColor: '#ea0303a1', padding: '5px', width: '100px', borderRadius: '5px', color: 'white', alignItems: 'start', marginRight: 'auto' }}>
                      Previous
                    </button>
                    <button type="button" onClick={handleNextStep} style={{ backgroundColor: 'rgb(99, 102, 241)', padding: '5px', width: '100px', borderRadius: '5px', color: 'white', justifyContent: 'flex-end' }}>
                      Next
                    </button>
                  </>
                )}
                {currentStep === 3 && (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button
                      type="button"
                      className='btn btn-primary'
                      onClick={(e) => { VerifyOtpMethod(formData.company_email, otp) }}
                      style={{
                        backgroundColor: 'rgb(99, 102, 241)',
                        padding: '5px',
                        width: '100px',
                        borderRadius: '5px',
                        color: 'white',
                        marginTop: '10px', // Adjust margin top as needed
                      }}
                    >
                      Submit
                    </button>
                  </div>
                )}

              </div>
            </form>
          </div>
          <div className="mt-3 text-center">
            <span>
              Already have an account?{' '}
              <Link to='/Login' style={{ textDecoration: 'none', color: '#6366F1' }}>Log In</Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
