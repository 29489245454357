import React, { useEffect, useState } from 'react';
import api from '../../Authenticaltion/api';
import Swal from 'sweetalert2';
import { useLoading } from '../../LoadingContext';
import { Calendar } from 'primereact/calendar';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const GenerateSalarySlip = () => {
    const navigate = useNavigate();

    const { updateLoading } = useLoading();
    const [EmpList, setEmpList] = useState([]);
    const [formData, setFormData] = useState({
        employeeName: '',
        monthYear: '',
        basic: '',
        conveyance: '',
        medical: '',
        epf: '',
        pt: '',
        arrears: '',
        tds: '',
        dayWorked: '',
        payDate: '',
        hra: '',
        cca: '',
        specialAllowance: '',
        esi: '',
        otherDeductions: '',
        variable: ''
    });

    const EmployeeList = async () => {
        try {
            const response = await api.get('/getEmployeeNameWithIdEmail');
            console.log("response", response.data);
            setEmpList(response.data);
        } catch (error) {
            console.error("Unable to fetch Employee list", error);
        }
    }

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        const formDataCopy = { ...formData };

        if ((name === 'basic' || name === 'conveyance' || name === "medical" || name === "epf" || name === "pt" || name === "arrears" || name === "tds" || name === "dayWorked" || name === "hra" || name === "cca" || name === "specialAllowance" || name === "esi" || name === "otherDeductions" || name === "variable") && /[^0-9.]/.test(value)) {
            return;
        }

        if (name === 'monthYear') {
            console.log("value", value);
            const selectedDate = new Date(value);
            const currentDate = new Date();

            if (selectedDate > currentDate) {
                Swal.fire({
                    icon: "warning",
                    text: "Please select a month and year not beyond the current month!",
                    showConfirmButton: false,
                    timer: 2000
                });

                formDataCopy[name] = '';
            } else {
                const year = selectedDate.getFullYear();
                const month = selectedDate.getMonth() + 1; // Months are zero indexed in JavaScript, so add 1

                const daysInMonth = new Date(year, month, 0).getDate();
                const currentDayWorked = parseFloat(formDataCopy.dayWorked);

                if (!isNaN(currentDayWorked) && currentDayWorked > daysInMonth) {
                    Swal.fire({
                        icon: "warning",
                        text: `Day Worked cannot exceed ${daysInMonth} days for the selected month and year!`,
                        showConfirmButton: false,
                        timer: 1500
                    });
                    formDataCopy.dayWorked = '';
                }
            }
        }

        if (type === 'number') {
            if (!isNaN(value) && parseFloat(value) >= 0) {
                formDataCopy[name] = parseFloat(value);
            } else {
                formDataCopy[name] = '';
            }
        } else {
            formDataCopy[name] = value;
        }

        if (name === 'dayWorked') {
            const currentDayWorked = parseFloat(value);
            const selectedMonthYear = formDataCopy.monthYear;

            if (selectedMonthYear instanceof Date) {
                const year = selectedMonthYear.getFullYear();
                const month = selectedMonthYear.getMonth() + 1; // Months are zero indexed in JavaScript, so add 1
                const daysInMonth = new Date(year, month, 0).getDate();

                if (!isNaN(currentDayWorked) && currentDayWorked > daysInMonth) {
                    Swal.fire({
                        icon: "warning",
                        text: `Day Worked cannot exceed ${daysInMonth} days for the selected month and year!`,
                        showConfirmButton: false,
                        timer: 1500
                    });
                    formDataCopy.dayWorked = '';
                }
            }
        }

        setFormData(formDataCopy);
    };


    const handleGenerate = async () => {
        const { employeeName, monthYear, basic, conveyance, medical, epf, pt, arrears, tds, dayWorked, payDate, hra, cca, specialAllowance, esi, otherDeductions, variable } = formData;
        if (!employeeName || !monthYear || !basic || !dayWorked || !payDate) {
            Swal.fire({
                icon: "warning",
                text: "Fill all mandatory fields!",
                showConfirmButton: false,
                timer: 1500
            });
            return;
        }
        const earnings = [
            { earning_type: "Basic Salary", earning_amount: parseFloat(basic) || 0 },
            { earning_type: "Conveyance", earning_amount: parseFloat(conveyance) || 0 },
            { earning_type: "Medical", earning_amount: parseFloat(medical) || 0 },
            { earning_type: "HRA", earning_amount: parseFloat(hra) || 0 },
            { earning_type: "CCA", earning_amount: parseFloat(cca) || 0 },
            { earning_type: "Special Allowance", earning_amount: parseFloat(specialAllowance) || 0 },
            { earning_type: "Arrears", earning_amount: parseFloat(arrears) || 0 },
            { earning_type: "Variable", earning_amount: parseFloat(variable) || 0 }
        ];
        const deductions = [
            { deduction_type: "EPF", deduction_amount: parseFloat(epf) || 0 },
            { deduction_type: "ESI", deduction_amount: parseFloat(esi) || 0 },
            { deduction_type: "Pt", deduction_amount: parseFloat(pt) || 0 },
            { deduction_type: "Other Deductions", deduction_amount: parseFloat(otherDeductions) || 0 },
            { deduction_type: "Tax deducted at Source", deduction_amount: parseFloat(tds) || 0 }
        ];
        const data = {
            user_id: employeeName.split('-')[0].trim(),
            employee_id: employeeName.split('-')[1].trim(),
            salary_month: new Date(monthYear).toLocaleString('default', { month: 'long' }),
            salary_year: new Date(monthYear).getFullYear(),
            earnings,
            deductions,
            day_worked: parseFloat(dayWorked) || 0,
            pay_date: payDate
        };
        try {
            updateLoading(true);
            const response = await api.post('/GeneratePayslip', data);

            if (response.status === 201) {
                Swal.fire({
                    icon: "success",
                    text: "Salary Slip generated successfully!",
                    showConfirmButton: false,
                    timer: 1500
                });
                navigate('/Admin-Salaryslip');

            } else {
                let errorMessage = "Failed to generate salary slip!";
                if (response.data && response.data.error) {
                    errorMessage = response.data.error;
                }
                Swal.fire({
                    icon: "error",
                    text: errorMessage,
                    showConfirmButton: false,
                    timer: 1500
                });
            }
        } catch (error) {
            console.error("Error generating salary slip:", error);
            let errorMessage = "Failed to generate salary slip!";
            if (error.response && error.response.data && error.response.data.error) {
                errorMessage = error.response.data.error;
            }
            Swal.fire({
                icon: "error",
                text: errorMessage,
                showConfirmButton: false,
                timer: 1500
            });
        } finally {
            updateLoading(false);
            setFormData({
                employeeName: '',
                monthYear: new Date().toISOString().slice(0, 7),
                basic: '',
                conveyance: '',
                medical: '',
                epf: '',
                pt: '',
                arrears: '',
                tds: '',
                dayWorked: '',
                payDate: '',
                hra: '',
                cca: '',
                specialAllowance: '',
                esi: '',
                otherDeductions: '',
                variable: ''
            });
        }
    };
    useEffect(() => {
        EmployeeList();
    }, []);

    return (
        <div class=" shadow p-3 bg-white rounded">
            <div class="h5 pb-2 mt-2 mb-2 border-bottom text-primary">
                Generate Salary Slip
            </div>
            <div className="card p-3">
                <form>
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                                <label className="font-weight-normal pl-1">Employee Name <span className="text-danger">*</span></label>
                                <select className="form-control" name="employeeName" value={formData.employeeName} onChange={handleChange} required style={{ backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"><path d="M4.796 6.104a.5.5 0 0 1 .708 0L8 8.793l2.496-2.497a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 0-.708z"/></svg>')`, backgroundRepeat: 'no-repeat', backgroundPosition: 'right 10px center', backgroundSize: '19px 23px' }}>
                                    <option disabled value="">Choose Option</option>
                                    {EmpList.map((emp) => (
                                        <option key={emp.user_id} value={`${emp.user_id}-${emp.Emp_id}- ${emp.Name} (${emp.Email_Id})`}>{emp.Name} ({emp.Email_Id})</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label className="font-weight-normal pl-1">Month & Year <span className="text-danger">*</span></label>
                                {/* <input
                                    type="month"
                                    className="form-control"
                                    id="monthYear"
                                    name="monthYear"
                                    value={formData.monthYear}
                                    onChange={handleChange}
                                    required
                                /> */}
                                <Calendar
                                    id="monthYear"
                                    view="month"
                                    dateFormat="mm/yy"
                                    value={formData.monthYear}
                                    onChange={(e) => handleChange({ target: { name: 'monthYear', value: e.value } })}
                                    maxDate={new Date()}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div className="form-group">
                                <label className="pl-1">Basic <span className="text-danger">*</span></label>
                                <div className="input-group">
                                    <input type="float" className="form-control" placeholder="Basic" min="0" step="0.01" name="basic" value={formData.basic} onChange={handleChange} required />
                                    <div className="input-group-append">
                                        <span className="input-group-text">₹</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="pl-1">Conveyance</label>
                                <div className="input-group">
                                    <input type="float" className="form-control" placeholder="Conveyance" min="0" step="0.01" name="conveyance" value={formData.conveyance} onChange={handleChange} />
                                    <div className="input-group-append">
                                        <span className="input-group-text">₹</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="pl-1">Medical</label>
                                <div className="input-group">
                                    <input type="float" className="form-control" placeholder="Medical" min="0" step="0.01" name="medical" value={formData.medical} onChange={handleChange} />
                                    <div className="input-group-append">
                                        <span className="input-group-text">₹</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="pl-1">EPF</label>
                                <div className="input-group">
                                    <input type="float" className="form-control" placeholder="EPF" min="0" step="0.01" name="epf" value={formData.epf} onChange={handleChange} />
                                    <div className="input-group-append">
                                        <span className="input-group-text">₹</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="pl-1">PT</label>
                                <div className="input-group">
                                    <input type="float" className="form-control" placeholder="PT" min="0" step="0.01" name="pt" value={formData.pt} onChange={handleChange} />
                                    <div className="input-group-append">
                                        <span className="input-group-text">₹</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="pl-1">Arrears</label>
                                <div className="input-group">
                                    <input type="float" className="form-control" placeholder="Arrears" min="0" step="0.01" name="arrears" value={formData.arrears} onChange={handleChange} />
                                    <div className="input-group-append">
                                        <span className="input-group-text">₹</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="pl-1">Tax deducted at Source</label>
                                <div className="input-group">
                                    <input type="float" className="form-control" placeholder="Tax deducted at Source" min="0" step="0.01" name="tds" value={formData.tds} onChange={handleChange} />
                                    <div className="input-group-append">
                                        <span className="input-group-text">₹</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                                <label className="font-weight-normal pl-1">Day Worked <span className="text-danger">*</span></label>
                                <input type="float" className="form-control" placeholder="Day Worked" min="0" step="0.01" name="dayWorked" value={formData.dayWorked} onChange={handleChange} required />
                            </div>
                            <div className="form-group">
                                <label className="font-weight-normal pl-1">
                                    Pay Date <span className="text-danger">*</span>
                                </label>
                                <div className="input-group">
                                    {/* <input
                                        type="date"
                                        className="form-control"
                                        placeholder="Pay Date"
                                        name="payDate"
                                        value={formData.payDate}
                                        onChange={handleChange}
                                        required
                                    /> */}
                                    <Calendar id="payDate" value={formData.payDate} onChange={(e) => handleChange({ target: { name: 'payDate', value: e.value } })} style={{ width: '100%' }} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="pl-1">HRA</label>
                                <div className="input-group">
                                    <input type="float" className="form-control" placeholder="HRA" min="0" step="0.01" name="hra" value={formData.hra} onChange={handleChange} />
                                    <div className="input-group-append">
                                        <span className="input-group-text">₹</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="pl-1">CCA</label>
                                <div className="input-group">
                                    <input type="float" className="form-control" placeholder="CCA" min="0" step="0.01" name="cca" value={formData.cca} onChange={handleChange} />
                                    <div className="input-group-append">
                                        <span className="input-group-text">₹</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="pl-1">Special Allowance</label>
                                <div className="input-group">
                                    <input type="float" className="form-control" placeholder="Special Allowance" min="0" step="0.01" name="specialAllowance" value={formData.specialAllowance} onChange={handleChange} />
                                    <div className="input-group-append">
                                        <span className="input-group-text">₹</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="pl-1">ESI</label>
                                <div className="input-group">
                                    <input type="float" className="form-control" placeholder="ESI" min="0" step="0.01" name="esi" value={formData.esi} onChange={handleChange} />
                                    <div className="input-group-append">
                                        <span className="input-group-text">₹</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="pl-1">Other Deductions</label>
                                <div className="input-group">
                                    <input type="float" className="form-control" placeholder="Other Deductions" min="0" step="0.01" name="otherDeductions" value={formData.otherDeductions} onChange={handleChange} />
                                    <div className="input-group-append">
                                        <span className="input-group-text">₹</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="pl-1">Variable</label>
                                <div className="input-group">
                                    <input type="float" className="form-control" placeholder="Variable" min="0" step="0.01" name="variable" value={formData.variable} onChange={handleChange} />
                                    <div className="input-group-append">
                                        <span className="input-group-text">₹</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center mt-3">
                            <button type="button" className="btn btn-primary" onClick={handleGenerate}>Generate</button>
                        </div>
                    </div>
                </form>
            </div >
        </div >
    );
}

export default GenerateSalarySlip;

