
const useAuth = () => {




  try {
    const token = localStorage.getItem('token')
    const newRole = localStorage.getItem('newRole')

    if (token) {
      if (newRole) {
        return {
          auth: true,
          role: newRole
        }
      }
      else {
        const tokenPayload = token.split('.')[1];
        const adjustedTokenPayload = tokenPayload.replace(/-/g, '+').replace(/_/g, '/');

        // Adding padding if needed
        const paddedTokenPayload = adjustedTokenPayload.padEnd(adjustedTokenPayload.length + (4 - adjustedTokenPayload.length % 4) % 4, '=');

        // Decoding the base64Url-encoded payload
        const decodedPayload = atob(paddedTokenPayload);
        const user = JSON.parse(decodedPayload);
        return {
          auth: true,
          role: user.Role
        }
      }


    } else {
      return {
        auth: false,
        role: null
      }
    }
  }
  catch(error) {
    console.log(error)
    localStorage.removeItem('token');
    localStorage.removeItem('newRole');
    window.location.href = '/';
  }
}
export default useAuth