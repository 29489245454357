import React, { useEffect, useState } from 'react';
import api from '../../Authenticaltion/api';
import { useLoading } from '../../LoadingContext';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const UserSalarySlips = () => {
  const { updateLoading } = useLoading();
  const [salarySlips, setSalarySlips] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(10); // number of items per page

  const fetchData = async (page = 1) => {
    try {
      updateLoading(true);
      const response = await api.get(`/ShowUserToPaySlips?limit=${limit}&offset=${(page - 1) * limit}`);
      setSalarySlips(response.data.items);
      setTotalRecords(response.data.totalRecords);
      setTotalPages(response.data.totalPages);
      setCurrentPage(page);
    } catch (error) {
      toast.error("Unable to fetch salary slips");
    } finally {
      updateLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      fetchData(page);
    }
  };
  const renderPagination = () => {
    const pageNumbers = [];
    const visiblePages = 5; // Number of pages to display at once

    if (totalPages <= visiblePages) {
      // Show all pages if totalPages is small
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // Always show the first page, last page, and a few surrounding pages
      const startPage = Math.max(1, currentPage - 2);
      const endPage = Math.min(totalPages, currentPage + 2);

      // Show first page and ellipsis if necessary
      if (startPage > 1) {
        pageNumbers.push(1);
        if (startPage > 2) pageNumbers.push('...');
      }

      // Add range of pages around current page
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      // Show ellipsis and last page if necessary
      if (endPage < totalPages - 1) {
        pageNumbers.push('...');
      }
      if (endPage < totalPages) {
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers.map((page, index) => (
      <li
        key={index}
        className={`page-item ${currentPage === page ? 'active' : ''} ${page === '...' ? 'disabled' : ''}`}
      >
        {page === '...' ? (
          <span className="page-link">...</span>
        ) : (
          <button className="page-link" onClick={() => handlePageChange(page)}>
            {page}
          </button>
        )}
      </li>
    ));
  };

  return (
    <div className="shadow p-3 bg-white rounded" style={{ minHeight: '85vh' }}>
      <div className="h5 pb-2 mt-2 mb-2 border-bottom text-primary">
        Salary Slips
      </div>
      <div className="row mt-4">
        {salarySlips.length > 0 ? (
          salarySlips.map((item) => (
            <div key={item.payslip_id} className="col-xl-3 col-sm-6 mb-4">
              <Link to={`/salary-slip/${item.payslip_id}`} style={{ textDecoration: 'none', display: 'flex' }}>
                <div className="card leave-item p-3" style={{ backgroundColor: '#f8f9fa', cursor: 'pointer', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', transition: 'transform 0.2s', position: 'relative', width: "100%" }}>
                  <div className="leave_info" style={{ whiteSpace: 'nowrap', fontSize: '0.9rem', color: '#6c757d' }}>
                    <i className="fa fa-calendar mr-2" style={{ color: '#007bff' }} />
                    Generated on: <span className="ml-1">{item.formatted_date}</span>
                  </div>
                  <div className="d-flex justify-content-between mt-2">
                    <div className="leave_info" style={{ whiteSpace: 'nowrap', fontSize: '0.9rem', color: '#6c757d' }}>
                      <i className="fa fa-calendar mr-2" style={{ color: '#007bff' }} />
                      Month: <span className="ml-1">{item.salary_month}-{item.salary_year}</span>
                      <span className="ml-2" style={{ fontSize: '1rem', fontWeight: '500', color: '#343a40' }}>{item.employee_name}</span>
                    </div>
                  </div>
                  <div className="icon-container" style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
                    <i className="fas fa-file-alt" style={{ fontSize: '3rem', opacity: '0.2' }}></i>
                  </div>
                </div>
              </Link>
            </div>
          ))
        ) : (
          <div className="col-12">
            <p className="text-center">Salary slip not available at this time</p>
          </div>
        )}
      </div>
      {/* {totalRecords > 0 && (
        <div className="d-flex justify-content-between mt-4">
          <button 
            className="btn btn-primary" 
            disabled={currentPage === 1} 
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </button>
          <span>Page {currentPage} of {totalPages}</span>
          <button 
            className="btn btn-primary" 
            disabled={currentPage === totalPages} 
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </button>
        </div>
      )} */}
      
      {totalRecords > 0 && (
        <div className="d-flex justify-content-end mt-4">
          <nav>
            <ul className="pagination">
              <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                  Previous
                </button>
              </li>
              {renderPagination()}
              <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
};

export default UserSalarySlips;
