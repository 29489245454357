import React from 'react'
import './PageNotFound.css'

const PageNotFound = () => {
  return (
    <div className="not-found">
      <div className="content">
        <h1>404 - Page Not Found</h1>
        <p>Sorry, the page you are looking for does not exist.</p>
        <a href="/" className="btn btn-primary">Return to Home</a>
      </div>
    </div>
  )
}

export default PageNotFound
