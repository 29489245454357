import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import useAuth from './Authenticaltion/roleFinder';
// import './MobileSidebar.css';
import { useNavigate } from 'react-router-dom';

const Mobilesidebar = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [activeRoute, setActiveRoute] = useState(location.pathname);
    const { role } = useAuth();
    const [activeSection, setActiveSection] = useState(role === 'MASTERADMIN' ? 'admin' : 'basic');

    const handleRouteClick = (path) => {
        setActiveRoute(path);
        navigate(path);
    };

    // const handleSectionClick = (section) => {
    //     setActiveSection(prevSection => (prevSection === section ? '' : section));
    // };
    const handleSectionClick = (section, defaultPath) => {
        if (activeSection !== section) {
            setActiveSection(section);
            handleRouteClick(defaultPath);
        }
    };

    useEffect(() => {
        setActiveRoute(location.pathname);
    }, [location.pathname]);

    const adminRoutes = [
        { path: "/Dashboard", name: "Dashboard", logo: 'fas fa-home' },
        { path: '/EmployeeList', name: "Employee Details", logo: "fas fa-user-plus" },
        { path: '/TimeTracker', name: "Time Tracker", logo: 'fas fa-stopwatch' },
        { path: '/UploadFile', name: "Upload File", logo: 'fas fa-upload' },
        { path: '/Holidays', name: "Holidays", logo: 'fas fa-calendar-alt' },
        { path: '/Manage-Holidays', name: "Manage Holidays", logo: 'fas fa-cogs' },
        { path: '/ViewAllLeave', name: 'User Leaves', logo: "fas fa-check-circle" },
        { path: '/AppliedReimbursement', name: 'Reimbursement', logo: 'fa fa-rupee' },
        { path: '/Admin-Salaryslip', name: 'Salary Slip', logo: 'fa fa-credit-card' }

    ];

    const superuserRoutes = [
        { path: "/Dashboard", name: "Dashboard", logo: 'fas fa-home' },
        { path: '/EmployeeList', name: "Employee Details", logo: "fas fa-user-plus" },
        { path: '/TimeTracker', name: "Time Tracker", logo: 'fas fa-stopwatch' },
        { path: '/UploadFile', name: "Upload File", logo: 'fas fa-upload' },
        { path: '/Holidays', name: "Holidays", logo: 'fas fa-calendar-alt' },
        { path: '/Manage-Holidays', name: "Manage Holidays", logo: 'fas fa-cogs' },
        { path: '/ViewAllLeave', name: 'User Leaves', logo: "fas fa-check-circle" },
        { path: '/AppliedReimbursement', name: 'Reimbursement', logo: 'fa fa-rupee' },
        { path: '/Admin-Salaryslip', name: 'Salary Slip', logo: 'fa fa-credit-card' }
    ];

    const masterAdminRoutes = [
        { path: "/Dashboard", name: "Dashboard", logo: 'fas fa-home' },
        { path: '/EmployeeList', name: "Employee Details", logo: "fas fa-user-plus" },
        { path: '/TimeTracker', name: "Time Tracker", logo: 'fas fa-stopwatch' },
        { path: '/Holidays', name: "Holidays", logo: 'far fa-calendar-alt' },
        { path: '/Manage-Holidays', name: "Manage Holidays", logo: 'fas fa-cogs' },
        { path: '/UploadFile', name: 'Upload File', logo: 'fas fa-upload' },
        { path: '/ViewAllLeave', name: 'User leaves', logo: 'fas fa-check' },
        { path: '/AppliedReimbursement', name: 'Reimbursement', logo: 'fa fa-rupee' },
        { path: '/Admin-Salaryslip', name: 'Salary Slip', logo: 'fa fa-credit-card' }
    ];

    const addRoutes = [
        { path: '/User-Leaves', name: 'Verify Leaves', logo: "fas fa-check-circle" },
    ];

    const finalRoutes = { Advance: [], Basic: [] };

    const basicRoutes = [
        { path: "/UserDashboard", name: "Dashboard", logo: 'fas fa-home' },
        { path: '/Leaves', name: "My Leaves", logo: 'fas fa-bed' },
        { path: '/user-TimeTracker', name: 'Time Tracker', logo: 'fas fa-stopwatch' },
        { path: '/Reimbursement', name: 'Reimbursement', logo: 'fas fa-rupee', },
        { path: '/Holidays', name: "Holidays", logo: 'far fa-calendar-alt' },
        { path: '/SalarySlips', name: "Salary Slips", logo: 'fas fa-file-alt' },

    ];

    const getRoutesByRole = (role) => {
        switch (role) {
            case 'ADMIN':
                finalRoutes.Advance = adminRoutes;
                finalRoutes.Basic = basicRoutes;
                finalRoutes.Basic.push(...addRoutes);
                return finalRoutes;
            case 'SUPERADMIN':
                finalRoutes.Advance = superuserRoutes;
                finalRoutes.Basic = basicRoutes;
                finalRoutes.Basic.push(...addRoutes);
                return finalRoutes;
            case 'MASTERADMIN':
                finalRoutes.Advance = masterAdminRoutes;
                return finalRoutes;
            case 'USER':
                finalRoutes.Basic = basicRoutes;
                return finalRoutes;
            case 'MANAGER':
                finalRoutes.Basic = basicRoutes;
                finalRoutes.Basic.push(...addRoutes);
                return finalRoutes;
            default:
                return [];
        }
    };

    const userRoutesToShow = getRoutesByRole(role);

    const renderSection = (sectionName, routes,defaultPath) => (
        <>
            <li
                className="w-100 d-flex justify-content-center section-header"
                onClick={() => handleSectionClick(sectionName,defaultPath)}
                style={{ cursor: 'pointer', borderRadius: 'none', }}
            >
                {sectionName === 'admin' ? 'Admin Features' : 'My Features'}
            </li>
            <hr style={{ color: '#003AFF' }} />
            <div className={`section ${activeSection === sectionName ? 'open' : ''}`}>
                {routes.map((route) => (
                    <li
                        className={`menu-item ${activeRoute === route.path ? 'active sidebar_active' : 'sidebar_deactive'}`}
                        key={route.name}
                        style={{ cursor: 'pointer', color: "#DCDCDC" }}
                        onClick={() => handleRouteClick(route.path)}
                    >
                        <NavLink
                            to={route.path}
                            style={{
                                color: activeRoute === route.path ? 'white' : 'inherit',
                                textDecoration: 'none',
                                fontSize: '13px',
                                fontWeight: activeRoute === route.path ? 'bold' : 'normal'
                            }}
                        >
                            <i className={route.logo} style={{ width: '2px', color: "#030303" }} />
                            <span style={{ color: " #000000", fontWeight: "600", fontSize: "12px" }}> {route.name}</span>
                        </NavLink>
                    </li>
                ))}
            </div>
        </>
    );

    return (
        <aside className="menu-sidebar d-none d-lg-block">
            <div className="logo">
                <img src="/images/LogoSpc.png" alt="WFMS" />
            </div>
            <div className="menu-sidebar__content js-scrollbar1">
                <nav className="navbar-sidebar">
                    <ul className="list-unstyled navbar__list">
                        {finalRoutes.Advance.length > 0 && renderSection('admin', finalRoutes.Advance,'/Dashboard')}
                        {finalRoutes.Basic.length > 0 && renderSection('basic', finalRoutes.Basic,'/UserDashboard')}
                    </ul>
                </nav>
            </div>
        </aside>
    );
};

export default Mobilesidebar;
