import React, { useState } from 'react';
import { Editor } from 'primereact/editor';
import api from '../../Authenticaltion/api';
import { Calendar } from 'primereact/calendar';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { useLoading } from '../../LoadingContext';
import { useNavigate } from 'react-router-dom';

const ApplyReimbursement = () => {
    const [spentAmount, setSpentAmount] = useState('');
    const [reimbursementType, setReimbursementType] = useState('');
    const [spentDate, setSpentDate] = useState(null);
    const [reason, setReason] = useState('');
    const [file, setFile] = useState(null);
    const { updateLoading } = useLoading();
    const navigate = useNavigate();

    const customHeader = (
        <span className="ql-formats">
            <button className="ql-bold"></button>
            <button className="ql-italic"></button>
            <button className="ql-underline"></button>
            <button className="ql-list" value="ordered"></button>
            <button className="ql-list" value="bullet"></button>
        </span>
    );

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];

        if (selectedFile && selectedFile.size > 5 * 1024 * 1024) { // Check file size (5 MB)
            Swal.fire({
                icon: 'warning',
                text: 'File size must be under 5 MB',
                timer: 2000,
                showConfirmButton: false
            });
            e.target.value = null; // Clear the input field
            setFile(null);
            return;
        }

        setFile(selectedFile);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        updateLoading(true);
        const amount = spentAmount.trim();

        if (!amount || parseFloat(amount) <= 0 || !reimbursementType || !spentDate || !reason.trim()) {
            Swal.fire({
                icon: 'warning',
                text: 'Please fill all mandatory fields',
                timer: 2000,
                showConfirmButton: false
            });
            updateLoading(false);
            return;
        } if (amount <= 50000) {

            let data = new FormData();
            data.append('amount', amount);
            data.append('typeName', reimbursementType);
            data.append('reason', reason);
            data.append('date_spent', spentDate.toISOString().split('T')[0]);
            if (file) {
                data.append('file', file);
            }

            try {
                const response = await api.post('/ApplyReinversement', data);
                if (response.status === 201) {
                    Swal
                        .fire({
                            icon: 'success',
                            text: 'Reimbursement applied successfully!',
                            timer: 2000,
                            showConfirmButton: false
                        })
                        .then(() => {
                            setSpentAmount('');
                            setReimbursementType('');
                            setSpentDate(null);
                            setReason('');
                            setFile(null);
                            navigate('/Reimbursement');
                        });
                }
            } catch (error) {
                toast.error('Failed to submit the reimbursement application. Please try again.');
                console.error(error);
            } finally {
                updateLoading(false);
            }
        } else {
            Swal.fire({
                icon: 'warning',
                text: 'Amount should be less than or equal to 50000',
                timer: 2000,
                showConfirmButton: false
            });
            updateLoading(false);
        };
    }
    const handleAmountInput = (e) => {
        let value = e.target.value;
        // Limit input to maximum 6 digits
        if (value.length > 6) {
            value = value.slice(0, 6);
        }
        // Ensure only digits and optional decimal point
        value = value.replace(/[^0-9.]/g, '');
        // Ensure only one decimal point
        const parts = value.split('.');
        if (parts.length > 2) {
            value = `${parts[0]}.${parts[1]}`;
        }
        setSpentAmount(value);
    };

    return (
        <div class=" shadow p-3 bg-white rounded">
            <div class="h5 pb-2 mt-2 mb-2 border-bottom text-primary">
                Apply Reimbursement
            </div>
            <form className="row mt-4" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col text-right">
                        <span className="text-primary">Amount you want to reimburse: {spentAmount}</span>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="mb-3">
                        <label htmlFor="leaveType" className="form-label">
                            Reimbursement Type <span className="text-danger fw-bold">*</span>
                        </label>
                        <select
                            className="form-select"
                            id="ReimbursementType"
                            name="ReimbursementType"
                            required
                            onChange={(e) => setReimbursementType(e.target.value)}
                        >
                            <option selected disabled>Select Reimbursement Type</option>
                            <option value="Travel">Travel</option>
                            <option value="Food and Hotel">Food and Hotel</option>
                            <option value="Mobile Recharge and Internet">Mobile Recharge and Internet</option>
                            <option value="Server">Server</option>
                            <option value="Domain">Domain</option>
                            <option value="Others">Others</option>
                        </select>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="SpentOn" className="form-label">
                            Spent on <span className="text-danger fw-bold">*</span>
                        </label>
                        <Calendar
                            value={spentDate}
                            onChange={(e) => setSpentDate(e.value)}
                            showIcon
                            style={{ height: '35px', width: '100%' }}
                            dateFormat="dd/mm/yy"
                            maxDate={new Date()}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="SpentAmount" className="form-label">
                            Enter Amount <span className="text-danger fw-bold">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            value={spentAmount}
                            onInput={handleAmountInput}
                            min={0}
                            max={50000}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="BillDocument" className="form-label">
                            Upload Bill
                        </label>
                        <input
                            type="file"
                            accept=".pdf, image/*"
                            className="form-control"
                            onChange={handleFileChange}
                        />
                        <small className="form-text" style={{ color: 'red' }}>
                            Acceptable file types: PDF, JPEG, PNG. Maximum size: 5 MB.
                        </small>
                    </div>
                </div>
                <div className="col-sm-6">
                    <label htmlFor="SpentDescription" className="form-label">
                        Description <span className="text-danger fw-bold">*</span>
                    </label>
                    <Editor
                        id="SpentMessage"
                        headerTemplate={customHeader}
                        style={{ height: '240px' }}
                        required
                        onTextChange={(e) => setReason(e.htmlValue)}
                    />
                </div>
                <div className="mb-3 text-center">
                    <button type="submit" className="btn" style={{ backgroundColor: '#6366F1', color: 'white', borderRadius: '20px' }}>Submit</button>
                </div>
            </form>
        </div>
    );
};

export default ApplyReimbursement;
