import React, { useEffect, useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import api from '../../../Authenticaltion/api';
import { Dialog } from 'primereact/dialog';
// import { useLoading } from "../../LoadingContext";
import { useLoading } from "../../../LoadingContext";
import '../Leaves.css'
import { toast } from 'react-toastify';
const HandleAssignedAdminLeave = () => {
  const [AssignedLeave, SetAssignedLeave] = useState([]);
  const { updateLoading } = useLoading()
  const [filterData, setFilterData] = useState({
    name: null,
    startDate: null,
    toDate: null,
    leaveType: null,
    status: null,
  });
  const calculateDays = () => {
    if (selectedLeave?.Days && selectedLeave?.Duration) {
      const adjustedDuration = (selectedLeave.Duration === 0.5 || selectedLeave.Duration === 0.6) ? 0.5 : selectedLeave.Duration;
        const totalDays = selectedLeave.Days * adjustedDuration;
        return totalDays // Adjust this logic based on the specific requirement
    }
    return 0; // Default value if data is not available
};
  const [isLoading, SetisLoading] = useState(false);
  const [selectedAction, setSelectedAction] = useState('');
  const [dialogInput, setDialogInput] = useState(null);
  const [showInputDialog, setShowInputDialog] = useState(false);
  const [showDialog, setShowDialog] = useState(false); // State for showing the dialog
  const [selectedLeave, setSelectedLeave] = useState(null); // State to store the selected leave
  const [showDocument, setShowDocument] = useState(false);
  const [page, setPage] = useState(1); // Start from page 1
  const pageSize = 36; // Default to 50 items per page
  const [hasMore, setHasMore] = useState(true); // To check if there's more data to fetch
  const handleFilter = () => {
    console.log('searching taken');

    fetchAssignedLeaves();
  };
  const handleCloseDialog = () => {
    setSelectedAction('');
    setShowDialog(false);
    setDialogInput('');
    setShowInputDialog(false); // Ensure the input dialog is also closed // Reset input field if needed
  };
  const fetchAssignedLeaves = async () => {
    updateLoading(true)
    try {
      const response = await api.get('/adminShowLeaves',{
        params: {
            page,
            pageSize,
        }});
      const data = response.data;
      console.log(data)
      SetAssignedLeave(data); 
      if (data.length < pageSize) {
        setHasMore(false);
    } else {
        setHasMore(true);
    } // Update state with the fetched data
    } catch (error) {
      console.error('Error fetching assigned leaves:', error);
    }
    finally {
      updateLoading(false)
    }
  };
  useEffect(() => {
    fetchAssignedLeaves();
  }, [page]);
  const handleViewDetails = async(leaveId) => {
    const Result = await api.get(`/getAssignedLeaveInfo/${leaveId}`)
    setSelectedLeave(Result.data[0]);
    setShowDialog(true);
  };

  const handleLeaveAction = (action) => {
    if (action === 'Approved' || action === 'Rejected') {
      setDialogInput('');
      // Open the input dialog
      setShowInputDialog(true);
      setSelectedAction(action);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && (newPage !== page || hasMore)) {
        setPage(newPage);
    }
};

  const handleDialogSubmit = async () => {
    setShowInputDialog(false);
    setShowDialog(false);
    updateLoading(true)
    console.log("Printing Selected Leave", selectedLeave);
    console.log("Printing LeaveOperation", selectedAction);
    const formLeaveData = await {
      Operation: selectedAction,
      Leaveid: selectedLeave.id,
      message: dialogInput,
      StartDate: selectedLeave.StartDate,
      EndDate: selectedLeave.EndDate,
      Duration: selectedLeave.Duration,
      LeaveType: selectedLeave.LeaveType,
      LeaveTypeId: selectedLeave.LeaveTypeId,
      userId: selectedLeave.uniqueEmployeeId,
      Days: selectedLeave.Days
    };

    try {
      const response = await api.post('/AdminLeaveApproved', formLeaveData);
      console.log(response);

      // Check the status code from the response
      if (response.status === 200) {
        // Assuming the backend sends a JSON response with a "message" field
        const responseData = response.data;

        if (responseData.message === 'Leave approved successfully') {
          // Update your state or perform other actions for approval success
          toast.success('Leave was approved successfully.');
        } else if (responseData.message === 'Leave rejected successfully') {
          // Update your state or perform other actions for rejection success
          toast.success('Leave was rejected successfully.');
        } else if (responseData.message === 'Leave deleted successfully') {
          // Update your state or perform other actions for delete success
          toast.success('Leave was deleted successfully.');
        } else {
          toast.success(responseData.message)
          // Handle unexpected success response here
        }
      } else {
        // Handle errors or other status codes.
        toast.error('Leave operation failed:', response.statusText);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(`An error occurred: ${error.response.data.error}`);
    } else {
        toast.error(`An error occurred: ${error.message || 'Unknown error'}`);
    }
    } finally {
      // After handling the response or error, you can set isLoading to false.
      setSelectedAction('');
      setDialogInput('');
      fetchAssignedLeaves();
      updateLoading(false)
    }

  };

  return (
    <>
      <div class=" shadow p-3 bg-white rounded">
        <div class="h5 pb-2 mt-2 mb-2 border-bottom text-primary">
          Leave Assigned
        </div>
        <div className="row mt-4">
          {AssignedLeave.map((leave) => (
            <div className="col-xl-3 col-sm-6">
              <div className='card leave-item p-2' key={leave.id} style={{ backgroundColor: '#D1CFCF', cursor: 'pointer', position: 'relative' }} onClick={() => handleViewDetails(leave.id)}>
                <div className='text-center' style={{ backgroundColor: "#ffffff", borderRadius: "5px" }}>
                  <div className='leave_info' style={{ color: '#2643FF', fontSize: '20px', fontWeight: "700" }}>{leave.LeaveType}</div>
                </div>
                <div className='mt-2'>
                  <span style={{ color: '#021317', fontWeight: "700" }}>Leave Sub Type:</span><span className='text-danger' style={{ color: '#FDFDFD', fontWeight: "700" }}> {leave.LeaveSubType == null ? "Not Available" : leave.LeaveSubType}</span>
                </div>
                <div className='leave_info mt-2'><i className="fa fa-user" /> {leave.EmployeeName}</div>

                <div className='row mt-4'>
                  <div className='col'>
                    <div className='leave_info'><i className="fa fa-clock" /> Duration: {leave.Duration === 0.5
                      ? "First Half"
                      : leave.Duration === 0.6
                        ? "Second Half"
                        : leave.Duration === 1.0
                          ? "Full Day"
                          : ""}
                    </div>
                    <div className='leave_info'><i className="fa fa-calendar" />  From: {leave.StartDate}</div>
                    <div className='leave_info'><i className="fa fa-calendar" /> To: {leave.EndDate}</div>
                  </div>
                  <div className='col-auto'>
                    <i className='fas fa-home' style={{ fontSize: '4rem', opacity: '0.2' }}></i>
                  </div>
                </div>
                <div className={`text-center mt-3 p-1 ${leave.finalstatus === 'Pending' ? 'bg-warning' : leave.finalstatus === 'Approved' ? 'bg-success' : leave.finalstatus === 'Rejected' ? 'bg-danger' : 'bg-danger'}`} style={{ borderRadius: "10px" }}>
                  <span className='status text-white'>{leave.finalstatus}</span>
                </div>
              </div>
            </div>

          ))}
          {AssignedLeave.length === 0 && (
            <div className="col-12">
              <p className="text-center">No assigned leaves are available at the moment.</p>
            </div>
          )}
          <div className="pagination-controls" style={{ display: 'flex', justifyContent: 'end' }}>
                <button
                    onClick={() => handlePageChange(page - 1)}
                    disabled={page === 1}
                    className='btn btn-primary'
                >
                    Previous
                </button>
                <span>Page {page}</span>
                <button
                    onClick={() => handlePageChange(page + 1)}
                    disabled={!hasMore}
                    className='btn btn-primary'
                >
                    Next
                </button>
        </div>
        </div>
        <Dialog className="dialogbox " visible={showDialog} showHeader={false} style={{ width: '50vw' }}>
          <div className='card chat w-100 ' style={{ padding: '0', margin: '0' }}>
            <div className='card-header' style={{ backgroundColor: '#5B8FFF' }}>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='col text-center text-white'>
                  <span className='h-100 fw-bold' style={{ fontSize: '20px' }}>{selectedLeave?.LeaveType} - {selectedLeave?.Duration === 0.5 ? "First Half" : selectedLeave?.Duration === 0.6 ? "Second Half" : selectedLeave?.Duration === 1.0 ? "Full Day" : `${selectedLeave?.Duration} ${selectedLeave?.subType}`} {selectedLeave?.LeaveSubType}</span>
                </div>

                <div className='col-auto'>
                  <i className="fa fa-times" onClick={handleCloseDialog}></i>
                </div>
              </div>
            </div>
            <div className='card-body max-w-60' style={{ backgroundColor: '#D7D9DE' }}>
              <div className='row'>
                <div>
                  {/* <span style={{ color: '#021317', fontWeight: '700', fontSize: '14px' }}>
                    Leave Type:
                  </span>
                  <span className='fw-bold' style={{ color: '#F50000', fontSize: '18px' }}>
                    {selectedLeave?.Duration === 0.5 ? "First Half" : selectedLeave?.Duration === 0.6 ? "Second Half" : selectedLeave?.Duration === 1.0 ? "Full Day" : `${selectedLeave?.Duration} ${selectedLeave?.subType}`} {selectedLeave?.LeaveSubType}
                  </span> */}
                  <br />
                  <span style={{ color: '#021317', fontWeight: '700', fontSize: '14px' }}>
                    Status :
                    <span style={{ fontSize: '16px', fontWeight: '700' }} className={`status-text ${selectedLeave?.finalstatus === 'Approved' ? 'text-success' : selectedLeave?.finalstatus === 'Rejected' ? 'text-danger' : 'text-warning'}`}>
                      {selectedLeave?.finalstatus}
                    </span>
                  </span>
                  <div className="mt-2">
                    <span style={{ color: '#021317', fontWeight: '700', fontSize: '14px' }}>
                      Available Leave Balance:
                    </span>
                    <span style={{ color: '#000', fontWeight: '700', fontSize: '16px', marginLeft: '5px' }}>
                      {selectedLeave?.remaining_balance || 'N/A'}
                    </span>
                  </div>
                </div>
                <div className='mt-2'>
                  <div style={{ color: '#021317', fontWeight: '700' }}>
                    <i className='fa fa-suitcase' />
                    <h6 className="d-inline ml-2" style={{ fontSize: '14px' }}>Leave is of <strong>{calculateDays()}</strong> {calculateDays() <= 1 ? "day" : "days"}</h6>
                  </div>
                  <div style={{ color: '#021317', fontWeight: '700' }}>
                    <i className='fa fa-calendar' />
                    <h6 className="d-inline ml-2" style={{ fontSize: '14px' }}>From: {selectedLeave?.StartDate}</h6>
                  </div>
                  <div style={{ color: '#021317', fontWeight: '700' }}>
                    <i className='fa fa-calendar' />
                    <h6 className="d-inline ml-2" style={{ fontSize: '14px' }}>To: {selectedLeave?.EndDate}</h6>
                  </div>
                  <div style={{ color: '#021317', fontWeight: '700' }}>
                    <i className='fa fa-file' />
                    <h6 className="d-inline ml-2" style={{ fontSize: '14px' }}>Attached Document: {selectedLeave?.AttachedDocumnet.FilePathName ? (
                      <a
                        href={selectedLeave.AttachedDocumnet.FilePathName}
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(selectedLeave.AttachedDocumnet.FilePathName, '_blank');
                        }}
                        role="button"
                        target='_blank'
                        aria-label={`Download ${selectedLeave.AttachedDocumnet.originalFilename}`}
                        style={{ color: '#6366F1', fontWeight: 'bold' }}
                      >
                        {selectedLeave.AttachedDocumnet.originalFilename}
                      </a>
                    ) : (
                      <span>Not provided</span>
                    )}
                    </h6>
                  </div>
                </div>
              </div>
              <div>
                <h5 className="mt-4 ml-2 " style={{ color: '#021317', fontWeight: "700" }}>Leave History</h5>
              </div>
              <div class=" card chat-history mt-2" style={{ backgroundColor: '#F9FCFF' }}>
                <div>
                  <ul className="list-unstyled">
                    <li>
                      <div className="d-flex flex-column mt-3 ml-1">
                        <div className="d-flex align-items-center ml-3" style={{ fontSize: "12px" ,color: "#6366F1" }}>
                          <strong >{selectedLeave?.user_history[0].UpdatedUserName}</strong>
                          <div className="message-data-time ml-2">
                            {selectedLeave?.user_history[0].DateChanged}
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div>
                            <img
                              src={selectedLeave?.user_history[0].UpdatedUserimage || `/images/icon/Noprofile.webp`}
                              className="rounded-circle mr-2"
                              style={{ width: "60px", height: "50px", objectFit: "cover" }}
                              alt="avatar"
                            />
                          </div>
                          <div
                            className="card message other-message p-2 mr-5 ml-1"
                            style={{ backgroundColor: "#A4A6A8", color: "#000000" }}
                            dangerouslySetInnerHTML={{ __html: selectedLeave?.user_history[0].Message }}
                          />
                        </div>
                      </div>
                    </li>
                    {selectedLeave?.user_history.map((historyItem, index) => (
                      index >= 1 && (
                        <li className="clearfix mt-2 d-flex justify-content-end ml-auto mr-1" key={index}>
                          <div className="d-flex flex-column align-items-end mt-3">
                            <div className="d-flex align-items-center mr-3" style={{ fontSize: "12px" ,color: "#6366F1" }}>
                              <div className="message-data-time mr-2">
                                {historyItem.DateChanged}
                              </div>
                              <strong>{historyItem.UpdatedUserName}</strong>
                            </div>
                            <div className="d-flex mt-1 mr-3">
                              <div
                                className="card message other-message p-2"
                                style={{ backgroundColor: "#A4A6A8", color: "#000000" }}
                                dangerouslySetInnerHTML={{ __html: historyItem.Message }}
                              />
                              <div>
                                <img
                                  src={historyItem.UpdatedUserimage || `/images/icon/Noprofile.webp`}
                                  className="rounded-circle ml-2"
                                  style={{ width: "50px", height: "50px", objectFit: "cover" }}
                                  alt="avatar"
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                marginRight: "8px",
                                fontSize: "12px",
                                fontWeight: "600",
                                color: historyItem.Status === "Rejected" ? "red" : "green"
                              }}
                            >
                              {historyItem.Status}
                            </div>
                          </div>
                        </li>
                      )
                    ))}
                  </ul>
                </div>
              </div>
              {selectedLeave?.finalstatus == "Pending" && (
                <div>
                  <div className="d-flex justify-content-end">
                    <button type="button" className="btn btn-success me-2" onClick={() => handleLeaveAction('Approved', selectedLeave)}>Approve</button>
                    <button type="button" className="btn btn-danger" onClick={() => handleLeaveAction('Rejected', selectedLeave)}>Reject</button>
                  </div>

                  {selectedAction && (
                    <div className="card mt-2 p-2">
                      <textarea
                        className="form-control mt-2"
                        value={dialogInput}
                        placeholder={selectedAction==="Approved"?"Reason for Approval":"Reason for Rejection"}
                        onChange={(e) => setDialogInput(e.target.value)}
                        style={{ height: '100px' }}
                      />
                      <div className="d-flex justify-content-center mt-2">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleDialogSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
};
export default HandleAssignedAdminLeave;

