// import React, { createContext, useContext, useState } from 'react';

// const LoadingContext = createContext();

// export const useLoading = () => {
//   return useContext(LoadingContext);
// };

// export const LoadingProvider = ({ children }) => {
//   const [loading, setLoading] = useState(false);

//   const updateLoading = (isLoading) => {
//     setLoading(isLoading);
//   };
//   return (
//     <LoadingContext.Provider value={{ loading, updateLoading }}>
//       {children}
//     </LoadingContext.Provider>
//   );
// };

//now loading context

// LoadingContext.jsx
import React, { createContext, useContext, useState ,useCallback} from 'react';

// Create the LoadingContext
const LoadingContext = createContext();

// Create a provider for the LoadingContext
const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  // Function to update the loading state
  const updateLoading = useCallback((value) => {
    setLoading(value);
  }, []);

  return (
    <LoadingContext.Provider value={{ loading, updateLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};

// Create a hook to use the LoadingContext
const useLoading = () => {
  const context = useContext(LoadingContext);

  if (!context) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }

  return context;
};

export { LoadingProvider, useLoading };

