import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
import Mobilesidebar from './Mobilesidebar';
import MobileHeader from './Mobileheader';
import DesktopHeader from './DesktopHeader';
import '@fontsource/roboto-slab';
import { ToastContainer } from 'react-toastify';
import "primereact/resources/themes/lara-light-indigo/theme.css";

import { useLoading } from './LoadingContext'
import LoaderCoponenet from './LoaderComponenet'
import LoaderComponenet from './LoaderComponenet';

const Layout = ({ children }) => {
  const location = useLocation();
  const { loading, updateLoading } = useLoading();
  const hasToken = localStorage.getItem('token');
  const withoutheaderPage = location.pathname === '/' || location.pathname === '/forgot-password' || location.pathname === '/Register' || location.pathname === '/Login';
  return (
    <>
      {loading && <LoaderComponenet />}
      <div className="page-wrapper">
        {withoutheaderPage ? (
          <div>
            {children}
            <ToastContainer />
          </div>
        ) : (
          <>
            {hasToken && <MobileHeader />}
            {hasToken && <Mobilesidebar />}
            <ToastContainer position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            <div className="page-container">
              {hasToken && <DesktopHeader />}

              <div className="main-content">
                {children}
              </div>
            </div>
          </>
        )}
      </div >
    </>
  );
};

export default Layout;
