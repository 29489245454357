import React, { useEffect, useState } from 'react';
import Layout from './Layout';
import AppRoutes from './Route';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "primereact/resources/primereact.min.css"; 
// import 'primereact/resources/themes/saga-blue/theme.css';
import { LoadingProvider } from './LoadingContext';

const App = () => {
  return (
    <LoadingProvider>
    <Layout>
       <AppRoutes/>
    </Layout>
    </LoadingProvider>
  );
};
export default App;
