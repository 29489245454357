import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import api from '../../Authenticaltion/api';
import { setAuthToken } from '../../Authenticaltion/api';
import Swal from 'sweetalert2';
// import { GoogleLogin } from 'react-google-login'; // Unused import
import './Login.css';
import useAuth from '../../Authenticaltion/roleFinder';
import animationData from './Animation - 1722833559727.json';
import Lottie from 'lottie-react'
// import { useLoading } from '../../LoadingContext'; // Unused import

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const RoleFinder = useAuth();
  const [pwd, setPwd] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await api.post('/login', {
        email,
        pwd,
      });
      console.log("response.status is", response.status);
      if (response.status === 200) {
        const { token, role } = response.data;
        localStorage.setItem('token', token);
        setAuthToken(token);

        if (role === 'SUPERUSER') {
          navigate('/Dashboard');
        } else {
          navigate('/UserDashboard');
        }
      } else if (response.status === 500) {
        const errorMessage = response.data.error;
        Swal.fire({
          title: 'Server Error',
          text: errorMessage,
          icon: 'error',
          timer: 2000,
        });
      }
    } catch (error) {
      console.log('API Request Error:', error.response.data.message);
      Swal.fire({
        title: 'Error',
        text: error.response.data.message,
        icon: 'error',
        timer: 2000,
      });
    }
  };

  useEffect(() => {
    console.log("Role is", RoleFinder.role);
    if (RoleFinder.role === 'ADMIN') {
      navigate('/Dashboard');
    } else if (RoleFinder.role === 'SUPERADMIN') {
      navigate('/Dashboard');

    } 
    else if (RoleFinder.role === 'USER' || RoleFinder.role === 'MANAGER') {
      navigate('/UserDashboard');
    }
    else if(RoleFinder.role === 'MASTERADMIN') {
      navigate('/Dashboard');
    }
  }, [RoleFinder.role, navigate]);

  return (
    <>
      <div className="container" style={{ marginTop: '3rem',paddingLeft: '0px', paddingRight: '0px' }}>
        <div className="col-md-6 d-flex justify-content-center align-items-center custom-mr-13">
          <Lottie animationData={animationData} style={{ width: '750px', height: '800px' }} />
        </div>
        <div className="col-md-6 d-flex justify-content-center align-items-center" style={{ borderLeft: '10px solid rgb(114 122 152 / 52%)' }}>
          <div className="login-wrap">
            <div className="login-content">
              <div className="login-logo">
                <a href="https://wfms.ajatus.in/">
                  <img width="150" src="images/LogoSpc.png" alt="CoolAdmin" />
                </a>
                <h5 className="mb-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Welcome to WFMS 👋</h5>
                <p className="mb-4 enter-message" style={{ fontSize: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Please sign in to your account to get started</p>
              </div>
             
              <div className="login-form">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="email">Email Address</label>
                    <input
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter your email"
                      required
                      
                    />
                 
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <div className="password-input-container">
                      <input
                        className="form-control"
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        id="password"
                        placeholder="Enter your password"
                        value={pwd}
                        onChange={(e) => setPwd(e.target.value)}
                        required
                      />
                      <i
                        className={`fas ${showPassword ? "fa-duotone fa-eye-slash" : "fa-light fa-eye"} password-toggle-icon1`}
                        onClick={() => setShowPassword(!showPassword)}
                      ></i>
                    </div>
                  </div>
                  <div className="form-group form-check">
                    <div className="d-flex align-items-center">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name="remember"
                        id="remember"
                      />
                      <label className="form-check-label" htmlFor="remember">
                        Remember Me
                      </label>
                      <label className="ml-auto">
                        <Link to="/forgot-password" style={{ textDecoration: 'none', color: '#6366F1' }}>Forgot Password?</Link>
                      </label>
                    </div>
                  </div>
                  <button
                    className="au-btn au-btn--block m-b-20"
                    type="submit"
                    style={{ height: '40px', backgroundColor: '#6366F1' }}
                  >
                    Log In
                  </button>
                 
                </form>
                
              </div>
            </div>
            <div className="mt-3 text-center">
              <span>
                Don't have an account?{' '}
                <Link to='/Register' style={{ textDecoration: 'none', color: '#6366F1' }}>Sign Up</Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
