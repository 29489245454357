import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCheck, faSquareXmark } from '@fortawesome/free-solid-svg-icons';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import { toast } from 'react-toastify';
import api from '../../../Authenticaltion/api'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import './TableData.css';
import { useLoading } from '../../.././LoadingContext'


const DisplayTableData = () => {
  const [showdisapprovedModal, setshowdisapprovedModal] = useState(false);
  const [AddToMakePresentModal, showAddPresentModal] = useState(false);
  const [rowToDisapproved, SetRowToDisapproved] = useState({})
  const [rowToPresent, SetRowToPresent] = useState({})
  const location = useLocation()
  const [tableData, setTableData] = useState([]);
  const title = location.state.Title;
  const [filteredData, setFilteredData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const { updateLoading } = useLoading()
  useEffect(() => {
    updateLoading(true)
    console.log(title);
    if (title === 'last7days') {
      console.log('Days7 Data');
      api
        .get('/Last7Days')
        .then((res) => {
          const pidata = res.data;
          const newArray = pidata.map((data) => {
            const { date, absentEmployees } = data;
            const employees = absentEmployees.map((emp) => ({
              date,
              name: emp.Name,
              emp_id: emp.Emp_Id,
              WFH: 'Active',
            }));
            return employees;
          });
          const flattenedArray = [].concat(...newArray);
          setTableData(flattenedArray);
          updateLoading(false)
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          updateLoading(false)
        });
    }
    else if (title === 'last3months') {
      console.log('Days 30 Data');
      api
        .get('/Last3Month')
        .then((res) => {
          const pidata = res.data;
          setTableData(pidata);
          updateLoading(false)
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          updateLoading(false)
        });
    }
    else if (title === 'LateCorner15Days') {
      api
        .get('/LateCorner15Days')
        .then((res) => {
          const pidataForLate15Days = res.data;
          setTableData(pidataForLate15Days);
          updateLoading(false)
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          updateLoading(false)
        });
    }
    else if (title === 'Last1Month') {
      api
        .get('/Last1Month')
        .then((res) => {
          const pidataForLate15Days = res.data;
          setTableData(pidataForLate15Days);
          updateLoading(false)
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          updateLoading(false)
        });
    }
    else if (title === 'LastMonth') {
      api
        .get('/LastMonth')
        .then((res) => {
          const pidataForLate15Days = res.data;
          setTableData(pidataForLate15Days);
          updateLoading(false)
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          updateLoading(false)
        });

    }

    updateLoading(false)
  }, [title]);
  useEffect(() => {
    updateLoading(true)
    if (tableData.length > 0) {
      const keys = Object.keys(tableData[0]);
      const formattedColumns = keys.map((key) => {
        let column = {
          dataField: key,
          text: key === 'FormattedDate' ? 'DATE' : key === 'late' ? 'Action' : key.toUpperCase(),
          sort: true,
          sortCaret: (order, column) => {
            if (!order) return null;
            if (order === 'asc') return '↑'; // Up arrow
            if (order === 'desc') return '↓'; // Down arrow
            return '↑';
          },
          formatter: (cell, row) => {
            if (key === 'date') {
              const dateObj = new Date(cell);
              const formattedDate = `${dateObj.getDate()}-${dateObj.getMonth() + 1}-${dateObj.getFullYear()}`;
              return formattedDate;
            }
            if (key === 'Status') {
              if (cell === 'Approved') {
                return <FontAwesomeIcon icon={faSquareCheck} style={{ color: '#4ea24e' }} />;
              } else {
                return (
                  <FontAwesomeIcon
                    icon={faSquareXmark}
                    style={{ color: '#fa0000', cursor: 'pointer' }}
                    onClick={() => DigagreeMethodModal(row)}
                  />
                );
              }
            }
            if (key === 'Action') {
              return <button
                className='btn btn-outline-warning  fw-bolder btn-sm' // Added "btn-sm" class to make the button small
                onClick={() => setapprovedMethod(row)}
                label="Confirm"
              >
                Approved
              </button>

            }
            return cell;
          },
          hidden: key === 'company_id',
        };

        return column;
      });
      setColumns(formattedColumns);
      setFilteredData(tableData);
      updateLoading(false)
    }
    updateLoading(false)

  }, [tableData]);

  //method for search value
  const handleSearch = (event) => {
    updateLoading(true)
    const searchInput = event.target.value;
    setSearchValue(searchInput);

    const filtered = tableData.filter((row) => {
      for (let key in row) {
        if (row[key].toString().toLowerCase().includes(searchInput.toLowerCase())) {
          return true;
        }
      }
      return false;
    });
    updateLoading(false)
    setFilteredData(filtered);
  };

  //size render per page functionality
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="btn-group" role="group">
      {options.map((option) => {
        const isSelect = currSizePerPage === `${option.page}`;
        const buttonClass = isSelect ? 'btn btn-primary' : 'btn btn-secondary';
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={buttonClass}
          >
            {option.text}
          </button>
        );
      })}
    </div>
  );
  //approved method dialog
  const setapprovedMethod = (row) => {
    showAddPresentModal(true);
    SetRowToPresent(row);

    console.log("Setapproved method call")
    // confirmDialog({
    //   message: (
    // <div>
    //   <i className="fa fa-exclamation-triangle" style={{ fontSize: '2em', marginRight: '1rem', color: '#578ad6' }}></i>
    //   Are you sure you want to let the employee to WFH: {row.Name}?
    // </div>
    //   ),
    //   header: 'Confirmation',
    //   accept: () => ApprovedMethod(row),
    // });
  }
  //approved method message
  const ApprovedMethod = (row) => {
    SetRowToPresent({})
    const { Emp_Id, FormattedDate, Name, company_id } = row;
    console.log("Sending approved row data is", row)
    const requestData = {
      Emp_Id,
      FormattedDate,
      Name,
      company_id,
    };
    api.post('/AddPresent', requestData)
      .then((res) => {
        if (res.data.type === 'sucess') {
          toast.success(res.data.message)
          console.log("Now it is time for call add present api ")
          if (title === 'last7days') {
            console.log('Days7 Data');
            api
              .get('/Last7Days')
              .then((res) => {
                const pidata = res.data;
                const newArray = pidata.map((data) => {
                  const { date, absentEmployees } = data;
                  const employees = absentEmployees.map((emp) => ({
                    date,
                    name: emp.Name,
                    emp_id: emp.Emp_Id,
                    WFH: 'Active',
                  }));
                  return employees;
                });
                const flattenedArray = [].concat(...newArray);
                setTableData(flattenedArray);
              })
              .catch((error) => {
                console.error('Error fetching data:', error);
              });
          }
          else {
            api
              .get('/Last3Month')
              .then((res) => {
                const pidata = res.data;
                setTableData(pidata);
              })
              .catch((error) => {
                console.error('Error fetching data:', error);
              });
          }
        }
        else {
          toast.error(res.data.message)
        }
      })
    setSearchValue('')
  }
  //disagree late method dialog
  const DigagreeMethodModal = (row) => {
    console.log("Testing row print", row)
    setshowdisapprovedModal(true)
    SetRowToDisapproved(row)
    // confirmDialog({
    //   // message: `Are you sure you want to cancel the late entry  ${row.Name}?`,
    //   message: (
    //     <div>
    //       <i className="fa fa-exclamation-triangle" style={{ fontSize: '2em', marginRight: '1rem', color: '#578ad6' }}></i>
    //       Are you sure you want to cancel the late entry  {row.Name}?
    //     </div>
    //   ),
    //   header: 'Confirmation',
    //   icon: 'pi pi-check',
    //   accept: () => DisagreeLate(row),
    // });
  }
  //disagree method 
  const DisagreeLate = (row) => {
    // Check if selectedRow exists
    SetRowToDisapproved({})
    if (row) {
      const { Emp_Id, FormattedDate, company_id } = row;
      const requestData = {
        Emp_Id,
        FormattedDate,
        company_id,

      };
      // Perform the reject action using the selectedRow data
      api
        .post('/DisagreeLate', requestData)
        .then((res) => {
          if (res.data.type === 'success') {
            toast.success(res.data.message)
            if (title === 'Last1Month') {
              api.get('/Last1Month')
                .then((res) => {
                  const pidataForLate15Days = res.data;
                  setTableData(pidataForLate15Days);
                })
                .catch((error) => {
                  toast.error(error)
                });
            }
            else if (title === 'LateCorner15Days') {
              api.get('/LateCorner15Days')
                .then((res) => {
                  const pidataForLate15Days = res.data;
                  setTableData(pidataForLate15Days);
                })
                .catch((error) => {
                  toast.error(error)
                })

            }
            else if (title === 'LastMonth') {
              api
                .get('/LastMonth')
                .then((res) => {
                  const pidataForLate15Days = res.data;
                  setTableData(pidataForLate15Days);
                })
                .catch((error) => {
                  console.error('Error fetching data:', error);
                });

            }
          }
          else {
            toast.error(res.data.message)
          }

        })
        .catch((error) => {
          toast.error(error)
        });
    }
    setSearchValue('')
  };
  return (
    <div className='shadow p-3 bg-white rounded'>

      <>
        <div className="row-12">
          <input
            type="text"
            placeholder="Search..."
            className="form-control"
            value={searchValue}
            onChange={handleSearch}
            style={{ width: '300px', marginBottom: '10px' }}
          />
        </div>
        {filteredData.length === 0 ? ( // Check if there are no records
          <p>No data available</p>
        ) : (
          <div className="table-light table-responsive shadow">
            <BootstrapTable
              id="tableResp"
              keyField={(row, index) => index.toString()}
              data={filteredData}
              columns={columns}
              pagination={paginationFactory({
                sizePerPageRenderer
              })}
              hover
              responsive
              condensed
              // bordered={false}
              filter={filterFactory()}
            />
          </div>
        )}
      </>

      <ConfirmDialog
        visible={AddToMakePresentModal}
        onHide={() => showAddPresentModal(false)}
        message={
          <div>
            <i className="fa fa-exclamation-triangle" style={{ fontSize: '2em', marginRight: '1rem', color: '#578ad6' }}></i>
            <span dangerouslySetInnerHTML={{ __html: `Are you sure you want to let the employee to WFH: ${rowToPresent.Name}?` }} />
          </div>
        }
        header="Confirmation"
        accept={() => ApprovedMethod(rowToPresent)}
        reject={() => showAddPresentModal(false)}

      />

      <ConfirmDialog
        visible={showdisapprovedModal}
        onHide={() => setshowdisapprovedModal(false)}
        message={
          <div>
            <i className="fa fa-exclamation-triangle" style={{ fontSize: '2em', marginRight: '1rem', color: '#578ad6' }}></i>
            <span dangerouslySetInnerHTML={{ __html: `Are you sure you want to cancel the late entry ${rowToDisapproved.Name}?` }} />
          </div>
        }
        header="Confirmation"
        accept={() => DisagreeLate(rowToDisapproved)}
        reject={() => setshowdisapprovedModal(false)}

      />
    </div>
  )
}
export default DisplayTableData



