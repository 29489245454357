import { Editor } from 'primereact/editor'
import React, { useState } from 'react'
import api from '../../Authenticaltion/api'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const ApplyWFH = () => {
    const [StartDate, setStartDate] = useState(" ");
    const [EndDate, setEndDate] = useState(" ");
    const [WFHMessage, setWFHMessage] = useState();

    const navigate = useNavigate()
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleWFH = async (e) => {
        e.preventDefault();
        try {
            if (StartDate && EndDate && WFHMessage) {
                const formattedStartDate = formatDate(StartDate);
                const formattedEndDate = formatDate(EndDate);

                const response = await api.post('/WfhApply', {
                    fromdate: formattedStartDate,
                    todate: formattedEndDate,
                    message: WFHMessage
                });

                console.log(response);
                if (response.status === 201) {
                    // toast.success('Successfully applied for WFH');
                    Swal.fire({
                        icon: "success",
                        title: "Applied Sucessfully",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    navigate("/userWorkFromHome")
                } else {
                    const errorMessage = response.data && response.data.error ? response.data.error : 'err';
                    toast.error(errorMessage);
                }
            } else {
                // toast.error('Please fill in all the required fields');
                Swal.fire({
                    icon: "error",
                    text: "Fill all Required Filled",
                    showConfirmButton: false,
                    timer: 1500
                });
            }
        } catch (error) {
            console.log('An error occurred while applying for leave', error);
        }
    };
    const customHeader = (
        <span className="ql-formats">
            <button className="ql-bold"></button>
            <button className="ql-italic"></button>
            <button className="ql-underline"></button>
            <button className="ql-list" value="ordered"></button>
            <button className="ql-list" value="bullet"></button>
        </span>
    );

    // console.log(WFHMessage);
    return (
        <div className="shadow p-3 bg-white rounded">
            <div class="h4 pb-2 mt-2 mb-2 border-bottom text-primary">
                Apply Work From Home
            </div>
            <form id="WorkFromHomeForm ">
                <div class="row">
                    <div class="col-xl-6 col-sm-12 col-12">
                        <div class="mb-3 row">
                            <div class="col-md-6">
                                <label for="fromDate" class="form-label">
                                    From Date <span class="text-danger fw-bold">*</span>
                                </label>
                                <input type="date" class="form-control" id="input" style={{ width: "220px" }} dateFormat="dd/mm/yy" onChange={(e) => setStartDate(e.target.value)} />
                            </div>
                            <div class="col-md-6">
                                <label for="toDate" class="form-label">
                                    To Date <span class="text-danger fw-bold">*</span>
                                </label>
                                <br />

                                <input type="date" class="form-control" id="input" style={{ width: "220px" }} dateFormat="dd/mm/yy" onChange={(e) => setEndDate(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="WFHMessage" class="form-label">
                            WFH Reason <span class="text-danger fw-bold">*</span>
                        </label>
                        <Editor
                            id="WFHMessage"
                            placeholder="No reason mentioned !"
                            onTextChange={(e) => setWFHMessage(e.htmlValue)}
                            headerTemplate={customHeader}
                            style={{ height: '220px' }}
                            required
                        />
                    </div>
                </div>
                <div class="mb-3 text-center">
                    <button type="button" class="btn" style={{ backgroundColor: '#6366F1', color: 'white', borderRadius: '20px' }} onClick={handleWFH}>
                        Apply WFH
                    </button>
                </div>
            </form>
        </div>
    )
}

export default ApplyWFH