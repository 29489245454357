import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
// import UserContextProvider from './UserContext'

axios.interceptors.request.use((req) => {
  console.log("interceptor called req", req.url);
  let token = localStorage.getItem('token')
  req.headers.Authorization = "Bearer " + token
  return req;
}, (err) => {
  console.log("interceptor called req error", err);
  return err;
})

axios.interceptors.response.use((response) => {
  console.log("interceptor called res", response);
  return response
}, (err) => {
  console.log("interceptor called res err", err);
  return err
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  // </React.StrictMode>
);
