import React, { useState } from 'react';

const AddEmployee = () => {
  // Initial state for basic information
  const [basicInfo, setBasicInfo] = useState(initialBasicInfo);

  // Handler for updating state
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBasicInfo(prevState => ({ ...prevState, [name]: value }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate inputs (basic example, can be expanded)
    if (!basicInfo.Name || !basicInfo.email || !basicInfo.emp_id) {
      alert('Please fill out all required fields.');
      return;
    }
    // Handle form submission, e.g., send data to an API
    console.log(basicInfo);
    // Optionally, reset the form after submission
    setBasicInfo(initialBasicInfo);
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Add Employee</h2>
      
    </div>
  );
};

// Initial state for basic information
const initialBasicInfo = {
  Name: "",
  email: "",
  emp_id: "",
  phone: "",
  department: "",
  designation: "",
  inTime: "",
  outTime: ""
};

export default AddEmployee;
